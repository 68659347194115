import axios from 'axios'
import store from 'store'
// import atstore from '@/store'
import { notification } from 'ant-design-vue'
import router from '@/router'

// console.log('store in axios file', atstore)
// Kok aneh
var baseurl = 'https://apilbbprs.heasoft.com/api'

var location = window.location.host.includes('localhost')

// console.log('location', window.location.host)
// console.log('location', location)

const apiClient = axios.create({
  baseURL: baseurl,
  // baseURL: 'http://localhost:3003/api',
  // timeout: 1000,
  headers: location ? { 'x-heasoft-dev': 'DFMCb49AWxLXun8zseQda3fq' } : {},
})
apiClient.interceptors.request.use(request => {
  // console.log('navigator.onLine', navigator.onLine)
  if (!navigator.onLine) {
    notification.warning({
      message: 'Anda sedang offline. Pastikan jaringan internet Anda tersambung',
    })
    return false
  }
  const accessToken = store.get('accessToken')
  if (accessToken) {
    request.headers.Authorization = `Bearer ${accessToken}`
  }
  return request
})

// apiClient.interceptors.response.use(function (error) {
//   console.log(error)
//   const pesan = error.response.data.message
//   if (pesan) {
//     notification.warning({
//       message: pesan,
//     })
//   }
// },
// )

apiClient.interceptors.response.use(undefined, error => {
  // Errors handling
  const { response } = error

  if (!response) {
    // console.log('Network error or no response from server')
    notification.error({
      message: 'Error',
      description: 'Server tidak dapat diakses.',
    })
    return Promise.reject(error)
  }

  // console.log('error.response', response)

  if (response.status === 502) {
    // console.error('Server error: 502 Bad Gateway')
    notification.error({
      message: 'Server Error',
      description: 'Server tidak dapat diakses. Tolong coba lagi nanti!.',
    })
  } else {
    // notification.warning({
    //   message: response.data.message || 'An error occurred',
    // })
  }
  // const { response } = error
  const { data } = response
  // console.log('error', error)
  // && response.headers['content-type'] === 'application/json'
  if (data && response.status !== 200) {
    // console.log('step1')
    // notification.warning({
    //   message: data.message,
    // })
    if (data.message !== 'unauthorization') {
      // console.log('step2')
      // console.log('store', store)
      // console.log('atstore', atstore)
      var tok = store.get('accessToken')
      // console.log('tok', tok)
      // console.log('response.status', response.status)
      if (tok !== undefined || response.status === 401) {
        notification.warning({
          message: data.message,
        })
      }
    } else {
      router.push('/auth/login')
    }
  }
  // console.log('finish')
})

export default apiClient
