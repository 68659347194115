import Vue from 'vue'
import Router from 'vue-router'
import AuthLayout from '@/layouts/Auth'
import MainLayout from '@/layouts/Main'
import store from '@/store'
import { notification } from 'ant-design-vue'
// import { check } from 'prettier'
Vue.use(Router)

const router = new Router({
  base: process.env.BASE_URL,
  // mode: 'history',
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      redirect: 'dashboard',
      component: MainLayout,
      meta: {
        authRequired: true,
        hidden: true,
      },
      children: [
        // Dashboards
        {
          path: '/demo/alpha',
          meta: {
            title: 'Alpha Dashboard',
            // permissions: true,
          },
          component: () => import('./views/dashboard/alpha/index.vue'),
        },
        {
          path: '/demo/beta',
          meta: {
            title: 'Beta Dashboard',
            // permissions: true,
          },
          component: () => import('./views/dashboard/beta/index.vue'),
        },
        {
          path: '/demo/crypto',
          meta: {
            title: 'Crypto Dashboard',
            // permissions: true,
          },
          component: () => import('./views/dashboard/crypto/index.vue'),
        },
        {
          path: '/demo/gamma',
          meta: {
            title: 'Gamma Dashboard',
            // permissions: true,
          },
          component: () => import('./views/dashboard/gamma/index.vue'),
        },
        {
          path: '/page/404',
          meta: {
            title: 'Page Not Found',
          },
          component: () => import('./views/auth/404'),
        },
        {
          path: '/dashboard',
          meta: {
            title: 'Dashboard',
          },
          component: () => import('./views/app/apps/dashboard/index.vue'),
        },
        {
          path: '/testdata',
          meta: {
            title: 'Test Data',
          },
          component: () => import('./views/app/apps/dashboard/testdata.vue'),
        },
        {
          path: '/validation',
          meta: {
            title: 'LBBPRS Form Validation',
            // permissions: '',
          },
          component: () => import('./views/app/apps/dashboard/testdata.vue'),
        },
        {
          path: '/lbbpr/validation',
          meta: {
            title: 'LBBPR Form Validation',
          },
          component: () => import('./views/app/apps/dashboard/testdata.vue'),
        },
        // {
        //   path: '/dashboard/main',
        //   meta: {
        //     title: 'Dashboard Main',
        //   },
        //   component: () => import('./views/dashboard/alpha/index'),
        // },
        {
          path: '/widgets/charts',
          meta: {
            title: 'Widgets / Charts',
          },
          component: () => import('./views/widgets/charts'),
        },
        {
          path: '/dashboard/main',
          meta: {
            title: 'Dashboard Main',
          },
          // component: () => import('./views/app/dashboard/index.vue'),
        },
        {
          path: '/realdashboard',
          meta: {
            title: 'Location Monitoring Dashboard1',
          },
          component: () => import('./views/dashboard/alpha/index.vue'),
        },
        {
          path: '/lmdashboard',
          meta: {
            title: 'Location Monitoring Dashboard1',
          },
          component: () => import('./views/app/experiment/locationmonitoring/dashboard.vue'),
        },
        {
          path: '/leafletmap',
          meta: {
            title: 'Location Monitoring',
          },
          component: () => import('./views/app/experiment/locationmonitoring/leafletjs.vue'),
        },
        {
          path: '/kunjungan',
          meta: {
            title: 'Timeline Kunjungan',
          },
          component: () => import('./views/app/experiment/locationmonitoring/kunjungan.vue'),
        },
        {
          path: '/lm/nasabah',
          meta: {
            title: 'Nasabah',
          },
          component: () => import('./views/app/experiment/locationmonitoring/nasabah.vue'),
        },
        {
          path: '/gmap',
          meta: {
            title: 'Experiment Google Map',
          },
          component: () => import('./views/app/experiment/locationmonitoring/testmaps.vue'),
        },
        // {

        /* Start Of Retails Route */
        {
          path: '/masters/barang',
          meta: {
            title: 'Master Barang',
            // permissions: true,
          },
          component: () => import('./views/app/apps/retail/masters/barang/index.vue'),
        },
        {
          path: '/masters/suppliers',
          meta: {
            title: 'Master Supplier',
            // permissions: true,
          },
          component: () => import('./views/app/apps/retail/masters/supplier/index.vue'),
        },
        {
          path: '/masters/customers',
          meta: {
            title: 'Master Customer',
            // permissions: true,
          },
          component: () => import('./views/app/apps/retail/masters/customer/index.vue'),
        },
        {
          path: '/transaksi/pembelian',
          meta: {
            title: 'Transaksi Pembelian',
            // permissions: true,
          },
          component: () => import('./views/app/apps/retail/transactions/pembelian/parent.vue'),
        },
        {
          path: '/transaksi/penjualan',
          meta: {
            title: 'Transaksi Penjualan',
            // permissions: true,
          },
          component: () => import('./views/app/apps/retail/transactions/penjualan/parent.vue'),
        },
        {
          path: '/transaksi/repack',
          meta: {
            title: 'Transaksi Re-Pack',
            // permissions: true,
          },
          component: () => import('./views/app/apps/retail/transactions/repack/parent.vue'),
        },
        {
          path: '/laporan/stok',
          meta: {
            title: 'Laporan Stok',
            // permissions: true,
          },
          component: () => import('./views/app/apps/retail/laporan/stok/index.vue'),
        },
        /* End Of Retails Route */

        /* Start Of Mobile Admin Route */
        {
          path: '/mobileadmin/news',
          meta: {
            title: 'News',
          },
          component: () => import('./views/app/apps/mobileadmin/news/index.vue'),
        },
        {
          path: '/mobileadmin/news/form',
          meta: {
            title: 'News / Form',
          },
          component: () => import('./views/app/apps/mobileadmin/news/form.vue'),
        },
        /* End Of Mobile Admin Route */

        /* Start Of Pajak BP2RD Route */
        {
          path: '/pajakbp2rd/transaksi',
          meta: {
            title: 'List Data Pembayaran Pajak',
            permissions: true,
          },
          component: () => import('./views/app/apps/pajakbp2rd/index.vue'),
        },
        {
          path: '/pajakbp2rd/config',
          meta: {
            title: 'Konfigurasi Kas Teller',
            permissions: true,
          },
          component: () => import('./views/app/apps/pajakbp2rd/kasteller.vue'),
        },
        /* End Of Pajak BP2RD Route */

        /* Start Of Bahari Biller Route */
        {
          path: '/baharibiller/transfer',
          meta: {
            title: 'Biller - Transfer Saldo',
          },
          component: () => import('./views/app/apps/baharibiller/transfer.vue'),
        },
        {
          path: '/baharibiller/data-transaksi',
          meta: {
            title: 'Biller - Data Transaksi',
          },
          component: () => import('./views/app/apps/baharibiller/datatransaksi.vue'),
        },
        {
          path: '/baharibiller/listtransaksi',
          meta: {
            title: 'Biller - List Transaksi',
          },
          component: () => import('./views/app/apps/baharibiller/listtransaksi.vue'),
        },
        /* End Of Mobile Admin Route */

        /* Start Of Whatsapp Route */
        {
          path: '/whatsapp/sessions',
          meta: {
            title: 'WA-Sessions',
          },
          component: () => import('./views/app/apps/whatsapp/sessions/index.vue'),
        },
        {
          path: '/whatsapp/chats',
          meta: {
            title: 'WA-Chats',
          },
          component: () => import('./views/app/apps/whatsapp/chats/index.vue'),
        },
        {
          path: '/whatsapp/contacts',
          meta: {
            title: 'WA-Contacts',
          },
          component: () => import('./views/app/apps/whatsapp/contacts/index.vue'),
        },
        {
          path: '/whatsapp/groups',
          meta: {
            title: 'WA-Groups',
          },
          component: () => import('./views/app/apps/whatsapp/groups/index.vue'),
        },
        {
          path: '/whatsapp/messages',
          meta: {
            title: 'WA-Messages',
          },
          component: () => import('./views/app/apps/whatsapp/messages/index.vue'),
        },
        /* End Of Whatsapp Route */

        // Start Of School Management
        {
          path: '/schoolmanagement/dashboard',
          meta: {
            title: 'Dashboard',
            permissions: true,
          },
          component: () => import('./views/app/apps/schoolmanagement/dashboard/index.vue'),
        },
        {
          path: '/schoolmanagement/guru/data',
          meta: {
            title: 'School Management | Guru',
            permissions: true,
          },
          component: () => import('./views/app/apps/schoolmanagement/guru/index.vue'),
        },
        {
          path: '/schoolmanagement/siswa/data',
          meta: {
            title: 'School Management | Siswa',
            permissions: true,
          },
          component: () => import('./views/app/apps/schoolmanagement/siswa/index.vue'),
        },
        {
          path: '/schoolmanagement/tahunajaran/data',
          meta: {
            title: 'School Management | Tahun Ajaran',
            permissions: true,
          },
          component: () => import('./views/app/apps/schoolmanagement/tahunajaran/index.vue'),
        },
        {
          path: '/schoolmanagement/pembayaran/form',
          meta: {
            title: 'School Management | Pembayaran',
            // permissions: true,
          },
          component: () => import('./views/app/apps/schoolmanagement/pembayaran/form.vue'),
        },
        {
          path: '/schoolmanagement/pembayaran/data',
          meta: {
            title: 'School Management | Pembayaran',
            permissions: true,
          },
          component: () => import('./views/app/apps/schoolmanagement/pembayaran/index.vue'),
        },
        {
          path: '/schoolmanagement/presensi/data',
          meta: {
            title: 'School Management | Presensi',
            permissions: true,
          },
          component: () => import('./views/app/apps/schoolmanagement/presensi/index.vue'),
        },
        {
          path: '/schoolmanagement/presensi/today',
          meta: {
            title: 'School Management | Presensi',
            // permissions: false,
          },
          component: () => import('./views/app/apps/schoolmanagement/presensi/form.vue'),
        },
        {
          path: '/schoolmanagement/announcement',
          meta: {
            title: 'School Management | Announcement',
            permissions: true,
          },
          component: () => import('./views/app/apps/schoolmanagement/announcement/index.vue'),
        },
        {
          path: '/schoolmanagement/profilsekolah',
          meta: {
            title: 'School Management | Profil Sekolah',
            permissions: true,
          },
          component: () => import('./views/app/apps/schoolmanagement/profilsekolah/index.vue'),
        },
        {
          path: '/schoolmanagement/jadwalpembayaran/data',
          meta: {
            title: 'School Management | Jadwal Pembayaran',
            permissions: true,
          },
          component: () => import('./views/app/apps/schoolmanagement/jadwalpembayaran/index.vue'),
        },
        {
          path: '/schoolmanagement/pengeluaran/data',
          meta: {
            title: 'School Management | Pengeluaran',
            permissions: true,
          },
          component: () => import('./views/app/apps/schoolmanagement/pengeluaran/index.vue'),
        },
        {
          path: '/schoolmanagement/pemasukanlain/data',
          meta: {
            title: 'School Management | Pemasukan Lainnya',
            permissions: true,
          },
          component: () => import('./views/app/apps/schoolmanagement/pemasukanlainnya/index.vue'),
        },
        {
          path: '/schoolmanagement/kelas/data',
          meta: {
            title: 'School Management | Kelas',
            permissions: true,
          },
          component: () => import('./views/app/apps/schoolmanagement/kelas/index.vue'),
        },
        {
          path: '/schoolmanagement/kelas/form',
          meta: {
            title: 'Form Kelas',
            // permissions: true,
          },
          component: () => import('./views/app/apps/schoolmanagement/kelas/form.vue'),
        },
        {
          path: '/schoolmanagement/masters/kategoripemasukan/data',
          meta: {
            title: 'Masters | Kategori Pemasukan',
            permissions: true,
          },
          component: () => import('./views/app/apps/schoolmanagement/masters/kategoripemasukan/index.vue'),
        },
        {
          path: '/schoolmanagement/masters/kategoripengeluaran/data',
          meta: {
            title: 'Masters | Kategori Pengeluaran',
            permissions: true,
          },
          component: () => import('./views/app/apps/schoolmanagement/masters/kategoripengeluaran/index.vue'),
        },
        // End Of School Management

        /* Start Of Taksasi Jaminan Route */
        {
          path: '/taksasijaminan/bpkb',
          meta: {
            title: 'Taksasi Jaminan BPKB',
          },
          component: () => import('./views/app/apps/taksasijaminan/taksasi/indexBPKB/index.vue'),
        },
        {
          path: '/taksasijaminan/bpkb/form',
          meta: {
            title: 'Taksasi Jaminan BPKB',
          },
          component: () => import('./views/app/apps/taksasijaminan/taksasi/indexBPKB/form/form.vue'),
        },
        {
          path: '/taksasijaminan/shm',
          meta: {
            title: 'Taksasi Jaminan SHM',
          },
          component: () => import('./views/app/apps/taksasijaminan/taksasi/indexSHM/index.vue'),
        },
        {
          path: '/taksasijaminan/pemohon/list',
          meta: {
            title: 'List pemohon',
          },
          component: () => import('./views/app/apps/taksasijaminan/taksasi/indexPemohon/index.vue'),
        },
        {
          path: '/taksasijaminan/shm/form',
          meta: {
            title: 'Taksasi Jaminan SHM',
          },
          component: () => import('./views/app/apps/taksasijaminan/taksasi/indexSHM/form/form.vue'),
        },
        {
          path: '/taksasijaminan/deposito',
          meta: {
            title: 'Taksasi Jaminan Deposito',
          },
          component: () => import('./views/app/apps/taksasijaminan/taksasi/indexDeposito/index.vue'),
        },
        {
          path: '/taksasijaminan/deposito/form',
          meta: {
            title: 'Taksasi Jaminan Deposito',
          },
          component: () => import('./views/app/apps/taksasijaminan/taksasi/indexDeposito/form/form.vue'),
        },
        {
          path: '/taksasijaminan/analisamikro',
          meta: {
            title: 'Analisis Kredit',
          },
          component: () => import('./views/app/apps/taksasijaminan/taksasi/kredit/index.vue'),
        },
        {
          path: '/taksasijaminan/analisamikro/form/ultramicro',
          meta: {
            title: 'Form Analisa Ultra Mikro',
          },
          component: () => import('./views/app/apps/taksasijaminan/taksasi/kredit/form/formkreditum.vue'),
        },
        {
          path: '/taksasijaminan/analisaslik',
          meta: {
            title: 'Analisa Slik',
            roles: undefined,
            permissions: undefined,
          },
          component: () => import('./views/app/apps/taksasijaminan/taksasi/analisaslik/index.vue'),
        },
        {
          path: '/taksasijaminan/analisaslik/form',
          meta: {
            title: 'Form Analisa Slik',
            roles: undefined,
            permissions: undefined,
          },
          component: () => import('./views/app/apps/taksasijaminan/taksasi/analisaslik/form.vue'),
        },
        // {
        //   path: '/taksasijaminan/dashboard/logtransaction',
        //   meta: {
        //     title: 'Dashboard Log Transaction',
        //     permissions: ['access-sewa_ban-log', 'access-test_ban-log'],
        //   },
        //   component: () => import('./views/app/dashboard/transaction'),
        // },
        // {
        //   path: 'apps/Chatting',
        //   name: 'chatting',
        //   meta: {
        //     title: 'App / Chatting',
        //   },
        //   component: () => import('./views/app/chatting/index.vue'),
        // },
        // {
        //   path: '/taksasijaminan/master/roles',
        //   name: 'roles',
        //   meta: {
        //     title: 'Master / Roles',
        //     permissions: ['access-roles'],
        //   },
        //   component: () => import('./views/app/master/roles/index.vue'),
        // },
        {
          path: '/taksasijaminan/setting/shift',
          name: 'Shift',
          meta: {
            title: 'Shift settings',
          },
          component: () => import('./views/app/master/shift/index.vue'),
        },
        {
          path: '/taksasijaminan/settings',
          name: 'Settings',
          meta: {
            title: 'Settings',
          },
          component: () => import('./views/app/apps/taksasijaminan/master/settings/index.vue'),
        },
        {
          path: '/taksasijaminan/notification',
          name: 'notificationBroadcast',
          meta: {
            title: 'Pemberitahuan',
          },
          component: () => import('./views/app/apps/notification/index.vue'),
        },

        /* Laporan */
        // {
        //   path: 'apps/laporan/odopermobil',
        //   name: 'odopermobil',
        //   meta: {
        //     title: 'Rekap / Odo per Mobil',
        //     permissions: ['access-sewa_ban-sp_rekap_odo', 'access-test_ban-sp_rekap_odo'],
        //   },
        //   component: () => import('./views/app/laporan/odopermobil/index.vue'),
        // },
        // {
        //   path: '/taksasijaminan/laporan/penjualan/detail',
        //   name: 'lappenjualandetail',
        //   meta: {
        //     title: 'Detail Penjualan',
        //     permissions: ['access-ukmsys-laptrxjual'],
        //   },
        //   component: () => import('./views/app/laporan/detail_penjualan/index.vue'),
        // },
        // {
        //   path: '/taksasijaminan/jurnal/laporan/bukubesar',
        //   name: 'bukubesar',
        //   meta: {
        //     title: 'Laporan / Buku Besar',
        //     permissions: ['access-ukmsys-bukubesar'],
        //   },
        //   component: () => import('./views/app/jurnal/laporan/bukubesar/index.vue'),
        // },
        // {
        //   path: '/taksasijaminan/jurnal/laporan/neraca',
        //   name: 'neraca',
        //   meta: {
        //     title: 'Laporan / Buku Besar',
        //     permissions: ['access-ukmsys-neraca'],
        //   },
        //   component: () => import('./views/app/jurnal/laporan/neraca/index.vue'),
        // },
        /* End Of Taksasi Jaminan Route */

        // Start of CBS Jombang Route
        {
          path: '/cbsjombang/mutasitabungan/form',
          meta: {
            title: 'CBS Jombang Mutasi Tabungan',
            permissions: true,
          },
          component: () => import('./views/app/apps/cbsjombang/mutasitabungan/index.vue'),
        },
        {
          path: '/cbsjombang/mutasitabungan/laporan',
          meta: {
            title: 'CBS Jombang Laporan Transaksi',
            permissions: true,
          },
          component: () => import('./views/app/apps/cbsjombang/mutasitabungan/laporan.vue'),
        },
        {
          path: '/cbsjombang/mutasitabungan/laporan',
          meta: {
            title: 'CBS Jombang Laporan Mutasi harian',
            permissions: true,
          },
          component: () => import('./views/app/apps/cbsjombang/mutasitabungan/laporanmutasiharian.vue'),
        },
        {
          path: '/cbsjombang/mutasitabungan/bukutabungan',
          meta: {
            title: 'CBS Jombang Laporan Buku Tabungan',
            // permissions: true,
          },
          component: () => import('./views/app/apps/cbsjombang/mutasitabungan/reprintbukutabungan.vue'),
        },
        {
          path: '/cbsjombang/registernasabah/data',
          meta: {
            title: 'CBS Jombang Register Nasabah',
            // permissions: true,
          },
          component: () => import('./views/app/apps/cbsjombang/registernasabah/index.vue'),
        },
        {
          path: '/cbsjombang/registernasabah/form',
          name: 'nasabahedit',
          meta: {
            title: 'CBS Jombang Register Nasabah',
            // permissions: true,
          },
          component: () => import('./views/app/apps/cbsjombang/registernasabah/form.vue'),
        },
        {
          path: '/cbsjombang/registerbadanusaha/data',
          meta: {
            title: 'CBS Jombang Register Badan Usaha',
            // permissions: true,
          },
          component: () => import('./views/app/apps/cbsjombang/registerbadanusaha/index.vue'),
        },
        {
          path: '/cbsjombang/registerbadanusaha/form',
          name: 'badanusahaedit',
          meta: {
            title: 'CBS Jombang Register Badan Usaha',
            // permissions: true,
          },
          component: () => import('./views/app/apps/cbsjombang/registerbadanusaha/form.vue'),
        },
        {
          path: '/cbsjombang/tabungan/pembukaan/data',
          meta: {
            title: 'Pembukaan Tabungan',
            // permissions: true,
          },
          component: () => import('./views/app/apps/cbsjombang/tabungan/pembukaan/index.vue'),
        },
        {
          path: '/cbsjombang/laporan/registernasabah/data',
          meta: {
            title: 'Laporan Register Nasasbah',
            // permissions: true,
          },
          component: () => import('./views/app/apps/cbsjombang/laporan/registernasabah.vue'),
        },
        {
          path: '/cbsjombang/tabungan/pembukaan/form/tambah',
          name: 'pembukaantabungantambah',
          meta: {
            title: 'Tambah Data Pembukaan Tabungan',
            // permissions: true,
          },
          component: () => import('./views/app/apps/cbsjombang/tabungan/pembukaan/form.vue'),
        },
        {
          path: '/cbsjombang/tabungan/pembukaan/form/ubah',
          name: 'pembukaantabunganedit',
          meta: {
            title: 'Ubah Data Pembukaan Tabungan',
            // permissions: true,
          },
          component: () => import('./views/app/apps/cbsjombang/tabungan/pembukaan/form.vue'),
        },
        {
          path: '/cbsjombang/registernasabah/cari',
          meta: {
            title: 'CBS Jombang Cari Data Register',
            // permissions: true,
          },
          component: () => import('./views/app/apps/cbsjombang/registernasabah/cariregisternasabah.vue'),
        },
        // End Of CBS Jombang Route

        // Start of CBS TDN Route
        {
          path: '/backoffice/ecp/dashboardrekap',
          meta: {
            title: 'Dashboard ECP',
            permissions: true,
          },
          component: () => import('./views/app/apps/ecp/dashboard.vue'),
        },
        {
          path: '/backoffice/ecp/dasbboard',
          meta: {
            title: 'Dashboard ECP',
            permissions: true,
          },
          component: () => import('./views/app/apps/dashboard/index.vue'),
        },
        {
          path: '/backoffice/ecp/setting',
          meta: {
            title: 'Setting / BackOffice ECP',
            permissions: true,
          },
          component: () => import('./views/app/apps/ecp/settings/index.vue'),
        },
        {
          path: '/backoffice/ecp/nasabah',
          meta: {
            title: 'Nasabah / BackOffice ECP',
            permissions: true,
          },
          component: () => import('./views/app/apps/ecp/nasabah/index.vue'),
        },
        {
          path: '/backoffice/ecp/mutasi',
          meta: {
            title: 'Mutasi / BackOffice ECP',
            permissions: true,
          },
          component: () => import('./views/app/apps/ecp/mutasi/index.vue'),
        },
        {
          path: '/backoffice/ecp/sync',
          meta: {
            title: 'SYNC / BackOffice ECP',
            permissions: true,
          },
          component: () => import('./views/app/apps/ecp/sync/index.vue'),
        },
        // End of CBS TDN Route

        // Start of CBS TDN Route
        {
          path: '/bahari/retribusi',
          meta: {
            title: 'Bahari Retribusi',
            permissions: true,
          },
          component: () => import('./views/app/apps/retribusi/index.vue'),
        },
        // End of CBS TDN Route

        // Start of CBS TDN Route
        {
          path: '/cbstdn/mutasitabungan/form',
          meta: {
            title: 'CBS TDN Mutasi Tabungan',
            permissions: true,
          },
          component: () => import('./views/app/apps/cbspare/mutasitabungan/index.vue'),
        },
        {
          path: '/cbstdn/mutasitabungan/laporan',
          meta: {
            title: 'CBS TDN Laporan Transaksi',
            permissions: true,
          },
          component: () => import('./views/app/apps/cbspare/mutasitabungan/laporan.vue'),
        },
        {
          path: '/cbstdn/mutasitabungan/laporan',
          meta: {
            title: 'CBS TDN Laporan Mutasi harian',
            permissions: true,
          },
          component: () => import('./views/app/apps/cbspare/mutasitabungan/laporanmutasiharian.vue'),
        },
        {
          path: '/cbstdn/mutasitabungan/bukutabungan',
          meta: {
            title: 'CBS TDN Laporan Buku Tabungan',
            // permissions: true,
          },
          component: () => import('./views/app/apps/cbspare/mutasitabungan/reprintbukutabungan.vue'),
        },
        {
          path: '/cbstdn/registernasabah/data',
          meta: {
            title: 'CBS TDN Register Nasabah',
            // permissions: true,
          },
          component: () => import('./views/app/apps/cbspare/registernasabah/index.vue'),
        },
        {
          path: '/cbstdn/registernasabah/form',
          name: 'nasabaheditcbstdn',
          meta: {
            title: 'CBS TDN Register Nasabah',
            // permissions: true,
          },
          component: () => import('./views/app/apps/cbspare/registernasabah/form.vue'),
        },
        {
          path: '/cbstdn/registernasabah/cari',
          meta: {
            title: 'CBS TDN Cari Data Register',
            // permissions: true,
          },
          component: () => import('./views/app/apps/cbspare/registernasabah/cariregisternasabah.vue'),
        },
        // End Of CBS TDN Route

        // Start of Gaji Route
        {
          path: '/gaji/dashboard/view',
          meta: {
            title: 'Dashbooard View 1',
            permissions: true,
          },
          component: () => import('./views/app/apps/gaji/dashboard/view.vue'),
        },
        {
          path: '/gaji/information/bank',
          meta: {
            title: 'Informasi Rekening',
            permissions: true,
          },
          component: () => import('./views/app/apps/gaji/information/rekening/index.vue'),
        },
        {
          path: '/gaji/golongan',
          meta: {
            title: 'Gaji Golongan',
            permissions: true,
          },
          component: () => import('./views/app/apps/gaji/golongan/index.vue'),
        },
        {
          path: '/gaji/instansi',
          meta: {
            title: 'Gaji Instansi',
            permissions: true,
          },
          component: () => import('./views/app/apps/gaji/instansi/index.vue'),
        },
        {
          path: '/gaji/pegawai',
          meta: {
            title: 'Gaji Pegawai',
            permissions: true,
          },
          component: () => import('./views/app/apps/gaji/pegawai/index.vue'),
        },
        {
          path: '/gaji/penggajian',
          meta: {
            title: 'Gaji Penggajian',
            permissions: true,
          },
          component: () => import('./views/app/apps/gaji/penggajian/index.vue'),
        },
        // End of Gaji Route

        // Start of Koperasi Syariah Route
        {
          path: '/apuppt/templatekorporasi/form',
          meta: {
            title: 'Data Template Bank | Form',
            // permissions: true,
          },
          component: () => import('./views/app/apps/koperasisyariah/temp/templatekorporasi/form.vue'),
        },
        {
          path: '/apuppt/templatekorporasi',
          meta: {
            title: 'Data Template Bank',
            // permissions: true,
          },
          component: () => import('./views/app/apps/koperasisyariah/temp/templatekorporasi/templatekorporasi.vue'),
        },
        {
          path: '/apuppt/templateperorangan',
          meta: {
            title: 'Data Template Perorangan',
            // permissions: true,
          },
          component: () => import('./views/app/apps/koperasisyariah/temp/apuppt.vue'),
        },
        {
          path: '/koperasisyariah/report/akuntansi/jurnalumum',
          meta: {
            title: 'Jurnal Umum Akuntansi',
            // permissions: true,
          },
          component: () => import('./views/app/apps/koperasisyariah/akuntansi/report/jurnalumum.vue'),
        },
        {
          path: '/koperasisyariah/report/akuntansi/neraca',
          meta: {
            title: 'Neraca Akuntansi',
            // permissions: true,
          },
          component: () => import('./views/app/apps/koperasisyariah/akuntansi/report/aggrid-neraca.vue'),
        },
        {
          path: '/koperasisyariah/report/akuntansi/labarugi',
          meta: {
            title: 'Laba Rugi Akuntansi',
            // permissions: true,
          },
          component: () => import('./views/app/apps/koperasisyariah/akuntansi/report/aggrid-labarugi.vue'),
        },
        {
          path: '/koperasisyariah/report/akuntansi/bukubesar',
          meta: {
            title: 'Buku Besar Akuntansi',
            // permissions: true,
          },
          component: () => import('./views/app/apps/koperasisyariah/akuntansi/report/bukubesar.vue'),
        },
        {
          path: '/koperasisyariah/report/akuntansi/inventaris',
          meta: {
            title: 'Inventaris Akuntansi',
            // permissions: true,
          },
          component: () => import('./views/app/apps/koperasisyariah/akuntansi/report/inventaris.vue'),
        },
        {
          path: '/koperasisyariah/report/akuntansi/amortisasi',
          meta: {
            title: 'Amortisasi Akuntansi',
            // permissions: true,
          },
          component: () => import('./views/app/apps/koperasisyariah/akuntansi/report/amortisasi.vue'),
        },
        {
          path: '/koperasisyariah/transaksi/akuntansi/inventaris',
          meta: {
            title: 'Inventaris',
            // permissions: true,
          },
          component: () => import('./views/app/apps/koperasisyariah/transaksi/akuntansi/inventaris/parent.vue'),
        },
        {
          path: '/koperasisyariah/settings/shu',
          meta: {
            title: 'Setting SHU | Koperasi Syariah',
            // permissions: true,
          },
          component: () => import('./views/app/apps/koperasisyariah/settings/configshu.vue'),
        },
        {
          path: '/koperasisyariah/settings/bukutabungan',
          meta: {
            title: 'Setting Buku Tabungan | Koperasi Syariah',
            // permissions: true,
          },
          component: () => import('./views/app/apps/koperasisyariah/settings/configbukutabungan.vue'),
        },
        {
          path: '/koperasisyariah/settings/kasteller',
          meta: {
            title: 'Setting Kas Teller | Koperasi Syariah',
            // permissions: true,
          },
          component: () => import('./views/app/apps/koperasisyariah/settings/configkasteller.vue'),
        },
        {
          path: '/koperasisyariah/settings/pengesahan',
          meta: {
            title: 'Setting Pengesahan | Koperasi Syariah',
            // permissions: true,
          },
          component: () => import('./views/app/apps/koperasisyariah/settings/pengesahan.vue'),
        },
        {
          path: '/koperasisyariah/master/produksimpanan',
          meta: {
            title: 'Master Produk Simpanan | Koperasi Syariah',
            // permissions: true,
          },
          component: () => import('./views/app/apps/koperasisyariah/masters/produksimpanan.vue'),
        },
        {
          path: '/koperasisyariah/master/produksimpananberjangka',
          meta: {
            title: 'Master Produk Simpanan Berjangka | Koperasi Syariah',
            // permissions: true,
          },
          component: () => import('./views/app/apps/koperasisyariah/masters/produksimpananberjangka.vue'),
        },
        {
          path: '/koperasisyariah/master/produkpembiayaan',
          meta: {
            title: 'Master Produk Pembiayaan | Koperasi Syariah',
            // permissions: true,
          },
          component: () => import('./views/app/apps/koperasisyariah/masters/produkpembiayaan.vue'),
        },
        {
          path: '/koperasisyariah/master/accountofficer',
          meta: {
            title: 'Master Account Officer | Koperasi Syariah',
            // permissions: true,
          },
          component: () => import('./views/app/apps/koperasisyariah/masters/ao.vue'),
        },
        {
          path: '/koperasisyariah/master/kodetransaksisimpanan',
          meta: {
            title: 'Master Kode Transaksi Simpanan | Koperasi Syariah',
            // permissions: true,
          },
          component: () => import('./views/app/apps/koperasisyariah/masters/kodetransaksisimpanan.vue'),
        },
        {
          path: '/koperasisyariah/master/akutansi',
          meta: {
            title: 'Master Akutansi | Koperasi Syariah',
            // permissions: true,
          },
          component: () => import('./views/app/apps/koperasisyariah/masters/akuntansi.vue'),
        },
        {
          path: '/koperasisyariah/transaksi/akuntansi/jurnalumum',
          meta: {
            title: 'Transaksi Akuntansi Jurnal Umum',
            // permissions: true,
          },
          component: () => import('./views/app/apps/koperasisyariah/transaksi/akuntansi/jurnalumum/parent.vue'),
        },
        {
          path: '/koperasisyariah/transaksi/pembiayaan/pencairan/data',
          meta: {
            title: 'Transaksi Pencairan Pembiayaan',
            // permissions: true,
          },
          component: () => import('./views/app/apps/koperasisyariah/pencairanpembiayaan/index.vue'),
        },
        {
          path: '/koperasisyariah/transaksi/pembiayaan/pencairan/form',
          meta: {
            title: 'Transaksi Pencairan Pembiayaan | Form',
            // permissions: true,
          },
          component: () => import('./views/app/apps/koperasisyariah/pencairanpembiayaan/form.vue'),
        },
        {
          path: '/koperasisyariah/transaksi/pembiayaan/angsuran/data',
          meta: {
            title: 'Transaksi Angsuran',
            // permissions: true,
          },
          component: () => import('./views/app/apps/koperasisyariah/transaksiangsuran/index.vue'),
        },
        {
          path: '/koperasisyariah/transaksi/pembiayaan/angsuran/form',
          meta: {
            title: 'Transaksi Angsuran | Form',
            // permissions: true,
          },
          component: () => import('./views/app/apps/koperasisyariah/transaksiangsuran/form.vue'),
        },
        {
          path: '/koperasisyariah/simpanan/form',
          meta: {
            title: 'Koperasi Syariah Form',
            // permissions: true,
          },
          component: () => import('./views/app/apps/koperasisyariah/simpanan/form.vue'),
        },
        {
          path: '/koperasisyariah/simpanan/data',
          meta: {
            title: 'Koperasi Syariah Simpanan',
            // permissions: true,
          },
          component: () => import('./views/app/apps/koperasisyariah/simpanan/index.vue'),
        },
        {
          path: '/koperasisyariah/simpananberjangka/setoranpokok/form',
          meta: {
            title: 'Koperasi Syariah Form',
            // permissions: true,
          },
          component: () => import('./views/app/apps/koperasisyariah/simpananberjangka/setoranpokok/form.vue'),
        },
        {
          path: '/koperasisyariah/setting/konfigbilyet',
          meta: {
            title: 'Setting | Konfig Bilyet',
            // permissions: true,
          },
          component: () => import('./views/app/apps/koperasisyariah/testinggdrag.vue'),
        },
        {
          path: '/koperasisyariah/simpananberjangka/setoranpokok/data',
          meta: {
            title: 'Koperasi Syariah Simpanan Berjangka Setoran Pokok',
            // permissions: true,
          },
          component: () => import('./views/app/apps/koperasisyariah/simpananberjangka/setoranpokok/index.vue'),
        },
        {
          path: '/koperasisyariah/simpananberjangka/pencairanpokok/data',
          meta: {
            title: 'Koperasi Syariah Simpanan  Berjangka Pencairan Pokok',
            // permissions: true,
          },
          component: () => import('./views/app/apps/koperasisyariah/simpananberjangka/pencairanpokok/index.vue'),
        },
        {
          path: '/koperasisyariah/simpanan/cetakbukusimpanan',
          meta: {
            title: 'Koperasi Syariah Cetak Simpanan',
            // permissions: true,
          },
          component: () => import('./views/app/apps/koperasisyariah/simpanan/reprintbukusimpanan.vue'),
        },
        {
          path: '/koperasisyariah/registernasabah/data',
          meta: {
            title: 'Koperasi Syariah Register',
            // permissions: true,
          },
          component: () => import('./views/app/apps/koperasisyariah/registernasabah/index.vue'),
        },
        {
          path: '/koperasisyariah/registrasisimpanan/data',
          meta: {
            title: 'Koperasi Syariah Registrasi Simpanan',
            // permissions: true,
          },
          component: () => import('./views/app/apps/koperasisyariah/registrasisimpanan/index.vue'),
        },
        {
          path: '/koperasisyariah/registrasisimpanan/form',
          meta: {
            title: 'Form | Registrasi Simpanan',
            // permissions: true,
          },
          component: () => import('./views/app/apps/koperasisyariah/registrasisimpanan/form.vue'),
        },
        {
          path: '/koperasisyariah/simulasi/jadwalangsuran',
          meta: {
            title: 'Koperasi Syariah Simulasi',
            // permissions: true,
          },
          component: () => import('./views/app/apps/koperasisyariah/simulasijadwalangsuran/index.vue'),
        },
        {
          path: '/koperasisyariah/pembiayaan/data',
          meta: {
            title: 'Koperasi Syariah Pembiayaan',
            // permissions: true,
          },
          component: () => import('./views/app/apps/koperasisyariah/pembiayaan/index.vue'),
        },
        {
          path: '/koperasisyariah/pembiayaan/form',
          meta: {
            title: 'Form | Pembiayaan',
            // permissions: true,
          },
          component: () => import('./views/app/apps/koperasisyariah/pembiayaan/form.vue'),
        },
        {
          path: '/koperasisyariah/registrasisimpananberjangka/data',
          meta: {
            title: 'Koperasi Syariah Registrasi Simpanan Berjangka',
            // permissions: true,
          },
          component: () => import('./views/app/apps/koperasisyariah/registrasisimpananberjangka/index.vue'),
        },
        {
          path: '/koperasisyariah/registrasisimpananberjangka/form',
          meta: {
            title: 'Form | Registrasi Simpanan Berjangka',
            // permissions: true,
          },
          component: () => import('./views/app/apps/koperasisyariah/registrasisimpananberjangka/form.vue'),
        },
        {
          path: '/koperasisyariah/registernasabah/formanggota',
          name: 'koperasisyariahnasabaheditanggota',
          meta: {
            title: 'Koperasi Syariah Register Anggota',
            // permissions: true,
          },
          component: () => import('./views/app/apps/koperasisyariah/registernasabah/newform-anggota.vue'),
        },
        // End of Koperasi Syariah Route

        // Start of Mini Bank System Route
        {
          path: '/minibank/apuppt/templatekorporasi/form',
          meta: {
            title: 'Data Template Bank | Form',
            // permissions: true,
          },
          component: () => import('./views/app/apps/minibank/temp/templatekorporasi/form.vue'),
        },
        {
          path: '/minibank/apuppt/templatekorporasi',
          meta: {
            title: 'Data Template Bank',
            // permissions: true,
          },
          component: () => import('./views/app/apps/minibank/temp/templatekorporasi/templatekorporasi.vue'),
        },
        {
          path: '/minibank/apuppt/templateperorangan',
          meta: {
            title: 'Data Template Perorangan',
            // permissions: true,
          },
          component: () => import('./views/app/apps/minibank/temp/apuppt.vue'),
        },
        {
          path: '/minibank/report/tabungan/saldotabungan',
          meta: {
            title: 'Laporan Saldo Tabungan',
            // permissions: true,
          },
          component: () => import('./views/app/apps/minibank/laporantabungan/saldotabungan.vue'),
        },
        {
          path: '/minibank/report/akuntansi/jurnalumum',
          meta: {
            title: 'Jurnal Umum Akuntansi',
            // permissions: true,
          },
          component: () => import('./views/app/apps/minibank/akuntansi/report/jurnalumum.vue'),
        },
        {
          path: '/minibank/report/akuntansi/neraca',
          meta: {
            title: 'Neraca Akuntansi',
            // permissions: true,
          },
          component: () => import('./views/app/apps/minibank/akuntansi/report/aggrid-neraca.vue'),
        },
        {
          path: '/minibank/report/akuntansi/labarugi',
          meta: {
            title: 'Laba Rugi Akuntansi',
            // permissions: true,
          },
          component: () => import('./views/app/apps/minibank/akuntansi/report/aggrid-labarugi.vue'),
        },
        {
          path: '/minibank/report/akuntansi/bukubesar',
          meta: {
            title: 'Buku Besar Akuntansi',
            // permissions: true,
          },
          component: () => import('./views/app/apps/minibank/akuntansi/report/bukubesar.vue'),
        },
        {
          path: '/minibank/report/akuntansi/inventaris',
          meta: {
            title: 'Inventaris Akuntansi',
            // permissions: true,
          },
          component: () => import('./views/app/apps/minibank/akuntansi/report/inventaris.vue'),
        },
        {
          path: '/minibank/report/akuntansi/amortisasi',
          meta: {
            title: 'Amortisasi Akuntansi',
            // permissions: true,
          },
          component: () => import('./views/app/apps/minibank/akuntansi/report/amortisasi.vue'),
        },
        {
          path: '/minibank/transaksi/akuntansi/inventaris',
          meta: {
            title: 'Inventaris',
            // permissions: true,
          },
          component: () => import('./views/app/apps/minibank/transaksi/akuntansi/inventaris/parent.vue'),
        },
        {
          path: '/minibank/settings/shu',
          meta: {
            title: 'Setting SHU | Mini Bank System',
            // permissions: true,
          },
          component: () => import('./views/app/apps/minibank/settings/configshu.vue'),
        },
        {
          path: '/minibank/settings/bukutabungan',
          meta: {
            title: 'Setting Buku Tabungan | Mini Bank System',
            // permissions: true,
          },
          component: () => import('./views/app/apps/minibank/settings/configbukutabungan.vue'),
        },
        {
          path: '/minibank/settings/kasteller',
          meta: {
            title: 'Setting Kas Teller | Mini Bank System',
            // permissions: true,
          },
          component: () => import('./views/app/apps/minibank/settings/configkasteller.vue'),
        },
        {
          path: '/minibank/settings/pengesahan',
          meta: {
            title: 'Setting Pengesahan | Mini Bank System',
            // permissions: true,
          },
          component: () => import('./views/app/apps/minibank/settings/pengesahan.vue'),
        },
        {
          path: '/minibank/master/produktabungan',
          meta: {
            title: 'Master Produk Tabungan | Mini Bank System',
            // permissions: true,
          },
          component: () => import('./views/app/apps/minibank/masters/produktabungan.vue'),
        },
        {
          path: '/minibank/master/produkdeposito',
          meta: {
            title: 'Master Produk Deposito | Mini Bank System',
            // permissions: true,
          },
          component: () => import('./views/app/apps/minibank/masters/produkdeposito.vue'),
        },
        {
          path: '/minibank/master/produkkredit',
          meta: {
            title: 'Master Produk Kredit | Mini Bank System',
            // permissions: true,
          },
          component: () => import('./views/app/apps/minibank/masters/produkkredit.vue'),
        },
        {
          path: '/minibank/master/accountofficer',
          meta: {
            title: 'Master Account Officer | Mini Bank System',
            // permissions: true,
          },
          component: () => import('./views/app/apps/minibank/masters/ao.vue'),
        },
        {
          path: '/minibank/master/kodetransaksitabungan',
          meta: {
            title: 'Master Kode Transaksi Tabungan | Mini Bank System',
            // permissions: true,
          },
          component: () => import('./views/app/apps/minibank/masters/kodetransaksitabungan.vue'),
        },
        {
          path: '/minibank/master/akutansi',
          meta: {
            title: 'Master Akutansi | Mini Bank System',
            // permissions: true,
          },
          component: () => import('./views/app/apps/minibank/masters/akuntansi.vue'),
        },
        {
          path: '/minibank/transaksi/akuntansi/jurnalumum',
          meta: {
            title: 'Transaksi Akuntansi Jurnal Umum',
            // permissions: true,
          },
          component: () => import('./views/app/apps/minibank/transaksi/akuntansi/jurnalumum/parent.vue'),
        },
        {
          path: '/minibank/transaksi/kredit/pencairan/data',
          meta: {
            title: 'Transaksi Pencairan Kredit',
            // permissions: true,
          },
          component: () => import('./views/app/apps/minibank/pencairankredit/index.vue'),
        },
        {
          path: '/minibank/transaksi/kredit/pencairan/form',
          meta: {
            title: 'Transaksi Pencairan Kredit | Form',
            // permissions: true,
          },
          component: () => import('./views/app/apps/minibank/pencairankredit/form.vue'),
        },
        {
          path: '/minibank/transaksi/kredit/angsuran/data',
          meta: {
            title: 'Transaksi Angsuran',
            // permissions: true,
          },
          component: () => import('./views/app/apps/minibank/transaksiangsuran/index.vue'),
        },
        {
          path: '/minibank/transaksi/kredit/angsuran/form',
          meta: {
            title: 'Transaksi Angsuran | Form',
            // permissions: true,
          },
          component: () => import('./views/app/apps/minibank/transaksiangsuran/form.vue'),
        },
        {
          path: '/minibank/tabungan/form',
          meta: {
            title: 'Mini Bank System Form',
            // permissions: true,
          },
          component: () => import('./views/app/apps/minibank/tabungan/form.vue'),
        },
        {
          path: '/minibank/tabungan/data',
          meta: {
            title: 'Mini Bank System Tabungan',
            // permissions: true,
          },
          component: () => import('./views/app/apps/minibank/tabungan/index.vue'),
        },
        {
          path: '/minibank/deposito/setoranpokok/form',
          meta: {
            title: 'Mini Bank System Form',
            // permissions: true,
          },
          component: () => import('./views/app/apps/minibank/deposito/setoranpokok/form.vue'),
        },
        {
          path: '/minibank/setting/konfigbilyet',
          meta: {
            title: 'Setting | Konfig Bilyet',
            // permissions: true,
          },
          component: () => import('./views/app/apps/minibank/testinggdrag.vue'),
        },
        {
          path: '/minibank/deposito/setoranpokok/data',
          meta: {
            title: 'Mini Bank System Deposito Setoran Pokok',
            // permissions: true,
          },
          component: () => import('./views/app/apps/minibank/deposito/setoranpokok/index.vue'),
        },
        {
          path: '/minibank/deposito/pencairanpokok/data',
          meta: {
            title: 'Mini Bank System Tabungan  Berjangka Pencairan Pokok',
            // permissions: true,
          },
          component: () => import('./views/app/apps/minibank/deposito/pencairanpokok/index.vue'),
        },
        {
          path: '/minibank/tabungan/cetakbukutabungan',
          meta: {
            title: 'Mini Bank System Cetak Tabungan',
            // permissions: true,
          },
          component: () => import('./views/app/apps/minibank/tabungan/reprintbukutabungan.vue'),
        },
        {
          path: '/minibank/registernasabah/data',
          meta: {
            title: 'Mini Bank System Register',
            // permissions: true,
          },
          component: () => import('./views/app/apps/minibank/registernasabah/index.vue'),
        },
        {
          path: '/minibank/registrasitabungan/data',
          meta: {
            title: 'Mini Bank System Registrasi Tabungan',
            // permissions: true,
          },
          component: () => import('./views/app/apps/minibank/registrasitabungan/index.vue'),
        },
        {
          path: '/minibank/registrasitabungan/form',
          meta: {
            title: 'Form | Registrasi Tabungan',
            // permissions: true,
          },
          component: () => import('./views/app/apps/minibank/registrasitabungan/form.vue'),
        },
        {
          path: '/minibank/simulasi/jadwalangsuran',
          meta: {
            title: 'Mini Bank System Simulasi',
            // permissions: true,
          },
          component: () => import('./views/app/apps/minibank/simulasijadwalangsuran/index.vue'),
        },
        {
          path: '/minibank/kredit/data',
          meta: {
            title: 'Mini Bank System Kredit',
            // permissions: true,
          },
          component: () => import('./views/app/apps/minibank/kredit/index.vue'),
        },
        {
          path: '/minibank/kredit/form',
          meta: {
            title: 'Form | Kredit',
            // permissions: true,
          },
          component: () => import('./views/app/apps/minibank/kredit/form.vue'),
        },
        {
          path: '/minibank/registrasideposito/data',
          meta: {
            title: 'Mini Bank System Registrasi Deposito',
            // permissions: true,
          },
          component: () => import('./views/app/apps/minibank/registrasideposito/index.vue'),
        },
        {
          path: '/minibank/registrasideposito/form',
          meta: {
            title: 'Form | Registrasi Deposito',
            // permissions: true,
          },
          component: () => import('./views/app/apps/minibank/registrasideposito/form.vue'),
        },
        {
          path: '/minibank/registernasabah/formnasabah',
          name: 'minibanknasabaheditnasabah',
          meta: {
            title: 'Mini Bank System Register Nasabah',
            // permissions: true,
          },
          component: () => import('./views/app/apps/minibank/registernasabah/newform-nasabah.vue'),
        },
        // End of Mini Bank System Route

        // Start of TabunganKu Route
        {
          path: '/tabungannku/report/akuntansi/jurnalumum',
          meta: {
            title: 'Jurnal Umum Akuntansi',
            // permissions: true,
          },
          component: () => import('./views/app/apps/tabunganku/akuntansi/report/jurnalumum.vue'),
        },
        {
          path: '/tabungannku/report/akuntansi/neraca',
          meta: {
            title: 'Neraca Akuntansi',
            // permissions: true,
          },
          component: () => import('./views/app/apps/tabunganku/akuntansi/report/aggrid-neraca.vue'),
        },
        {
          path: '/tabungannku/report/deposito/saldo',
          meta: {
            title: 'Saldo Deposito',
            // permissions: true,
          },
          component: () => import('./views/app/apps/tabunganku/deposito/report/saldodeposito.vue'),
        },
        {
          path: '/tabungannku/report/deposito/saldo',
          meta: {
            title: 'Saldo Deposito',
            // permissions: true,
          },
          component: () => import('./views/app/apps/tabunganku/deposito/report/saldodeposito.vue'),
        },
        {
          path: '/tabungannku/report/kredit/saldo',
          meta: {
            title: 'Saldo Kredit',
            // permissions: true,
          },
          component: () => import('./views/app/apps/tabunganku/realisasikredit/report/saldokredit.vue'),
        },
        {
          path: '/tabungannku/report/tabungan/saldo',
          meta: {
            title: 'Saldo Tabungan',
            // permissions: true,
          },
          component: () => import('./views/app/apps/tabunganku/tabungan/report/saldotabungan.vue'),
        },
        {
          path: '/tabungannku/master/kodetransaksi',
          meta: {
            title: 'Master Kode Transaksi',
            permissions: true,
          },
          component: () => import('./views/app/apps/tabunganku/master/kodetransaksi.vue'),
        },
        {
          path: '/tabungannku/master/instansi',
          meta: {
            title: 'Master Instansi',
            permissions: true,
          },
          component: () => import('./views/app/apps/tabunganku/master/instansi/index.vue'),
        },
        {
          path: '/tabungannku/master/newakutansi',
          meta: {
            title: 'Master Akutansi',
            permissions: true,
          },
          component: () => import('./views/app/apps/tabunganku/master/newakuntansi.vue'),
        },
        {
          path: '/tabungannku/master/akutansi',
          meta: {
            title: 'Master Akutansi',
            permissions: true,
          },
          component: () => import('./views/app/apps/tabunganku/master/akutansi.vue'),
        },
        {
          path: '/tabungannku/master/kasteller',
          meta: {
            title: 'Master Kas Teller',
            // permissions: true,
          },
          component: () => import('./views/app/apps/tabunganku/master/kasteller.vue'),
        },
        {
          path: '/tabungannku/master/konfigurasi',
          meta: {
            title: 'konfigurasi',
            // permissions: true,
          },
          component: () => import('./views/app/apps/tabunganku/master/konfigurasi.vue'),
        },
        {
          path: '/tabungannku/master/ao',
          meta: {
            title: 'Master AO',
            permissions: true,
          },
          component: () => import('./views/app/apps/tabunganku/master/ao.vue'),
        },
        {
          path: '/tabungannku/master/golongan/kredit',
          meta: {
            title: 'Master Golongan Kredit',
            permissions: true,
          },
          component: () => import('./views/app/apps/tabunganku/master/golongankredit.vue'),
        },
        {
          path: '/tabungannku/master/golongan/tabungan',
          meta: {
            title: 'Master Golongan Tabungan',
            permissions: true,
          },
          component: () => import('./views/app/apps/tabunganku/master/golongantabungan.vue'),
        },
        {
          path: '/tabungannku/master/golongan/deposito',
          meta: {
            title: 'Master Golongan Deposito',
            permissions: true,
          },
          component: () => import('./views/app/apps/tabunganku/master/golongandeposito.vue'),
        },
        {
          path: '/tabungannku/setoransimpanan',
          meta: {
            title: 'Setoran Simpanan',
            // permissions: true,
          },
          component: () => import('./views/app/apps/tabunganku/deposito/setoransimpanan.vue'),
        },
        {
          path: '/tabungannku/deposito/setorandeposito',
          meta: {
            title: 'Setoran Deposito',
            // permissions: true,
          },
          component: () => import('./views/app/apps/tabunganku/deposito/setorandeposito.vue'),
        },
        {
          path: '/tabungannku/akuntansi/jurnal',
          meta: {
            title: 'Jurnal Akuntansi',
            // permissions: true,
          },
          component: () => import('./views/app/apps/tabunganku/akuntansi/jurnal.vue'),
        },
        {
          path: '/tabungannku/kas/pengeluarankas',
          meta: {
            title: 'Pengeluaran Kas',
            // permissions: true,
          },
          component: () => import('./views/app/apps/tabunganku/kas/pengeluarankas.vue'),
        },
        {
          path: '/tabungannku/kas/penerimaankas',
          meta: {
            title: 'Penerimaan Kas',
            // permissions: true,
          },
          component: () => import('./views/app/apps/tabunganku/kas/penerimaankas.vue'),
        },
        {
          path: '/tabungannku/kas/data',
          meta: {
            title: 'Kas',
            // permissions: true,
          },
          component: () => import('./views/app/apps/tabunganku/kas/index.vue'),
        },
        {
          path: '/tabungannku/deposito/pembatalanpencairandeposito',
          meta: {
            title: 'Pembatalan Pencairan Deposito',
            // permissions: true,
          },
          component: () => import('./views/app/apps/tabunganku/deposito/pembatalanpencairandeposito.vue'),
        },
        {
          path: '/tabungannku/deposito/mutasideposito',
          meta: {
            title: 'Mutasi Deposito',
            // permissions: true,
          },
          component: () => import('./views/app/apps/tabunganku/deposito/mutasideposito.vue'),
        },
        {
          path: '/tabungannku/deposito/cetakbilyetdeposito',
          meta: {
            title: 'Cetak Bilyet Deposito',
            // permissions: true,
          },
          component: () => import('./views/app/apps/tabunganku/deposito/cetakbilyetdeposito.vue'),
        },
        {
          path: '/tabungannku/deposito/form',
          meta: {
            title: 'Deposito Form',
            // permissions: true,
          },
          component: () => import('./views/app/apps/tabunganku/deposito/form.vue'),
        },
        {
          path: '/tabungannku/deposito/pembukaan',
          meta: {
            title: 'Deposito Form',
            // permissions: true,
          },
          component: () => import('./views/app/apps/tabunganku/deposito/pembukaan.vue'),
        },
        {
          path: '/tabungannku/deposito/data',
          meta: {
            title: 'Deposito',
            // permissions: true,
          },
          component: () => import('./views/app/apps/tabunganku/deposito/index.vue'),
        },
        {
          path: '/tabungannku/kredit/kartuangsuran',
          meta: {
            title: 'Kartu Angsuran',
            // permissions: true,
          },
          component: () => import('./views/app/apps/tabunganku/realisasikredit/kartuangsuran.vue'),
        },
        {
          path: '/tabungannku/kredit/hapusangsuran',
          meta: {
            title: 'Hapus Angsuran',
            // permissions: true,
          },
          component: () => import('./views/app/apps/tabunganku/realisasikredit/hapusangsuran.vue'),
        },
        {
          path: '/tabungannku/kredit/angsuran',
          meta: {
            title: 'Angsuran',
            // permissions: true,
          },
          component: () => import('./views/app/apps/tabunganku/realisasikredit/angsuran.vue'),
        },
        {
          path: '/tabungannku/kredit/batal_pencairan',
          meta: {
            title: 'Batal Pencairan',
            // permissions: true,
          },
          component: () => import('./views/app/apps/tabunganku/realisasikredit/batal_pencairan.vue'),
        },
        {
          path: '/tabungannku/kredit/pencairan',
          meta: {
            title: 'Pencairan',
            // permissions: true,
          },
          component: () => import('./views/app/apps/tabunganku/realisasikredit/pencairan.vue'),
        },
        {
          path: '/tabungannku/realisasikredit/data',
          meta: {
            title: 'Realisasi Kredit',
            // permissions: true,
          },
          component: () => import('./views/app/apps/tabunganku/realisasikredit/index.vue'),
        },
        {
          path: '/tabungannku/realisasikredit/form',
          meta: {
            title: 'Realisasi Kredit / Form',
            // permissions: true,
          },
          component: () => import('./views/app/apps/tabunganku/realisasikredit/form.vue'),
        },
        {
          path: '/tabungannku/tabungan/data',
          meta: {
            title: 'Tabungan',
            permissions: true,
          },
          component: () => import('./views/app/apps/tabunganku/tabungan/index.vue'),
        },
        {
          path: '/tabungannku/tabungan/form',
          meta: {
            title: 'Tabungan',
            // permissions: true,
          },
          component: () => import('./views/app/apps/tabunganku/tabungan/newform.vue'),
        },
        {
          path: '/tabungannku/mutasitabungan/form',
          meta: {
            title: 'Mutasi Tabungan',
            permissions: true,
          },
          component: () => import('./views/app/apps/tabunganku/mutasitabungan/index.vue'),
        },
        {
          path: '/tabungannku/mutasitabungan/laporan',
          meta: {
            title: 'Laporan Transaksi',
            permissions: true,
          },
          component: () => import('./views/app/apps/tabunganku/mutasitabungan/laporan.vue'),
        },
        {
          path: '/tabungannku/mutasitabungan/laporan',
          meta: {
            title: 'Laporan Mutasi harian',
            permissions: true,
          },
          component: () => import('./views/app/apps/tabunganku/mutasitabungan/laporanmutasiharian.vue'),
        },
        {
          path: '/tabungannku/mutasitabungan/bukutabungan',
          meta: {
            title: 'Laporan Buku Tabungan',
            // permissions: true,
          },
          component: () => import('./views/app/apps/tabunganku/mutasitabungan/reprintbukutabungan.vue'),
        },
        {
          path: '/tabungannku/registernasabah/data',
          meta: {
            title: 'Register',
            // permissions: true,
          },
          component: () => import('./views/app/apps/tabunganku/registernasabah/index.vue'),
        },
        {
          path: '/tabungannku/registernasabah/formnonanggota',
          name: 'nasabaheditnonanggota',
          meta: {
            title: 'Register Non Anggota',
            // permissions: true,
          },
          component: () => import('./views/app/apps/tabunganku/registernasabah/newform.vue'),
        },
        {
          path: '/tabungannku/registernasabah/formanggota',
          name: 'nasabaheditanggota',
          meta: {
            title: 'Register Anggota',
            // permissions: true,
          },
          component: () => import('./views/app/apps/tabunganku/registernasabah/newform-anggota.vue'),
        },
        {
          path: '/tabungannku/registernasabah/cari',
          meta: {
            title: 'Cari Data Register',
            // permissions: true,
          },
          component: () => import('./views/app/apps/tabunganku/registernasabah/cariregisternasabah.vue'),
        },
        // End of TabunganKu Route

        // Start of Koperasi Route
        {
          path: '/koperasi/report/akuntansi/jurnalumum',
          meta: {
            title: 'Jurnal Umum Akuntansi',
            // permissions: true,
          },
          component: () => import('./views/app/apps/newkoperasi/akuntansi/report/jurnalumum.vue'),
        },
        {
          path: '/koperasi/report/akuntansi/neraca',
          meta: {
            title: 'Neraca Akuntansi',
            // permissions: true,
          },
          component: () => import('./views/app/apps/newkoperasi/akuntansi/report/aggrid-neraca.vue'),
        },
        {
          path: '/koperasi/report/deposito/saldo',
          meta: {
            title: 'Saldo Deposito',
            // permissions: true,
          },
          component: () => import('./views/app/apps/newkoperasi/deposito/report/saldodeposito.vue'),
        },
        {
          path: '/koperasi/report/deposito/saldo',
          meta: {
            title: 'Saldo Deposito',
            // permissions: true,
          },
          component: () => import('./views/app/apps/newkoperasi/deposito/report/saldodeposito.vue'),
        },
        {
          path: '/koperasi/report/kredit/saldo',
          meta: {
            title: 'Saldo Kredit',
            // permissions: true,
          },
          component: () => import('./views/app/apps/newkoperasi/realisasikredit/report/saldokredit.vue'),
        },
        {
          path: '/koperasi/report/tabungan/saldo',
          meta: {
            title: 'Saldo Tabungan',
            // permissions: true,
          },
          component: () => import('./views/app/apps/newkoperasi/tabungan/report/saldotabungan.vue'),
        },
        {
          path: '/koperasi/master/kodetransaksi',
          meta: {
            title: 'Koperasi Master Kode Transaksi',
            permissions: true,
          },
          component: () => import('./views/app/apps/newkoperasi/master/kodetransaksi.vue'),
        },
        {
          path: '/koperasi/master/instansi',
          meta: {
            title: 'Koperasi Master Instansi',
            permissions: true,
          },
          component: () => import('./views/app/apps/newkoperasi/master/instansi/index.vue'),
        },
        {
          path: '/koperasi/master/newakutansi',
          meta: {
            title: 'Koperasi Master Akutansi',
            permissions: true,
          },
          component: () => import('./views/app/apps/newkoperasi/master/newakuntansi.vue'),
        },
        {
          path: '/koperasi/master/akutansi',
          meta: {
            title: 'Koperasi Master Akutansi',
            permissions: true,
          },
          component: () => import('./views/app/apps/newkoperasi/master/akutansi.vue'),
        },
        {
          path: '/koperasi/master/kasteller',
          meta: {
            title: 'Koperasi Master Kas Teller',
            // permissions: true,
          },
          component: () => import('./views/app/apps/newkoperasi/master/kasteller.vue'),
        },
        {
          path: '/koperasi/master/konfigurasi',
          meta: {
            title: 'Koperasi konfigurasi',
            // permissions: true,
          },
          component: () => import('./views/app/apps/newkoperasi/master/konfigurasi.vue'),
        },
        {
          path: '/koperasi/master/ao',
          meta: {
            title: 'Koperasi Master AO',
            permissions: true,
          },
          component: () => import('./views/app/apps/newkoperasi/master/ao.vue'),
        },
        {
          path: '/koperasi/master/golongan/kredit',
          meta: {
            title: 'Koperasi Master Golongan Kredit',
            permissions: true,
          },
          component: () => import('./views/app/apps/newkoperasi/master/golongankredit.vue'),
        },
        {
          path: '/koperasi/master/golongan/tabungan',
          meta: {
            title: 'Koperasi Master Golongan Tabungan',
            permissions: true,
          },
          component: () => import('./views/app/apps/newkoperasi/master/golongantabungan.vue'),
        },
        {
          path: '/koperasi/master/golongan/deposito',
          meta: {
            title: 'Koperasi Master Golongan Deposito',
            permissions: true,
          },
          component: () => import('./views/app/apps/newkoperasi/master/golongandeposito.vue'),
        },
        {
          path: '/koperasi/setoransimpanan',
          meta: {
            title: 'Setoran Simpanan',
            // permissions: true,
          },
          component: () => import('./views/app/apps/newkoperasi/deposito/setoransimpanan.vue'),
        },
        {
          path: '/koperasi/deposito/setorandeposito',
          meta: {
            title: 'Setoran Deposito',
            // permissions: true,
          },
          component: () => import('./views/app/apps/newkoperasi/deposito/setorandeposito.vue'),
        },
        {
          path: '/koperasi/akuntansi/jurnal',
          meta: {
            title: 'Jurnal Akuntansi',
            // permissions: true,
          },
          component: () => import('./views/app/apps/newkoperasi/akuntansi/jurnal.vue'),
        },
        {
          path: '/koperasi/kas/pengeluarankas',
          meta: {
            title: 'Pengeluaran Kas',
            // permissions: true,
          },
          component: () => import('./views/app/apps/newkoperasi/kas/pengeluarankas.vue'),
        },
        {
          path: '/koperasi/kas/penerimaankas',
          meta: {
            title: 'Penerimaan Kas',
            // permissions: true,
          },
          component: () => import('./views/app/apps/newkoperasi/kas/penerimaankas.vue'),
        },
        {
          path: '/koperasi/kas/data',
          meta: {
            title: 'Kas',
            // permissions: true,
          },
          component: () => import('./views/app/apps/newkoperasi/kas/index.vue'),
        },
        {
          path: '/koperasi/deposito/pembatalanpencairandeposito',
          meta: {
            title: 'Pembatalan Pencairan Deposito',
            // permissions: true,
          },
          component: () => import('./views/app/apps/newkoperasi/deposito/pembatalanpencairandeposito.vue'),
        },
        {
          path: '/koperasi/deposito/mutasideposito',
          meta: {
            title: 'Mutasi Deposito',
            // permissions: true,
          },
          component: () => import('./views/app/apps/newkoperasi/deposito/mutasideposito.vue'),
        },
        {
          path: '/koperasi/deposito/cetakbilyetdeposito',
          meta: {
            title: 'Cetak Bilyet Deposito',
            // permissions: true,
          },
          component: () => import('./views/app/apps/newkoperasi/deposito/cetakbilyetdeposito.vue'),
        },
        {
          path: '/koperasi/deposito/form',
          meta: {
            title: 'Deposito Form',
            // permissions: true,
          },
          component: () => import('./views/app/apps/newkoperasi/deposito/form.vue'),
        },
        {
          path: '/koperasi/deposito/pembukaan',
          meta: {
            title: 'Deposito Form',
            // permissions: true,
          },
          component: () => import('./views/app/apps/newkoperasi/deposito/pembukaan.vue'),
        },
        {
          path: '/koperasi/deposito/data',
          meta: {
            title: 'Deposito',
            // permissions: true,
          },
          component: () => import('./views/app/apps/newkoperasi/deposito/index.vue'),
        },
        {
          path: '/koperasi/kredit/kartuangsuran',
          meta: {
            title: 'Kartu Angsuran',
            // permissions: true,
          },
          component: () => import('./views/app/apps/newkoperasi/realisasikredit/kartuangsuran.vue'),
        },
        {
          path: '/koperasi/kredit/hapusangsuran',
          meta: {
            title: 'Hapus Angsuran',
            // permissions: true,
          },
          component: () => import('./views/app/apps/newkoperasi/realisasikredit/hapusangsuran.vue'),
        },
        {
          path: '/koperasi/kredit/angsuran',
          meta: {
            title: 'Angsuran',
            // permissions: true,
          },
          component: () => import('./views/app/apps/newkoperasi/realisasikredit/angsuran.vue'),
        },
        {
          path: '/koperasi/kredit/batal_pencairan',
          meta: {
            title: 'Batal Pencairan',
            // permissions: true,
          },
          component: () => import('./views/app/apps/newkoperasi/realisasikredit/batal_pencairan.vue'),
        },
        {
          path: '/koperasi/kredit/pencairan',
          meta: {
            title: 'Pencairan',
            // permissions: true,
          },
          component: () => import('./views/app/apps/newkoperasi/realisasikredit/pencairan.vue'),
        },
        {
          path: '/koperasi/realisasikredit/data',
          meta: {
            title: 'Koperasi Realisasi Kredit',
            // permissions: true,
          },
          component: () => import('./views/app/apps/newkoperasi/realisasikredit/index.vue'),
        },
        {
          path: '/koperasi/realisasikredit/form',
          meta: {
            title: 'Koperasi Realisasi Kredit / Form',
            // permissions: true,
          },
          component: () => import('./views/app/apps/newkoperasi/realisasikredit/form.vue'),
        },
        {
          path: '/koperasi/tabungan/data',
          meta: {
            title: 'Koperasi Tabungan',
            permissions: true,
          },
          component: () => import('./views/app/apps/newkoperasi/tabungan/index.vue'),
        },
        {
          path: '/koperasi/tabungan/form',
          meta: {
            title: 'Koperasi Tabungan',
            // permissions: true,
          },
          component: () => import('./views/app/apps/newkoperasi/tabungan/newform.vue'),
        },
        {
          path: '/koperasi/mutasitabungan/form',
          meta: {
            title: 'Koperasi Mutasi Tabungan',
            permissions: true,
          },
          component: () => import('./views/app/apps/newkoperasi/mutasitabungan/index.vue'),
        },
        {
          path: '/koperasi/mutasitabungan/laporan',
          meta: {
            title: 'Koperasi Laporan Transaksi',
            permissions: true,
          },
          component: () => import('./views/app/apps/newkoperasi/mutasitabungan/laporan.vue'),
        },
        {
          path: '/koperasi/mutasitabungan/laporan',
          meta: {
            title: 'Koperasi Laporan Mutasi harian',
            permissions: true,
          },
          component: () => import('./views/app/apps/newkoperasi/mutasitabungan/laporanmutasiharian.vue'),
        },
        {
          path: '/koperasi/mutasitabungan/bukutabungan',
          meta: {
            title: 'Koperasi Laporan Buku Tabungan',
            // permissions: true,
          },
          component: () => import('./views/app/apps/newkoperasi/mutasitabungan/reprintbukutabungan.vue'),
        },
        {
          path: '/koperasi/registernasabah/data',
          meta: {
            title: 'Koperasi Register',
            // permissions: true,
          },
          component: () => import('./views/app/apps/newkoperasi/registernasabah/index.vue'),
        },
        {
          path: '/koperasi/registernasabah/formnonanggota',
          name: 'koperasinasabaheditnonanggota',
          meta: {
            title: 'Koperasi Register Non Anggota',
            // permissions: true,
          },
          component: () => import('./views/app/apps/newkoperasi/registernasabah/newform.vue'),
        },
        {
          path: '/koperasi/registernasabah/formanggota',
          name: 'koperasinasabaheditanggota',
          meta: {
            title: 'Koperasi Register Anggota',
            // permissions: true,
          },
          component: () => import('./views/app/apps/newkoperasi/registernasabah/newform-anggota.vue'),
        },
        {
          path: '/koperasi/registernasabah/cari',
          meta: {
            title: 'Koperasi Cari Data Register',
            // permissions: true,
          },
          component: () => import('./views/app/apps/newkoperasi/registernasabah/cariregisternasabah.vue'),
        },
        // End Of Koperasi Route

        // Start of Project A Route
        {
          path: '/projecta/customercare/complain',
          meta: {
            title: 'Complain',
            // permissions: true,
          },
          component: () => import('./views/app/apps/projecta/customercare/complain/index.vue'),
        },
        {
          path: '/projecta/utility/otorisasi',
          meta: {
            title: 'Otorisasi',
            // permissions: true,
          },
          component: () => import('./views/app/apps/projecta/utility/otorisasi.vue'),
        },
        {
          path: '/projecta/utility/otorisasiv2',
          meta: {
            title: 'Otorisasi Mode',
            // permissions: true,
          },
          component: () => import('./views/app/apps/projecta/utility/otorisasiv2.vue'),
        },
        {
          path: '/projecta/utility/posting/harian',
          meta: {
            title: 'Posting Harian',
            // permissions: true,
          },
          component: () => import('./views/app/apps/projecta/utility/postingharian.vue'),
        },
        {
          path: '/projecta/utility/posting/akhirtahun',
          meta: {
            title: 'Posting Akhir Tahun',
            // permissions: true,
          },
          component: () => import('./views/app/apps/projecta/utility/postingakhirtahun.vue'),
        },
        {
          path: '/projecta/utility/posting/amortisasi',
          meta: {
            title: 'Posting Amortisasi',
            // permissions: true,
          },
          component: () => import('./views/app/apps/projecta/utility/postingamortisasi.vue'),
        },
        {
          path: '/projecta/report/akuntansi/jurnalumum',
          meta: {
            title: 'Jurnal Umum Akuntansi',
            // permissions: true,
          },
          component: () => import('./views/app/apps/projecta/akuntansi/report/jurnalumum.vue'),
        },
        {
          path: '/projecta/report/akuntansi/neraca',
          meta: {
            title: 'Neraca Akuntansi',
            // permissions: true,
          },
          component: () => import('./views/app/apps/projecta/akuntansi/report/aggrid-neraca.vue'),
        },
        {
          path: '/projecta/report/akuntansi/labarugi',
          meta: {
            title: 'Laba Rugi Akuntansi',
            // permissions: true,
          },
          component: () => import('./views/app/apps/projecta/akuntansi/report/aggrid-labarugi.vue'),
        },
        {
          path: '/projecta/report/akuntansi/bukubesar',
          meta: {
            title: 'Buku Besar Akuntansi',
            // permissions: true,
          },
          component: () => import('./views/app/apps/projecta/akuntansi/report/bukubesar.vue'),
        },
        {
          path: '/projecta/report/akuntansi/inventaris',
          meta: {
            title: 'Inventaris Akuntansi',
            // permissions: true,
          },
          component: () => import('./views/app/apps/projecta/akuntansi/report/inventaris.vue'),
        },
        {
          path: '/projecta/report/akuntansi/amortisasi',
          meta: {
            title: 'Amortisasi Akuntansi',
            // permissions: true,
          },
          component: () => import('./views/app/apps/projecta/akuntansi/report/amortisasi.vue'),
        },
        {
          path: '/projecta/report/penjualan/keseluruhan',
          meta: {
            title: 'Keseluruhan Penjualan',
            // permissions: true,
          },
          component: () => import('./views/app/apps/projecta/penjualan/report/index.vue'),
        },
        {
          path: '/projecta/report/penjualan/piutang',
          meta: {
            title: 'Piutang Penjualan',
            // permissions: true,
          },
          component: () => import('./views/app/apps/projecta/penjualan/report/piutang.vue'),
        },
        {
          path: '/projecta/report/pembelian/keseluruhan',
          meta: {
            title: 'Keseluruhan Pembelian',
            // permissions: true,
          },
          component: () => import('./views/app/apps/projecta/pembelian/report/index.vue'),
        },
        {
          path: '/projecta/report/penjualan/peremployee',
          meta: {
            title: 'Pembelian Per Sales',
            // permissions: true,
          },
          component: () => import('./views/app/apps/projecta/penjualan/report/peremployee.vue'),
        },
        {
          path: '/projecta/report/pembelian/persupplier',
          meta: {
            title: 'Pembelian Per Supplier',
            // permissions: true,
          },
          component: () => import('./views/app/apps/projecta/pembelian/report/persupplier.vue'),
        },
        {
          path: '/projecta/report/penjualan/stock',
          meta: {
            title: 'Stock Penjualan',
            // permissions: true,
          },
          component: () => import('./views/app/apps/projecta/penjualan/report/stock.vue'),
        },
        {
          path: '/projecta/report/penjualan/kartustock',
          meta: {
            title: 'Kartu Stock Penjualan',
            // permissions: true,
          },
          component: () => import('./views/app/apps/projecta/penjualan/report/kartustock.vue'),
        },
        {
          path: '/projecta/report/pembelian/stock',
          meta: {
            title: 'Stock Pembelian',
            // permissions: true,
          },
          component: () => import('./views/app/apps/projecta/pembelian/report/stock.vue'),
        },
        {
          path: '/projecta/report/pembelian/kartustock',
          meta: {
            title: 'Kartu Stock Pembelian',
            // permissions: true,
          },
          component: () => import('./views/app/apps/projecta/pembelian/report/kartustock.vue'),
        },
        {
          path: '/projecta/report/deposito/saldo',
          meta: {
            title: 'Saldo Deposito',
            // permissions: true,
          },
          component: () => import('./views/app/apps/projecta/deposito/report/saldodeposito.vue'),
        },
        {
          path: '/projecta/report/deposito/saldo',
          meta: {
            title: 'Saldo Deposito',
            // permissions: true,
          },
          component: () => import('./views/app/apps/projecta/deposito/report/saldodeposito.vue'),
        },
        {
          path: '/projecta/report/kredit/saldo',
          meta: {
            title: 'Saldo Kredit',
            // permissions: true,
          },
          component: () => import('./views/app/apps/projecta/realisasikredit/report/saldokredit.vue'),
        },
        {
          path: '/projecta/report/tabungan/saldo',
          meta: {
            title: 'Saldo Tabungan',
            // permissions: true,
          },
          component: () => import('./views/app/apps/projecta/tabungan/report/saldotabungan.vue'),
        },
        {
          path: '/projecta/master/kodetransaksi',
          meta: {
            title: 'Project A Master Kode Transaksi',
            permissions: true,
          },
          component: () => import('./views/app/apps/projecta/master/kodetransaksi.vue'),
        },
        {
          path: '/projecta/master/instansi',
          meta: {
            title: 'Project A Master Instansi',
            permissions: true,
          },
          component: () => import('./views/app/apps/projecta/master/instansi/index.vue'),
        },
        {
          path: '/projecta/master/newakutansi',
          meta: {
            title: 'Project A Master Akutansi',
            permissions: true,
          },
          component: () => import('./views/app/apps/projecta/master/newakuntansi.vue'),
        },
        {
          path: '/projecta/master/akutansi',
          meta: {
            title: 'Project A Master Akutansi',
            permissions: true,
          },
          component: () => import('./views/app/apps/projecta/master/akutansi.vue'),
        },
        {
          path: '/projecta/master/bahanbaku',
          meta: {
            title: 'Project A Master Bahan Baku',
            permissions: true,
          },
          component: () => import('./views/app/apps/projecta/master/bahanbaku.vue'),
        },
        {
          path: '/projecta/master/kategoribahan',
          meta: {
            title: 'Project A Master Kategori Bahan',
            permissions: true,
          },
          component: () => import('./views/app/apps/projecta/master/kategoribahan.vue'),
        },
        {
          path: '/projecta/master/supplier',
          meta: {
            title: 'Project A Master Supplier',
            permissions: true,
          },
          component: () => import('./views/app/apps/projecta/master/supplier.vue'),
        },
        {
          path: '/projecta/master/customer',
          meta: {
            title: 'Project A Master Customer',
            permissions: true,
          },
          component: () => import('./views/app/apps/projecta/master/customer.vue'),
        },
        {
          path: '/projecta/master/kategoricustomer',
          meta: {
            title: 'Project A Master Kategori Customer',
            permissions: true,
          },
          component: () => import('./views/app/apps/projecta/master/kategoricustomer.vue'),
        },
        {
          path: '/projecta/master/barangjadi',
          meta: {
            title: 'Project A Master Barang Jadi',
            permissions: true,
          },
          component: () => import('./views/app/apps/projecta/master/barangjadi.vue'),
        },
        {
          path: '/projecta/master/sales',
          meta: {
            title: 'Project A Master Sales',
            permissions: true,
          },
          component: () => import('./views/app/apps/projecta/master/sales.vue'),
        },
        {
          path: '/projecta/master/kasteller',
          meta: {
            title: 'Project A Master Kas Teller',
            // permissions: true,
          },
          component: () => import('./views/app/apps/projecta/master/kasteller.vue'),
        },
        {
          path: '/projecta/master/konfigurasi',
          meta: {
            title: 'Project A konfigurasi',
            // permissions: true,
          },
          component: () => import('./views/app/apps/projecta/master/konfigurasi.vue'),
        },
        {
          path: '/projecta/master/ao',
          meta: {
            title: 'Project A Master AO',
            permissions: true,
          },
          component: () => import('./views/app/apps/projecta/master/ao.vue'),
        },
        {
          path: '/projecta/master/golongan/kredit',
          meta: {
            title: 'Project A Master Golongan Kredit',
            permissions: true,
          },
          component: () => import('./views/app/apps/projecta/master/golongankredit.vue'),
        },
        {
          path: '/projecta/master/golongan/tabungan',
          meta: {
            title: 'Project A Master Golongan Tabungan',
            permissions: true,
          },
          component: () => import('./views/app/apps/projecta/master/golongantabungan.vue'),
        },
        {
          path: '/projecta/master/golongan/deposito',
          meta: {
            title: 'Project A Master Golongan Deposito',
            permissions: true,
          },
          component: () => import('./views/app/apps/projecta/master/golongandeposito.vue'),
        },
        {
          path: '/projecta/setoransimpanan',
          meta: {
            title: 'Setoran Simpanan',
            // permissions: true,
          },
          component: () => import('./views/app/apps/projecta/deposito/setoransimpanan.vue'),
        },
        {
          path: '/projecta/deposito/setorandeposito',
          meta: {
            title: 'Setoran Deposito',
            // permissions: true,
          },
          component: () => import('./views/app/apps/projecta/deposito/setorandeposito.vue'),
        },
        {
          path: '/projecta/akuntansi/jurnal',
          meta: {
            title: 'Jurnal Akuntansi',
            // permissions: true,
          },
          component: () => import('./views/app/apps/projecta/akuntansi/jurnal.vue'),
        },
        {
          path: '/projecta/transaksi/penyesuaianbahanbaku',
          meta: {
            title: 'Penyesuaian Bahan Baku',
            // permissions: true,
          },
          component: () => import('./views/app/apps/projecta/transaksi/penyesuaianbahanbaku/index.vue'),
        },
        {
          path: '/projecta/transaksi/penyesuaianbarangjadi',
          meta: {
            title: 'Penyesuaian Barang Jadi',
            // permissions: true,
          },
          component: () => import('./views/app/apps/projecta/transaksi/penyesuaianbarangjadi/index.vue'),
        },
        {
          path: '/projecta/transaksi/meterair',
          meta: {
            title: 'Meter Air',
            // permissions: true,
          },
          component: () => import('./views/app/apps/projecta/transaksi/meterair/index.vue'),
        },
        {
          path: '/projecta/transaksi/labtest',
          meta: {
            title: 'Lab Test',
            // permissions: true,
          },
          component: () => import('./views/app/apps/projecta/transaksi/labtest/parent.vue'),
        },
        {
          path: '/projecta/transaksi/inventaris',
          meta: {
            title: 'Inventaris',
            // permissions: true,
          },
          component: () => import('./views/app/apps/projecta/transaksi/inventaris/parent.vue'),
        },
        {
          path: '/projecta/transaksi/biayaamortisasi',
          meta: {
            title: 'Amortisasi Biaya',
            // permissions: true,
          },
          component: () => import('./views/app/apps/projecta/transaksi/biayaamortisasi/parent.vue'),
        },
        {
          path: '/projecta/transaksi/orderpenjualan',
          meta: {
            title: 'Order Penjualan',
            // permissions: true,
          },
          component: () => import('./views/app/apps/projecta/transaksi/orderpenjualan/parent.vue'),
        },
        {
          path: '/projecta/transaksi/orderpenjualan/form',
          meta: {
            title: 'Form Order Penjualan',
            // permissions: true,
          },
          component: () => import('./views/app/apps/projecta/transaksi/orderpenjualan/form.vue'),
        },
        {
          path: '/projecta/transaksi/orderpembelian',
          meta: {
            title: 'Order Pembelian',
            // permissions: true,
          },
          component: () => import('./views/app/apps/projecta/transaksi/orderpembelian/parent.vue'),
        },
        {
          path: '/projecta/transaksi/orderpembelian/form',
          meta: {
            title: 'Form Order Pembelian',
            // permissions: true,
          },
          component: () => import('./views/app/apps/projecta/transaksi/orderpembelian/form.vue'),
        },
        {
          path: '/projecta/transaksi/purchasepayment',
          meta: {
            title: 'Pembayaran Pembelian',
            // permissions: true,
          },
          component: () => import('./views/app/apps/projecta/transaksi/purchasepayment/parent.vue'),
        },
        {
          path: '/projecta/transaksi/penerimaanbahanbaku',
          meta: {
            title: 'Penerimaan Bahan Baku',
            // permissions: true,
          },
          component: () => import('./views/app/apps/projecta/transaksi/penerimaanbahanbaku/parent.vue'),
        },
        {
          path: '/projecta/transaksi/permintaanmaterial',
          meta: {
            title: 'Permintaan Material',
            // permissions: true,
          },
          component: () => import('./views/app/apps/projecta/transaksi/permintaanmaterial/parent.vue'),
        },
        {
          path: '/projecta/transaksi/finishgoods',
          meta: {
            title: 'Input Barang Jadi',
            // permissions: true,
          },
          component: () => import('./views/app/apps/projecta/transaksi/finishgoods/parent.vue'),
        },
        {
          path: '/projecta/transaksi/delivery',
          meta: {
            title: 'Pengiriman / Delivery',
            // permissions: true,
          },
          component: () => import('./views/app/apps/projecta/transaksi/delivery/parent.vue'),
        },
        {
          path: '/projecta/transaksi/salespayment',
          meta: {
            title: 'Pembayaran Penjualan',
            // permissions: true,
          },
          component: () => import('./views/app/apps/projecta/transaksi/salespayment/parent.vue'),
        },
        {
          path: '/projecta/transaksi/rilisinvoice',
          meta: {
            title: 'Rilis Invoice',
            // permissions: true,
          },
          component: () => import('./views/app/apps/projecta/transaksi/rilisinvoice/parent.vue'),
        },
        {
          path: '/projecta/setting/hak_otorisasi',
          meta: {
            title: 'Hak Otorisasi',
            // permissions: true,
          },
          component: () => import('./views/app/apps/projecta/settings/hak_otorisasi.vue'),
        },
        {
          path: '/projecta/setting/infoperusahaan',
          meta: {
            title: 'Informasi Perusahaan',
            // permissions: true,
          },
          component: () => import('./views/app/apps/projecta/settings/infoperusahaan.vue'),
        },
        {
          path: '/projecta/setting/templateword',
          meta: {
            title: 'Template Word',
            // permissions: true,
          },
          component: () => import('./views/app/apps/projecta/settings/templateword.vue'),
        },
        {
          path: '/projecta/setting/konfigurasiakun',
          meta: {
            title: 'Konfigurasi Akun',
            // permissions: true,
          },
          component: () => import('./views/app/apps/projecta/settings/konfigurasiakun.vue'),
        },
        {
          path: '/projecta/kas/pengeluarankas',
          meta: {
            title: 'Pengeluaran Kas',
            // permissions: true,
          },
          component: () => import('./views/app/apps/projecta/kas/pengeluarankas.vue'),
        },
        {
          path: '/projecta/kas/penerimaankas',
          meta: {
            title: 'Penerimaan Kas',
            // permissions: true,
          },
          component: () => import('./views/app/apps/projecta/kas/penerimaankas.vue'),
        },
        {
          path: '/projecta/kas/data',
          meta: {
            title: 'Kas',
            // permissions: true,
          },
          component: () => import('./views/app/apps/projecta/kas/index.vue'),
        },
        {
          path: '/projecta/deposito/pembatalanpencairandeposito',
          meta: {
            title: 'Pembatalan Pencairan Deposito',
            // permissions: true,
          },
          component: () => import('./views/app/apps/projecta/deposito/pembatalanpencairandeposito.vue'),
        },
        {
          path: '/projecta/deposito/mutasideposito',
          meta: {
            title: 'Mutasi Deposito',
            // permissions: true,
          },
          component: () => import('./views/app/apps/projecta/deposito/mutasideposito.vue'),
        },
        {
          path: '/projecta/deposito/cetakbilyetdeposito',
          meta: {
            title: 'Cetak Bilyet Deposito',
            // permissions: true,
          },
          component: () => import('./views/app/apps/projecta/deposito/cetakbilyetdeposito.vue'),
        },
        {
          path: '/projecta/deposito/form',
          meta: {
            title: 'Deposito Form',
            // permissions: true,
          },
          component: () => import('./views/app/apps/projecta/deposito/form.vue'),
        },
        {
          path: '/projecta/deposito/pembukaan',
          meta: {
            title: 'Deposito Form',
            // permissions: true,
          },
          component: () => import('./views/app/apps/projecta/deposito/pembukaan.vue'),
        },
        {
          path: '/projecta/deposito/data',
          meta: {
            title: 'Deposito',
            // permissions: true,
          },
          component: () => import('./views/app/apps/projecta/deposito/index.vue'),
        },
        {
          path: '/projecta/kredit/kartuangsuran',
          meta: {
            title: 'Kartu Angsuran',
            // permissions: true,
          },
          component: () => import('./views/app/apps/projecta/realisasikredit/kartuangsuran.vue'),
        },
        {
          path: '/projecta/kredit/hapusangsuran',
          meta: {
            title: 'Hapus Angsuran',
            // permissions: true,
          },
          component: () => import('./views/app/apps/projecta/realisasikredit/hapusangsuran.vue'),
        },
        {
          path: '/projecta/kredit/angsuran',
          meta: {
            title: 'Angsuran',
            // permissions: true,
          },
          component: () => import('./views/app/apps/projecta/realisasikredit/angsuran.vue'),
        },
        {
          path: '/projecta/kredit/batal_pencairan',
          meta: {
            title: 'Batal Pencairan',
            // permissions: true,
          },
          component: () => import('./views/app/apps/projecta/realisasikredit/batal_pencairan.vue'),
        },
        {
          path: '/projecta/kredit/pencairan',
          meta: {
            title: 'Pencairan',
            // permissions: true,
          },
          component: () => import('./views/app/apps/projecta/realisasikredit/pencairan.vue'),
        },
        {
          path: '/projecta/realisasikredit/data',
          meta: {
            title: 'Project A Realisasi Kredit',
            // permissions: true,
          },
          component: () => import('./views/app/apps/projecta/realisasikredit/index.vue'),
        },
        {
          path: '/projecta/realisasikredit/form',
          meta: {
            title: 'Project A Realisasi Kredit / Form',
            // permissions: true,
          },
          component: () => import('./views/app/apps/projecta/realisasikredit/form.vue'),
        },
        {
          path: '/projecta/tabungan/data',
          meta: {
            title: 'Project A Tabungan',
            permissions: true,
          },
          component: () => import('./views/app/apps/projecta/tabungan/index.vue'),
        },
        {
          path: '/projecta/tabungan/form',
          meta: {
            title: 'Project A Tabungan',
            // permissions: true,
          },
          component: () => import('./views/app/apps/projecta/tabungan/newform.vue'),
        },
        {
          path: '/projecta/mutasitabungan/form',
          meta: {
            title: 'Project A Mutasi Tabungan',
            permissions: true,
          },
          component: () => import('./views/app/apps/projecta/mutasitabungan/index.vue'),
        },
        {
          path: '/projecta/mutasitabungan/laporan',
          meta: {
            title: 'Project A Laporan Transaksi',
            permissions: true,
          },
          component: () => import('./views/app/apps/projecta/mutasitabungan/laporan.vue'),
        },
        {
          path: '/projecta/mutasitabungan/laporan',
          meta: {
            title: 'Project A Laporan Mutasi harian',
            permissions: true,
          },
          component: () => import('./views/app/apps/projecta/mutasitabungan/laporanmutasiharian.vue'),
        },
        {
          path: '/projecta/mutasitabungan/bukutabungan',
          meta: {
            title: 'Project A Laporan Buku Tabungan',
            // permissions: true,
          },
          component: () => import('./views/app/apps/projecta/mutasitabungan/reprintbukutabungan.vue'),
        },
        {
          path: '/projecta/registernasabah/data',
          meta: {
            title: 'Project A Register',
            // permissions: true,
          },
          component: () => import('./views/app/apps/projecta/registernasabah/index.vue'),
        },
        {
          path: '/projecta/registernasabah/formnonanggota',
          name: 'koperasinasabaheditnonanggota',
          meta: {
            title: 'Project A Register Non Anggota',
            // permissions: true,
          },
          component: () => import('./views/app/apps/projecta/registernasabah/newform.vue'),
        },
        {
          path: '/projecta/registernasabah/formanggota',
          name: 'koperasinasabaheditanggota',
          meta: {
            title: 'Project A Register Anggota',
            // permissions: true,
          },
          component: () => import('./views/app/apps/projecta/registernasabah/newform-anggota.vue'),
        },
        {
          path: '/projecta/registernasabah/cari',
          meta: {
            title: 'Project A Cari Data Register',
            // permissions: true,
          },
          component: () => import('./views/app/apps/projecta/registernasabah/cariregisternasabah.vue'),
        },
        {
          path: '/projecta/maklon/ordermanagement',
          meta: {
            title: 'Maklon | Management Order',
            permissions: true,
          },
          component: () => import('./views/app/apps/projecta/maklon/ordermanagement/parent.vue'),
        },
        {
          path: '/projecta/maklon/permintaanmaterial',
          meta: {
            title: 'Maklon | Material Order',
            permissions: true,
          },
          component: () => import('./views/app/apps/projecta/maklon/permintaanmaterial/parent.vue'),
        },
        {
          path: '/projecta/maklon/pengecekanlab',
          meta: {
            title: 'Maklon | Lab Test',
            permissions: true,
          },
          component: () => import('./views/app/apps/projecta/maklon/pengecekanlab/parent.vue'),
        },
        {
          path: '/projecta/maklon/inputbarangjadi',
          meta: {
            title: 'Maklon | Input Finished Goods',
            permissions: true,
          },
          component: () => import('./views/app/apps/projecta/maklon/inputbarangjadi/parent.vue'),
        },
        {
          path: '/projecta/maklon/pengiriman',
          meta: {
            title: 'Maklon | Delivery',
            permissions: true,
          },
          component: () => import('./views/app/apps/projecta/maklon/pengiriman/parent.vue'),
        },
        {
          path: '/projecta/cobranding/ordermanagement',
          meta: {
            title: 'Maklon | Management Order',
            permissions: true,
          },
          component: () => import('./views/app/apps/projecta/cobranding/ordermanagement/parent.vue'),
        },
        {
          path: '/projecta/cobranding/permintaanmaterial',
          meta: {
            title: 'Maklon | Material Order',
            permissions: true,
          },
          component: () => import('./views/app/apps/projecta/cobranding/permintaanmaterial/parent.vue'),
        },
        {
          path: '/projecta/cobranding/pengecekanlab',
          meta: {
            title: 'Maklon | Lab Test',
            permissions: true,
          },
          component: () => import('./views/app/apps/projecta/cobranding/pengecekanlab/parent.vue'),
        },
        {
          path: '/projecta/cobranding/inputbarangjadi',
          meta: {
            title: 'Maklon | Input Finished Goods',
            permissions: true,
          },
          component: () => import('./views/app/apps/projecta/cobranding/inputbarangjadi/parent.vue'),
        },
        {
          path: '/projecta/cobranding/pengiriman',
          meta: {
            title: 'Maklon | Delivery',
            permissions: true,
          },
          component: () => import('./views/app/apps/projecta/cobranding/pengiriman/parent.vue'),
        },
        {
          path: '/projecta/dashboard1',
          meta: {
            title: 'Dashboard test',
            // permissions: true,
          },
          component: () => import('./views/app/apps/projecta/dashboard.vue'),
        },
        // End Of Project A Route

        // Start of OBOX Route
        {
          path: '/obox/cr006a',
          meta: {
            title: 'Obox cr006a',
            permissions: true,
          },
          component: () => import('./views/app/apps/obox/lbbpr/cr006a.vue'),
        },
        {
          path: '/obox/cr007a',
          meta: {
            title: 'Obox cr007a',
            permissions: true,
          },
          component: () => import('./views/app/apps/obox/lbbpr/cr007a.vue'),
        },
        {
          path: '/obox/cr008a',
          meta: {
            title: 'Obox cr008a',
            // permissions: true,
          },
          component: () => import('./views/app/apps/obox/lbbpr/cr008a.vue'),
        },
        {
          path: '/obox/cr009a',
          meta: {
            title: 'Obox cr009a',
            permissions: true,
          },
          component: () => import('./views/app/apps/obox/lbbpr/cr009a.vue'),
        },
        {
          path: '/obox/cr010a',
          meta: {
            title: 'Obox cr010a',
            permissions: true,
          },
          component: () => import('./views/app/apps/obox/lbbprs/cr010a.vue'),
        },
        {
          path: '/obox/cr011a',
          meta: {
            title: 'Obox cr011a',
            permissions: true,
          },
          component: () => import('./views/app/apps/obox/lbbprs/cr011a.vue'),
        },
        {
          path: '/obox/cr012a',
          meta: {
            title: 'Obox cr012a',
            permissions: true,
          },
          component: () => import('./views/app/apps/obox/lbbprs/cr012a.vue'),
        },
        {
          path: '/obox/cr013a',
          meta: {
            title: 'Obox cr013a',
            permissions: true,
          },
          component: () => import('./views/app/apps/obox/lbbprs/cr013a.vue'),
        },
        {
          path: '/obox/lq003a',
          meta: {
            title: 'Obox lq003a',
            permissions: true,
          },
          component: () => import('./views/app/apps/obox/lbbpr/lq003a.vue'),
        },
        {
          path: '/obox/lq004a',
          meta: {
            title: 'Obox lq004a',
            permissions: true,
          },
          component: () => import('./views/app/apps/obox/lbbpr/lq004a.vue'),
        },
        {
          path: '/obox/lq005a',
          meta: {
            title: 'Obox lq005a',
            permissions: true,
          },
          component: () => import('./views/app/apps/obox/lbbpr/lq005a.vue'),
        },
        {
          path: '/obox/lq006a',
          meta: {
            title: 'Obox lq006a',
            permissions: true,
          },
          component: () => import('./views/app/apps/obox/lbbpr/lq006a.vue'),
        },
        {
          path: '/obox/lq007a',
          meta: {
            title: 'Obox lq007a',
            permissions: true,
          },
          component: () => import('./views/app/apps/obox/lbbprs/lq007a.vue'),
        },
        {
          path: '/obox/lq008a',
          meta: {
            title: 'Obox lq008a',
            permissions: true,
          },
          component: () => import('./views/app/apps/obox/lbbprs/lq008a.vue'),
        },
        {
          path: '/obox/lq009a',
          meta: {
            title: 'Obox lq009a',
            permissions: true,
          },
          component: () => import('./views/app/apps/obox/lbbprs/lq009a.vue'),
        },
        {
          path: '/obox/lq010a',
          meta: {
            title: 'Obox lq010a',
            permissions: true,
          },
          component: () => import('./views/app/apps/obox/lbbprs/lq010a.vue'),
        },
        {
          path: '/obox/op001a',
          meta: {
            title: 'Obox op001a',
            permissions: true,
          },
          component: () => import('./views/app/apps/obox/lbbpr/op001a.vue'),
        },
        {
          path: '/obox/op002a',
          meta: {
            title: 'Obox op002a',
            permissions: true,
          },
          component: () => import('./views/app/apps/obox/lbbpr/op002a.vue'),
        },
        {
          path: '/obox/op003a',
          meta: {
            title: 'Obox op003a',
            permissions: true,
          },
          component: () => import('./views/app/apps/obox/lbbpr/op003a.vue'),
        },
        {
          path: '/obox/op004a',
          meta: {
            title: 'Obox op004a',
            permissions: true,
          },
          component: () => import('./views/app/apps/obox/lbbprs/op004a.vue'),
        },
        {
          path: '/obox/op005a',
          meta: {
            title: 'Obox op005a',
            // permissions: true,
          },
          component: () => import('./views/app/apps/obox/lbbprs/op005a.vue'),
        },
        {
          path: '/obox/op006a',
          meta: {
            title: 'Obox op006a',
            permissions: true,
          },
          component: () => import('./views/app/apps/obox/lbbprs/op006a.vue'),
        },
        {
          path: '/obox/exportbpr',
          meta: {
            title: 'Obox BPR Export All',
            key: 'bpr',
            permissions: true,
          },
          component: () => import('./views/app/apps/obox/utility/export.vue'),
        },
        {
          path: '/obox/exportbprs',
          meta: {
            title: 'Obox BPR Export All',
            key: 'bprs',
            permissions: true,
          },
          component: () => import('./views/app/apps/obox/utility/export.vue'),
        },
        // End Of OBOX Route

        // Start Of Otorisasi Route
        {
          path: '/_otorisasi/permissions',
          meta: {
            title: 'Otorisasi > Permissions',
            // permissions: true,
          },
          component: () => import('./views/app/apps/_otorisasi/permissions.vue'),
        },
        {
          path: '/_otorisasi/permissiontype',
          meta: {
            title: 'Otorisasi > Permission Type',
            // permissions: true,
          },
          component: () => import('./views/app/apps/_otorisasi/permission_type.vue'),
        },
        {
          path: '/_otorisasi/requests',
          meta: {
            title: 'Otorisasi > Requests',
            // permissions: true,
          },
          component: () => import('./views/app/apps/_otorisasi/requests.vue'),
        },
        {
          path: '/_otorisasi/rolespermission',
          meta: {
            title: 'Otorisasi > Role Permission',
            // permissions: true,
          },
          component: () => import('./views/app/apps/_otorisasi/roles_permission.vue'),
        },
        {
          path: '/_otorisasi/roletype',
          meta: {
            title: 'Otorisasi > Role Type',
            // permissions: true,
          },
          component: () => import('./views/app/apps/_otorisasi/role_type.vue'),
        },
        {
          path: '/_otorisasi/roles',
          meta: {
            title: 'Otorisasi > Roles',
            // permissions: true,
          },
          component: () => import('./views/app/apps/_otorisasi/roles.vue'),
        },
        {
          path: '/_otorisasi/userroles',
          meta: {
            title: 'Otorisasi > User Roles',
            // permissions: true,
          },
          component: () => import('./views/app/apps/_otorisasi/user_role.vue'),
        },
        {
          path: '/_otorisasi/confirmaction',
          meta: {
            title: 'Otorisasi > Confirm Action',
            // permissions: true,
          },
          component: () => import('./views/app/apps/_otorisasi/cardconfirmation.vue'),
        },
        // End Of Otorisasi Route

        // Start Of Otorisasi Route
        {
          path: '/otorisasi/auth',
          meta: {
            title: 'Otorisasi Auth',
            permissions: true,
          },
          component: () => import('./views/app/apps/otorisasi/auth/index.vue'),
        },
        {
          path: '/otorisasi/messages',
          meta: {
            title: 'Otorisasi Messages',
            permissions: true,
          },
          component: () => import('./views/app/apps/otorisasi/messages/index.vue'),
        },
        {
          path: '/otorisasi/sequence',
          meta: {
            title: 'Otorisasi Sequence',
            permissions: true,
          },
          component: () => import('./views/app/apps/otorisasi/sequence/index.vue'),
        },
        {
          path: '/otorisasi/registration',
          meta: {
            title: 'Otorisasi Regristration',
            permissions: true,
          },
          component: () => import('./views/app/apps/otorisasi/regristration/index.vue'),
        },
        {
          path: '/otorisasi/permission',
          meta: {
            title: 'Otorisasi Permission',
            permissions: true,
          },
          component: () => import('./views/app/apps/otorisasi/permission/index.vue'),
        },
        {
          path: '/otorisasi/role',
          meta: {
            title: 'Otorisasi Role',
            permissions: true,
          },
          component: () => import('./views/app/apps/otorisasi/role/index.vue'),
        },
        {
          path: '/otorisasi/user',
          meta: {
            title: 'Otorisasi User',
            permissions: true,
          },
          component: () => import('./views/app/apps/otorisasi/user/index.vue'),
        },
        // End Of Otorisasi Route

        // Start Of Apu Route
        {
          path: '/apu/Kredit',
          meta: {
            title: 'Apu Kredit',
            permissions: true,
          },
          component: () => import('./views/app/apps/apu/kredit/table.vue'),
        },
        // End Of Apu Route

        // Admin route
        {
          path: '/admin/master/branches',
          meta: {
            title: 'Admin Master Branches',
            permissions: true,
          },
          component: () => import('./views/app/master/managebranches/index.vue'),
        },
        {
          path: '/admin/master/gridconfig',
          meta: {
            title: 'Admin Grid Config',
            permissions: true,
          },
          component: () => import('./views/app/master/grid_config/index.vue'),
        },
        {
          path: '/admin/master/urutangridconfig',
          meta: {
            title: 'Admin Atur Urutan Grid Config',
            permissions: true,
          },
          component: () => import('./views/app/master/grid_config/indexnew.vue'),
        },
        {
          path: '/admin/master/apikey',
          meta: {
            title: 'Admin Master Api Key',
            // permissions: true,
          },
          component: () => import('./views/app/apps/systemapp/apikey/index.vue'),
        },
        {
          path: '/admin/master/applist',
          meta: {
            title: 'Admin Master AppList',
            permissions: true,
          },
          component: () => import('./views/app/master/applist/index.vue'),
        },
        {
          path: '/admin/master/bankapplist',
          meta: {
            title: 'Admin Master Bank App List',
            permissions: true,
          },
          component: () => import('./views/app/master/bankapp/index.vue'),
        },
        {
          path: '/admin/master/arrangepermissions',
          meta: {
            title: 'Arrange Permissions',
            permissions: true,
          },
          component: () => import('./views/app/master/arrangepermission/index.vue'),
        },
        {
          path: '/admin/master/permissions',
          meta: {
            title: 'Arrange Data Permissions',
            permissions: true,
          },
          component: () => import('./views/app/master/managepermissions/index.vue'),
        },
        {
          path: '/admin/master/permission',
          meta: {
            title: 'Admin Master Data Permissions',
            permissions: true,
          },
          component: () => import('./views/app/master/managepermission/index.vue'),
        },
        {
          path: '/admin/master/users',
          meta: {
            title: 'Admin Master Data Users',
            permissions: true,
          },
          component: () => import('./views/app/master/manageusers/index.vue'),
        },
        {
          path: '/admin/master/roles',
          meta: {
            title: 'Admin Master Data Roles',
            permissions: true,
          },
          component: () => import('./views/app/master/manageroles/index.vue'),
        },
        {
          path: '/admin/master/bankpelapor',
          meta: {
            title: 'Admin Master Data Bank Pelapor',
            permissions: true,
          },
          component: () => import('./views/app/master/bankpelapor/index.vue'),
        },
        {
          path: '/admin/master/listdomain',
          meta: {
            title: 'Admin Master Data Domain',
            // permissions: true,
          },
          component: () => import('./views/app/master/listdomain/index.vue'),
        },
        // End of admin route

        // Start Of New Route
        {
          path: '/report/daftartagihan',
          meta: {
            title: 'Laporan Tagihan',
            permissions: true,
          },
          component: () => import('./views/app/apps/almabrur/index.vue'),
        },
        // End Of Laporan Route

        // Start Of Slik Route
        {
          path: '/slik/a01',
          meta: {
            title: 'Data slik',
            permissions: true,
          },
          component: () => import('./views/app/apps/slik/a01.vue'),
        },
        {
          path: '/slik/d01',
          meta: {
            title: 'Data slik',
            permissions: true,
          },
          component: () => import('./views/app/apps/slik/d01.vue'),
        },
        {
          path: '/slik/d02',
          meta: {
            title: 'Data slik',
            permissions: true,
          },
          component: () => import('./views/app/apps/slik/d02.vue'),
        },
        {
          path: '/slik/f01',
          meta: {
            title: 'Data slik',
            permissions: true,
          },
          component: () => import('./views/app/apps/slik/f01.vue'),
        },
        {
          path: '/slik/f02',
          meta: {
            title: 'Data slik',
            permissions: true,
          },
          component: () => import('./views/app/apps/slik/f02.vue'),
        },
        {
          path: '/slik/f03',
          meta: {
            title: 'Data slik',
            permissions: true,
          },
          component: () => import('./views/app/apps/slik/f03.vue'),
        },
        {
          path: '/slik/f04',
          meta: {
            title: 'Data slik',
            permissions: true,
          },
          component: () => import('./views/app/apps/slik/f04.vue'),
        },
        {
          path: '/slik/f05',
          meta: {
            title: 'Data slik',
            permissions: true,
          },
          component: () => import('./views/app/apps/slik/f05.vue'),
        },
        {
          path: '/slik/f06',
          meta: {
            title: 'Data slik',
            permissions: true,
          },
          component: () => import('./views/app/apps/slik/f06.vue'),
        },
        {
          path: '/slik/k01',
          meta: {
            title: 'Data slik',
            permissions: true,
          },
          component: () => import('./views/app/apps/slik/k01.vue'),
        },
        {
          path: '/slik/m01',
          meta: {
            title: 'Data slik',
            permissions: true,
          },
          component: () => import('./views/app/apps/slik/m01.vue'),
        },
        {
          path: '/slik/p01',
          meta: {
            title: 'Data slik',
            permissions: true,
          },
          component: () => import('./views/app/apps/slik/p01.vue'),
        },
        {
          path: '/slik/s01',
          meta: {
            title: 'Data slik',
            permissions: true,
          },
          component: () => import('./views/app/apps/slik/s01.vue'),
        },
        {
          path: '/slik/agunan',
          meta: {
            title: 'Agunan',
            key: 'agunan',
            permissions: true,
          },
          component: () => import('./views/app/apps/referensi/slik/index.vue'),
        },
        {
          path: '/slik/debitur_badan_usaha',
          meta: {
            title: 'Debitur Badan Usaha',
            key: 'debitur_badan_usaha',
            permissions: true,
          },
          component: () => import('./views/app/apps/referensi/slik/index.vue'),
        },
        {
          path: '/slik/debitur_individu',
          meta: {
            title: 'Debitur Individu',
            key: 'debitur_individu',
            permissions: true,
          },
          component: () => import('./views/app/apps/referensi/slik/index.vue'),
        },
        {
          path: '/slik/exporta01',
          meta: {
            title: 'Export A01',
            key: 'exporta01',
            permissions: true,
          },
          component: () => import('./views/app/apps/referensi/slik/index.vue'),
        },
        {
          path: '/slik/exportd01',
          meta: {
            title: 'Export D01',
            key: 'exportd01',
            permissions: true,
          },
          component: () => import('./views/app/apps/referensi/slik/index.vue'),
        },
        {
          path: '/slik/exportf01',
          meta: {
            title: 'Export F01',
            key: 'exportf01',
            permissions: true,
          },
          component: () => import('./views/app/apps/referensi/slik/index.vue'),
        },
        {
          path: '/slik/exports01',
          meta: {
            title: 'Export S01',
            key: 'exports01',
            permissions: true,
          },
          component: () => import('./views/app/apps/referensi/slik/index.vue'),
        },
        {
          path: '/slik/fasilitas_lainnya',
          meta: {
            title: 'Fasilitas Lainnya',
            key: 'fasilitas_lainnya',
            permissions: true,
          },
          component: () => import('./views/app/apps/referensi/slik/index.vue'),
        },
        {
          path: '/slik/kode_status_pengurus_pemilik',
          meta: {
            // title: '',
            key: 'kode_status_pengurus_pemilik',
            permissions: true,
          },
          component: () => import('./views/app/apps/referensi/slik/index.vue'),
        },
        {
          path: '/slik/kode_sifat_kredit_pembiayaan',
          meta: {
            // title: '',
            key: 'kode_sifat_kredit_pembiayaan',
            permissions: true,
          },
          component: () => import('./views/app/apps/referensi/slik/index.vue'),
        },
        {
          path: '/slik/kode_sifat_akad_pembiayaan',
          meta: {
            // title: '',
            key: 'kode_sifat_akad_pembiayaan',
            permissions: true,
          },
          component: () => import('./views/app/apps/referensi/slik/index.vue'),
        },
        {
          path: '/slik/header',
          meta: {
            title: 'Header',
            key: 'header',
            permissions: true,
          },
          component: () => import('./views/app/apps/referensi/slik/index.vue'),
        },
        {
          path: '/slik/header_laporan',
          meta: {
            title: 'Header Laporan',
            key: 'header_laporan',
            permissions: true,
          },
          component: () => import('./views/app/apps/referensi/slik/index.vue'),
        },
        {
          path: '/slik/kode_bidang_usaha_tempat_bekerja_debitur',
          meta: {
            title: 'Kode Bidang Usaha Tempat Bekerja Debitur',
            key: 'kode_bidang_usaha_tempat_bekerja_debitur',
            permissions: true,
          },
          component: () => import('./views/app/apps/referensi/slik/index.vue'),
        },
        {
          path: '/slik/kode_cara_restrukturisasi',
          meta: {
            title: 'Kode Cara Restrukturisasi',
            key: 'kode_cara_restrukturisasi',
            permissions: true,
          },
          component: () => import('./views/app/apps/referensi/slik/index.vue'),
        },
        {
          path: '/slik/kode_golongan_debitur_pihak_ke3_bukan_bank',
          meta: {
            title: 'Kode Golongan Debitur Pihak Ke3 Bukan Bank',
            key: 'kode_golongan_debitur_pihak_ke3_bukan_bank',
            permissions: true,
          },
          component: () => import('./views/app/apps/referensi/slik/index.vue'),
        },
        {
          path: 'kode_golongan_penjamin',
          meta: {
            // title: '',
            key: 'kode_golongan_penjamin',
            permissions: true,
          },
          component: () => import('./views/app/apps/referensi/slik/index.vue'),
        },
        {
          path: '/slik/kode_golongan_penjamin',
          meta: {
            // title: '',
            key: 'kode_golongan_penjamin',
            permissions: true,
          },
          component: () => import('./views/app/apps/referensi/slik/index.vue'),
        },
        {
          path: '/slik/kode_hubungan_dengan_pelapor',
          meta: {
            // title: '',
            key: 'kode_hubungan_dengan_pelapor',
            permissions: true,
          },
          component: () => import('./views/app/apps/referensi/slik/index.vue'),
        },
        {
          path: '/slik/kode_jabatan_pengurus_pemilik',
          meta: {
            // title: '',
            key: 'kode_jabatan_pengurus_pemilik',
            permissions: true,
          },
          component: () => import('./views/app/apps/referensi/slik/index.vue'),
        },
        {
          path: '/slik/kode_jenis_agunan',
          meta: {
            // title: '',
            key: 'kode_jenis_agunan',
          },
          component: () => import('./views/app/apps/referensi/slik/index.vue'),
        },
        {
          path: '/slik/kode_jenis_badan_usaha',
          meta: {
            // title: '',
            key: 'kode_jenis_badan_usaha',
            permissions: true,
          },
          component: () => import('./views/app/apps/referensi/slik/index.vue'),
        },
        {
          path: '/slik/kode_jenis_fasilitas',
          meta: {
            // title: '',
            key: 'kode_jenis_fasilitas',
            permissions: true,
          },
          component: () => import('./views/app/apps/referensi/slik/index.vue'),
        },
        {
          path: '/slik/kode_jenis_garansi',
          meta: {
            // title: '',
            key: 'kode_jenis_garansi',
            permissions: true,
          },
          component: () => import('./views/app/apps/referensi/slik/index.vue'),
        },
        {
          path: '/slik/kode_jenis_kelamin',
          meta: {
            // title: '',
            key: 'kode_jenis_kelamin',
            permissions: true,
          },
          component: () => import('./views/app/apps/referensi/slik/index.vue'),
        },
        {
          path: '/slik/kode_jenis_kredit_pembiayaan',
          meta: {
            // title: '',
            key: 'kode_jenis_kredit_pembiayaan',
            permissions: true,
          },
          component: () => import('./views/app/apps/referensi/slik/index.vue'),
        },
        {
          path: '/slik/kode_jenis_lc',
          meta: {
            // title: '',
            key: 'kode_jenis_lc',
            permissions: true,
          },
          component: () => import('./views/app/apps/referensi/slik/index.vue'),
        },
        {
          path: '/slik/kode_jenis_ljk',
          meta: {
            // title: '',
            key: 'kode_jenis_ljk',
            permissions: true,
          },
          component: () => import('./views/app/apps/referensi/slik/index.vue'),
        },
        {
          path: '/slik/kode_jenis_penggunaan',
          meta: {
            // title: '',
            key: 'kode_jenis_penggunaan',
            permissions: true,
          },
          component: () => import('./views/app/apps/referensi/slik/index.vue'),
        },
        {
          path: '/slik/kode_jenis_pengikatan',
          meta: {
            // title: '',
            key: 'kode_jenis_pengikatan',
            permissions: true,
          },
          component: () => import('./views/app/apps/referensi/slik/index.vue'),
        },
        {
          path: '/slik/kode_jenis_segmen_data',
          meta: {
            // title: '',
            key: 'kode_jenis_segmen_data',
            permissions: true,
          },
          component: () => import('./views/app/apps/referensi/slik/index.vue'),
        },
        {
          path: '/slik/kode_jenis_segmen_fasilitas',
          meta: {
            // title: '',
            key: 'kode_jenis_segmen_fasilitas',
            permissions: true,
          },
          component: () => import('./views/app/apps/referensi/slik/index.vue'),
        },
        {
          path: '/slik/kode_jenis_suku_bunga_imbalan',
          meta: {
            // title: '',
            key: 'kode_jenis_suku_bunga_imbalan',
            permissions: true,
          },
          component: () => import('./views/app/apps/referensi/slik/index.vue'),
        },
        {
          path: '/slik/kode_jenis_surat_berharga',
          meta: {
            // title: '',
            key: 'kode_jenis_surat_berharga',
            permissions: true,
          },
          component: () => import('./views/app/apps/referensi/slik/index.vue'),
        },
        {
          path: '/slik/kode_kab_kota_dati_2',
          meta: {
            // title: '',
            key: 'kode_kab_kota_dati_2',
            permissions: true,
          },
          component: () => import('./views/app/apps/referensi/slik/index.vue'),
        },
        {
          path: '/slik/kode_kab_kota_dati_2_lokasi_proyek_penggunaan_kredit',
          meta: {
            // title: '',
            key: 'kode_kab_kota_dati_2_lokasi_proyek_penggunaan_kredit',
            permissions: true,
          },
          component: () => import('./views/app/apps/referensi/slik/index.vue'),
        },
        {
          path: '/slik/kode_kantor_cabang',
          meta: {
            // title: '',
            key: 'kode_kantor_cabang',
            permissions: true,
          },
          component: () => import('./views/app/apps/referensi/slik/index.vue'),
        },
        {
          path: '/slik/kode_kategori_debitur',
          meta: {
            // title: '',
            key: 'kode_kategori_debitur',
            permissions: true,
          },
          component: () => import('./views/app/apps/referensi/slik/index.vue'),
        },
        {
          path: '/slik/kode_kolektibilitas',
          meta: {
            // title: '',
            key: 'kode_kolektibilitas',
            permissions: true,
          },
          component: () => import('./views/app/apps/referensi/slik/index.vue'),
        },
        {
          path: '/slik/kode_kondisi',
          meta: {
            // title: '',
            key: 'kode_kondisi',
            permissions: true,
          },
          component: () => import('./views/app/apps/referensi/slik/index.vue'),
        },
        {
          path: '/slik/kode_kredit_pembiayaan_program_pemerintah',
          meta: {
            // title: '',
            key: 'kode_kredit_pembiayaan_program_pemerintah',
            permissions: true,
          },
          component: () => import('./views/app/apps/referensi/slik/index.vue'),
        },
        {
          path: '/slik/kode_lembaga_pemeringkat',
          meta: {
            // title: '',
            key: 'kode_lembaga_pemeringkat',
            permissions: true,
          },
          component: () => import('./views/app/apps/referensi/slik/index.vue'),
        },
        {
          path: '/slik/kode_ljk',
          meta: {
            // title: '',
            key: 'kode_ljk',
            permissions: true,
          },
          component: () => import('./views/app/apps/referensi/slik/index.vue'),
        },
        {
          path: '/slik/kode_ljk2',
          meta: {
            // title: '',
            key: 'kode_ljk2',
            permissions: true,
          },
          component: () => import('./views/app/apps/referensi/slik/index.vue'),
        },
        {
          path: '/slik/kode_negara_domisili_debitur',
          meta: {
            // title: '',
            key: 'kode_negara_domisili_debitur',
            permissions: true,
          },
          component: () => import('./views/app/apps/referensi/slik/index.vue'),
        },
        {
          path: '/slik/kode_orientasi_penggunaan',
          meta: {
            // title: '',
            key: 'kode_orientasi_penggunaan',
            permissions: true,
          },
          component: () => import('./views/app/apps/referensi/slik/index.vue'),
        },
        {
          path: '/slik/kode_pekerjaan',
          meta: {
            // title: '',
            key: 'kode_pekerjaan',
            permissions: true,
          },
          component: () => import('./views/app/apps/referensi/slik/index.vue'),
        },
        {
          path: '/slik/kode_pos',
          meta: {
            // title: '',
            key: 'kode_pos',
            permissions: true,
          },
          component: () => import('./views/app/apps/referensi/slik/index.vue'),
        },
        {
          path: '/slik/kode_sandi_kab_kota_dati_2',
          meta: {
            // title: '',
            key: 'kode_sandi_kab_kota_dati_2',
            permissions: true,
          },
          component: () => import('./views/app/apps/referensi/slik/index.vue'),
        },
        {
          path: '/slik/kode_sebab_macet',
          meta: {
            // title: '',
            key: 'kode_sebab_macet',
            permissions: true,
          },
          component: () => import('./views/app/apps/referensi/slik/index.vue'),
        },
        {
          path: '/slik/kode_sektor_ekonomi',
          meta: {
            // title: '',
            key: 'kode_sektor_ekonomi',
            permissions: true,
          },
          component: () => import('./views/app/apps/referensi/slik/index.vue'),
        },
        {
          path: '/slik/kode_jenis_kredit_pembiayaan',
          meta: {
            // title: '',
            key: 'kode_jenis_kredit_pembiayaan',
            permissions: true,
          },
          component: () => import('./views/app/apps/referensi/slik/index.vue'),
        },
        {
          path: '/slik/kode_skim_akad_pembiayaan',
          meta: {
            // title: '',
            key: 'kode_skim_akad_pembiayaan',
            permissions: true,
          },
          component: () => import('./views/app/apps/referensi/slik/index.vue'),
        },
        {
          path: '/slik/kode_status_agunan',
          meta: {
            // title: '',
            key: 'kode_status_agunan',
            permissions: true,
          },
          component: () => import('./views/app/apps/referensi/slik/index.vue'),
        },
        {
          path: '/slik/kode_status_pendidikan_gelar_debitur',
          meta: {
            // title: '',
            key: 'kode_status_pendidikan_gelar_debitur',
            permissions: true,
          },
          component: () => import('./views/app/apps/referensi/slik/index.vue'),
        },
        {
          path: '/slik/kode_jabatan_pengurus_pemilik',
          meta: {
            // title: '',
            key: 'kode_jabatan_pengurus_pemilik',
            permissions: true,
          },
          component: () => import('./views/app/apps/referensi/slik/index.vue'),
        },
        {
          path: '/slik/kode_sumber_penghasilan',
          meta: {
            // title: '',
            key: 'kode_sumber_penghasilan',
            permissions: true,
          },
          component: () => import('./views/app/apps/referensi/slik/index.vue'),
        },
        {
          path: '/slik/kode_tujuan_garansi',
          meta: {
            // title: '',
            key: 'kode_tujuan_garansi',
            permissions: true,
          },
          component: () => import('./views/app/apps/referensi/slik/index.vue'),
        },
        {
          path: '/slik/kode_tujuan_kepemilikan',
          meta: {
            // title: '',
            key: 'kode_tujuan_kepemilikan',
            permissions: true,
          },
          component: () => import('./views/app/apps/referensi/slik/index.vue'),
        },
        {
          path: '/slik/kode_tujuan_lc',
          meta: {
            // title: '',
            key: 'kode_tujuan_lc',
            permissions: true,
          },
          component: () => import('./views/app/apps/referensi/slik/index.vue'),
        },
        {
          path: '/slik/kode_valuta',
          meta: {
            // title: '',
            key: 'kode_valuta',
            permissions: true,
          },
          component: () => import('./views/app/apps/referensi/slik/index.vue'),
        },
        {
          path: '/slik/kredit',
          meta: {
            // title: '',
            key: 'kredit',
          },
          component: () => import('./views/app/apps/referensi/slik/index.vue'),
        },
        {
          path: '/slik/kredit_join_account',
          meta: {
            // title: '',
            key: 'kredit_join_account',
            permissions: true,
          },
          component: () => import('./views/app/apps/referensi/slik/index.vue'),
        },
        {
          path: '/slik/laporan_keuangan_debitur',
          meta: {
            // title: '',
            key: 'laporan_keuangan_debitur',
            permissions: true,
          },
          component: () => import('./views/app/apps/referensi/slik/index.vue'),
        },
        {
          path: '/slik/pengurus_pemilik',
          meta: {
            // title: '',
            key: 'pengurus_pemilik',
            permissions: true,
          },
          component: () => import('./views/app/apps/referensi/slik/index.vue'),
        },
        {
          path: '/slik/penjamin',
          meta: {
            // title: '',
            key: 'penjamin',
            permissions: true,
          },
          component: () => import('./views/app/apps/referensi/slik/index.vue'),
        },
        {
          path: '/slik/ref_sektor_ekonomi',
          meta: {
            // title: '',
            key: 'ref_sektor_ekonomi',
            permissions: true,
          },
          component: () => import('./views/app/apps/referensi/slik/index.vue'),
        },
        {
          path: '/slik/ref_status',
          meta: {
            // title: '',
            key: 'ref_status',
            permissions: true,
          },
          component: () => import('./views/app/apps/referensi/slik/index.vue'),
        },
        {
          path: '/slik/sektorekonomi',
          meta: {
            // title: '',
            key: 'sektorekonomi',
            permissions: true,
          },
          component: () => import('./views/app/apps/referensi/slik/index.vue'),
        },
        {
          path: '/slik/summary_fasilitas',
          meta: {
            // title: '',
            key: 'summary_fasilitas',
            permissions: true,
          },
          component: () => import('./views/app/apps/referensi/slik/index.vue'),
        },
        {
          path: '/slik/tbl_kodepos',
          meta: {
            // title: '',
            key: 'tbl_kodepos',
            permissions: true,
          },
          component: () => import('./views/app/apps/referensi/slik/index.vue'),
        },
        {
          path: '/slik/viewa01',
          meta: {
            // title: '',
            key: 'viewa01',
            permissions: true,
          },
          component: () => import('./views/app/apps/referensi/slik/index.vue'),
        },
        {
          path: '/slik/viewd01',
          meta: {
            // title: '',
            key: 'viewd01',
            permissions: true,
          },
          component: () => import('./views/app/apps/referensi/slik/index.vue'),
        },
        {
          path: '/slik/viewd02',
          meta: {
            // title: '',
            key: 'viewd02',
            permissions: true,
          },
          component: () => import('./views/app/apps/referensi/slik/index.vue'),
        },
        {
          path: '/slik/viewf01',
          meta: {
            // title: '',
            key: 'viewf01',
            permissions: true,
          },
          component: () => import('./views/app/apps/referensi/slik/index.vue'),
        },

        // End Of Slik Route

        {
          path: '/gb_laporan_laba_rugi',
          meta: {
            title: 'Gb Laporan Laba Rugi',
            key: 'gb_laporan_laba_rugi',
            permissions: true,
          },
          component: () => import('./views/app/apps/referensi/gabungan/index.vue'),
        },
        {
          path: '/gb_laporan_distribusi_bagi_hasil',
          meta: {
            title: 'Gb Laporan Distribusi Bagi Hasil',
            key: 'gb_laporan_distribusi_bagi_hasil',
            permissions: true,
          },
          component: () => import('./views/app/apps/referensi/gabungan/index.vue'),
        },
        {
          path: '/gb_laporan_mingguan_cash_ratio',
          meta: {
            title: 'Gb Laporan Mingguan Cash Ratio',
            key: 'gb_laporan_mingguan_cash_ratio',
            permissions: true,
          },
          component: () => import('./views/app/apps/referensi/gabungan/index.vue'),
        },
        {
          path: '/gb_laporan_posisi_keuangan_rek_administratif',
          meta: {
            title: 'Gb Laporan Posisi Keuangan Rek Administratif',
            key: 'gb_laporan_posisi_keuangan_rek_administratif',
            permissions: true,
          },
          component: () => import('./views/app/apps/referensi/gabungan/index.vue'),
        },
        {
          path: '/gb_laporan_sumber_penggunaan_dana_kebajikan',
          meta: {
            title: 'Gb Laporan Sumber Penggunaan Dana Kebajikan',
            key: 'gb_laporan_sumber_penggunaan_dana_kebajikan',
            permissions: true,
          },
          component: () => import('./views/app/apps/referensi/gabungan/index.vue'),
        },
        {
          path: '/gb_laporan_sumber_penyaluran_dana_zakat_wakaf',
          meta: {
            title: 'Gb Laporan Sumber Penyaluran Dana Zakat Wakaf',
            key: 'gb_laporan_sumber_penyaluran_dana_zakat_wakaf',
            permissions: true,
          },
          component: () => import('./views/app/apps/referensi/gabungan/index.vue'),
        },
        {
          path: '/gb_rasio_keuangan_triwulanan',
          meta: {
            title: 'Gb Rasio Keuangan Triwulanan',
            key: 'gb_rasio_keuangan_triwulanan',
            permissions: true,
          },
          component: () => import('./views/app/apps/referensi/gabungan/index.vue'),
        },
        {
          path: '/gb_sandi_hubungan_pihak_terkait',
          meta: {
            title: 'Gb Sandi Hubungan Pihak Terkait',
            key: 'gb_sandi_hubungan_pihak_terkait',
            permissions: true,
          },
          component: () => import('./views/app/apps/referensi/gabungan/index.vue'),
        },
        {
          path: '/gb_sandi_jabatan_direksi_komisaris_dps',
          meta: {
            title: 'Gb Sandi Jabatan Direksi Komisaris Dps',
            key: 'gb_sandi_jabatan_direksi_komisaris_dps',
            permissions: true,
          },
          component: () => import('./views/app/apps/referensi/gabungan/index.vue'),
        },
        {
          path: '/gb_sandi_jabatan_organ_pelaksana',
          meta: {
            title: 'Gb Sandi Jabatan Organ Pelaksana',
            key: 'gb_sandi_jabatan_organ_pelaksana',
            permissions: true,
          },
          component: () => import('./views/app/apps/referensi/gabungan/index.vue'),
        },
        {
          path: '/gb_sandi_jenis_akad',
          meta: {
            title: 'Gb Sandi Jenis Akad',
            key: 'gb_sandi_jenis_akad',
            permissions: true,
          },
          component: () => import('./views/app/apps/referensi/gabungan/index.vue'),
        },
        {
          path: '/gb_sandi_jenis_pemilik',
          meta: {
            title: 'Gb Sandi Jenis Pemilik',
            key: 'gb_sandi_jenis_pemilik',
            permissions: true,
          },
          component: () => import('./views/app/apps/referensi/gabungan/index.vue'),
        },
        {
          path: '/gb_sandi_keanggotaan_komite',
          meta: {
            title: 'Gb Sandi Keanggotaan Komite',
            key: 'gb_sandi_keanggotaan_komite',
            permissions: true,
          },
          component: () => import('./views/app/apps/referensi/gabungan/index.vue'),
        },
        {
          path: '/gb_sandi_komisaris_independen',
          meta: {
            title: 'Gb Sandi Komisaris Independen',
            key: 'gb_sandi_komisaris_independen',
            permissions: true,
          },
          component: () => import('./views/app/apps/referensi/gabungan/index.vue'),
        },
        {
          path: '/gb_sandi_membawahi_fungsi_kepatuhan',
          meta: {
            title: 'Gb Sandi Membawahi Fungsi Kepatuhan',
            key: 'gb_sandi_membawahi_fungsi_kepatuhan',
            permissions: true,
          },
          component: () => import('./views/app/apps/referensi/gabungan/index.vue'),
        },
        {
          path: '/gb_sandi_memiliki_izin_pva',
          meta: {
            title: 'Gb Sandi Memiliki Izin Pva',
            key: 'gb_sandi_memiliki_izin_pva',
            permissions: true,
          },
          component: () => import('./views/app/apps/referensi/gabungan/index.vue'),
        },
        {
          path: '/gb_sandi_metode_restrukturisasi',
          meta: {
            title: 'Gb Sandi Metode Restrukturisasi',
            key: 'gb_sandi_metode_restrukturisasi',
            permissions: true,
          },
          component: () => import('./views/app/apps/referensi/gabungan/index.vue'),
        },
        {
          path: '/gb_sandi_pendidikan_formal',
          meta: {
            title: 'Gb Sandi Pendidikan Formal',
            key: 'gb_sandi_pendidikan_formal',
            permissions: true,
          },
          component: () => import('./views/app/apps/referensi/gabungan/index.vue'),
        },
        {
          path: '/gb_sandi_pihak_terkait',
          meta: {
            title: 'Gb Sandi Pihak Terkait',
            key: 'gb_sandi_pihak_terkait',
            permissions: true,
          },
          component: () => import('./views/app/apps/referensi/gabungan/index.vue'),
        },
        {
          path: '/gb_sandi_pihak_psp',
          meta: {
            title: 'Gb Sandi Psp',
            key: 'gb_sandi_psp',
            permissions: true,
          },
          component: () => import('./views/app/apps/referensi/gabungan/index.vue'),
        },
        {
          path: '/gb_sandi_pihak_sertifikat',
          meta: {
            title: 'Gb Sandi Sertifikat',
            key: 'gb_sandi_sertifikat',
            permissions: true,
          },
          component: () => import('./views/app/apps/referensi/gabungan/index.vue'),
        },
        {
          path: '/kc_laporan_laba_rugi',
          meta: {
            title: 'Kc Laporan Laba Rugi',
            key: 'kc_laporan_laba_rugi',
            permissions: true,
          },
          component: () => import('./views/app/apps/referensi/gabungan/index.vue'),
        },
        {
          path: '/kc_laporan_posisi_keuangan_rek_administratif',
          meta: {
            title: 'Kc Laporan Posisi Keuangan Rek Administratif',
            key: 'kc_laporan_posisi_keuangan_rek_administratif',
            permissions: true,
          },
          component: () => import('./views/app/apps/referensi/gabungan/index.vue'),
        },
        {
          path: '/kc_sandi_alasan_diblokir',
          meta: {
            title: 'Kc Sandi Alasan Diblokir',
            key: 'kc_sandi_alasan_diblokir',
            permissions: true,
          },
          component: () => import('./views/app/apps/referensi/gabungan/index.vue'),
        },
        {
          path: '/kc_sandi_aset_tetap_inventaris',
          meta: {
            title: 'Kc Sandi Aset Tetap Inventaris',
            key: 'kc_sandi_aset_tetap_inventaris',
            permissions: true,
          },
          component: () => import('./views/app/apps/referensi/gabungan/index.vue'),
        },
        {
          path: '/kc_sandi_bank',
          meta: {
            title: 'Kc Sandi Bank',
            key: 'kc_sandi_bank',
            permissions: true,
          },
          component: () => import('./views/app/apps/referensi/gabungan/index.vue'),
        },
        {
          path: '/kc_sandi_dati_ii',
          meta: {
            title: 'Kc Sandi Dati Ii',
            key: 'kc_sandi_dati_ii',
            permissions: true,
          },
          component: () => import('./views/app/apps/referensi/gabungan/index.vue'),
        },
        {
          path: '/kc_sandi_golongan_nasabah_debitur_bukan_bank',
          meta: {
            title: 'Kc Sandi Golongan Nasabah Debitur Bukan Bank',
            key: 'kc_sandi_golongan_nasabah_debitur_bukan_bank',
            permissions: true,
          },
          component: () => import('./views/app/apps/referensi/gabungan/index.vue'),
        },
        {
          path: '/kc_sandi_golongan_penjamin',
          meta: {
            title: 'Kc Sandi Golongan Penjamin',
            key: 'kc_sandi_golongan_penjamin',
            permissions: true,
          },
          component: () => import('./views/app/apps/referensi/gabungan/index.vue'),
        },
        {
          path: '/kc_sandi_golongan_penyalur_penerusan_dana_investasi_terikat',
          meta: {
            title: 'Kc Sandi Golongan Penyalur Penerusan Dana Investasi Terikat',
            key: 'kc_sandi_golongan_penyalur_penerusan_dana_investasi_terikat',
            permissions: true,
          },
          component: () => import('./views/app/apps/referensi/gabungan/index.vue'),
        },
        {
          path: '/kc_sandi_hubungan_dengan_bank',
          meta: {
            title: 'Kc Sandi Hubungan Dengan Bank',
            key: 'kc_sandi_hubungan_dengan_bank',
            permissions: true,
          },
          component: () => import('./views/app/apps/referensi/gabungan/index.vue'),
        },
        {
          path: '/kc_sandi_jenis_agunan',
          meta: {
            title: 'Kc Sandi Jenis Agunan',
            key: 'kc_sandi_jenis_agunan',
            permissions: true,
          },
          component: () => import('./views/app/apps/referensi/gabungan/index.vue'),
        },
        {
          path: '/kc_sandi_jenis_akad',
          meta: {
            title: 'Kc Sandi Jenis Akad',
            key: 'kc_sandi_jenis_akad',
            permissions: true,
          },
          component: () => import('./views/app/apps/referensi/gabungan/index.vue'),
        },
        {
          path: '/kc_sandi_jenis_akad_ayda',
          meta: {
            title: 'Kc Sandi Jenis Akad Ayda',
            key: 'kc_sandi_jenis_akad_ayda',
            permissions: true,
          },
          component: () => import('./views/app/apps/referensi/gabungan/index.vue'),
        },
        {
          path: '/kc_sandi_jenis_akad_bagi_hasil',
          meta: {
            title: 'Kc Sandi Jenis Akad Bagi Hasil',
            key: 'kc_sandi_jenis_akad_bagi_hasil',
            permissions: true,
          },
          component: () => import('./views/app/apps/referensi/gabungan/index.vue'),
        },
        {
          path: '/kc_sandi_jenis_akad_pembiayaan_diterima',
          meta: {
            title: 'Kc Sandi Jenis Akad Pembiayaan Diterima',
            key: 'kc_sandi_jenis_akad_pembiayaan_diterima',
            permissions: true,
          },
          component: () => import('./views/app/apps/referensi/gabungan/index.vue'),
        },
        {
          path: '/kc_sandi_jenis_akad_pembiayaan_sewa',
          meta: {
            title: 'Kc Sandi Jenis Akad Pembiayaan Sewa',
            key: 'kc_sandi_jenis_akad_pembiayaan_sewa',
            permissions: true,
          },
          component: () => import('./views/app/apps/referensi/gabungan/index.vue'),
        },
        {
          path: '/kc_sandi_jenis_aset_aidp',
          meta: {
            title: 'Kc Sandi Jenis Aset Aidp',
            key: 'kc_sandi_jenis_aset_aidp',
            permissions: true,
          },
          component: () => import('./views/app/apps/referensi/gabungan/index.vue'),
        },
        {
          path: '/kc_sandi_jenis_akad_pembiayaan_ayda',
          meta: {
            title: 'Kc Sandi Jenis Akad Pembiayaan Ayda',
            key: 'kc_sandi_jenis_akad_ayda',
            permissions: true,
          },
          component: () => import('./views/app/apps/referensi/gabungan/index.vue'),
        },
        {
          path: '/kc_sandi_jenis_aset_pembiayaan_sewa',
          meta: {
            title: 'Kc Sandi Jenis Aset Pembiayaan Sewa',
            key: 'kc_sandi_jenis_aset_pembiayaan_sewa',
            permissions: true,
          },
          component: () => import('./views/app/apps/referensi/gabungan/index.vue'),
        },
        {
          path: '/kc_sandi_jenis_aset_persediaan',
          meta: {
            title: 'Kc Sandi Jenis Aset Persediaan',
            key: 'kc_sandi_jenis_aset_persediaan',
            permissions: true,
          },
          component: () => import('./views/app/apps/referensi/gabungan/index.vue'),
        },
        {
          path: '/kc_sandi_jenis_aset_tidak_berwujud',
          meta: {
            title: 'Kc Sandi Jenis Aset Tidak Berwujud',
            key: 'kc_sandi_jenis_aset_tidak_berwujud',
            permissions: true,
          },
          component: () => import('./views/app/apps/referensi/gabungan/index.vue'),
        },
        {
          path: '/kc_sandi_jenis_instrumen',
          meta: {
            title: 'Kc Sandi Jenis Instrumen',
            key: 'kc_sandi_jenis_instrumen',
            permissions: true,
          },
          component: () => import('./views/app/apps/referensi/gabungan/index.vue'),
        },
        {
          path: '/kc_sandi_jenis_instrumen_aset_produktif_hapusbuku',
          meta: {
            title: 'Kc Sandi Jenis Instrumen Aset Produktif Hapusbuku',
            key: 'kc_sandi_jenis_instrumen_aset_produktif_hapusbuku',
            permissions: true,
          },
          component: () => import('./views/app/apps/referensi/gabungan/index.vue'),
        },
        {
          path: '/kc_sandi_jenis_instrumen_dana_investasi',
          meta: {
            title: 'Kc Sandi Jenis Instrumen Dana Investasi',
            key: 'kc_sandi_jenis_instrumen_dana_investasi',
            permissions: true,
          },
          component: () => import('./views/app/apps/referensi/gabungan/index.vue'),
        },
        {
          path: '/kc_sandi_jenis_instrumen_kewajiban_kepada_bank_lain',
          meta: {
            title: 'Kc Sandi Jenis Instrumen Kewajiban Kepada Bank Lain',
            key: 'kc_sandi_jenis_instrumen_kewajiban_kepada_bank_lain',
            permissions: true,
          },
          component: () => import('./views/app/apps/referensi/gabungan/index.vue'),
        },
        {
          path: '/kc_sandi_jenis_instrumen_pembiayaan_diterima',
          meta: {
            title: 'Kc Sandi Jenis Instrumen Pembiayaan Diterima',
            key: 'kc_sandi_jenis_instrumen_pembiayaan_diterima',
            permissions: true,
          },
          component: () => import('./views/app/apps/referensi/gabungan/index.vue'),
        },
        {
          path: '/kc_sandi_jenis_instrumen_ppap',
          meta: {
            title: 'Kc Sandi Jenis Instrumen Ppap',
            key: 'kc_sandi_jenis_instrumen_ppap',
            permissions: true,
          },
          component: () => import('./views/app/apps/referensi/gabungan/index.vue'),
        },
        {
          path: '/kc_sandi_jenis_instrumen_rupa_aset',
          meta: {
            title: 'Kc Sandi Jenis Instrumen Rupa Aset',
            key: 'kc_sandi_jenis_instrumen_rupa_aset',
            permissions: true,
          },
          component: () => import('./views/app/apps/referensi/gabungan/index.vue'),
        },
        {
          path: '/kc_sandi_jenis_instrumen_rupa_kewajiban',
          meta: {
            title: 'Kc Sandi Jenis Instrumen Rupa Kewajiban',
            key: 'kc_sandi_jenis_instrumen_rupa_kewajiban',
            permissions: true,
          },
          component: () => import('./views/app/apps/referensi/gabungan/index.vue'),
        },
        {
          path: '/kc_sandi_jenis_kewajiban_segera',
          meta: {
            title: 'Kc Sandi Jenis Kewajiban Segera',
            key: 'kc_sandi_jenis_kewajiban_segera',
            permissions: true,
          },
          component: () => import('./views/app/apps/referensi/gabungan/index.vue'),
        },
        {
          path: '/kc_sandi_jenis_modal',
          meta: {
            title: 'Kc Sandi Jenis Modal',
            key: 'kc_sandi_jenis_modal',
            permissions: true,
          },
          component: () => import('./views/app/apps/referensi/gabungan/index.vue'),
        },
        {
          path: '/kc_sandi_jenis_operasional',
          meta: {
            title: 'Kc Sandi Jenis Operasional',
            key: 'kc_sandi_jenis_operasional',
            permissions: true,
          },
          component: () => import('./views/app/apps/referensi/gabungan/index.vue'),
        },
        {
          path: '/kc_sandi_jenis_pembiayaan',
          meta: {
            title: 'Kc Sandi Jenis Pembiayaan',
            key: 'kc_sandi_jenis_pembiayaan',
            permissions: true,
          },
          component: () => import('./views/app/apps/referensi/gabungan/index.vue'),
        },
        {
          path: '/kc_sandi_jenis_pembiayaan_persetujuan',
          meta: {
            title: 'Kc Sandi Jenis Pembiayaan Persetujuan',
            key: 'kc_sandi_jenis_pembiayaan_persetujuan',
            permissions: true,
          },
          component: () => import('./views/app/apps/referensi/gabungan/index.vue'),
        },
        {
          path: '/kc_sandi_jenis_pembiayaan_sewa',
          meta: {
            title: 'Kc Sandi Jenis Pembiayaan Sewa',
            key: 'kc_sandi_jenis_pembiayaan_sewa',
            permissions: true,
          },
          component: () => import('./views/app/apps/referensi/gabungan/index.vue'),
        },
        {
          path: '/kc_sandi_jenis_penggunaan',
          meta: {
            title: 'Kc Sandi Jenis Penggunaan',
            key: 'kc_sandi_jenis_penggunaan',
            permissions: true,
          },
          component: () => import('./views/app/apps/referensi/gabungan/index.vue'),
        },
        {
          path: '/kc_sandi_jenis_penggunaan_penerusan_dana_terikat',
          meta: {
            title: 'Kc Sandi Jenis Penggunaan Penerusan Dana Terikat',
            key: 'kc_sandi_jenis_penggunaan_penerusan_dana_terikat',
            permissions: true,
          },
          component: () => import('./views/app/apps/referensi/gabungan/index.vue'),
        },
        {
          path: '/kc_sandi_jenis_pengikatan',
          meta: {
            title: 'Kc Sandi Jenis Pengikatan',
            key: 'kc_sandi_jenis_pengikatan',
            permissions: true,
          },
          component: () => import('./views/app/apps/referensi/gabungan/index.vue'),
        },
        {
          path: '/kc_sandi_jenis_penyaluran_penerusan_dana_investasi_terikat',
          meta: {
            title: 'Kc Sandi Jenis Penyaluran Penerusan Dana Investasi Terikat',
            key: 'kc_sandi_jenis_penyaluran_penerusan_dana_investasi_terikat',
            permissions: true,
          },
          component: () => import('./views/app/apps/referensi/gabungan/index.vue'),
        },
        {
          path: '/kc_sandi_jenis_piutang',
          meta: {
            title: 'Kc Sandi Jenis Piutang',
            key: 'kc_sandi_jenis_piutang',
            permissions: true,
          },
          component: () => import('./views/app/apps/referensi/gabungan/index.vue'),
        },
        {
          path: '/kc_sandi_jenis_piutang_istishna',
          meta: {
            title: 'Kc Sandi Jenis Piutang Istishna',
            key: 'kc_sandi_jenis_piutang_istishna',
            permissions: true,
          },
          component: () => import('./views/app/apps/referensi/gabungan/index.vue'),
        },
        {
          path: '/kc_sandi_jenis_piutang_murabahah',
          meta: {
            title: 'Kc Sandi Jenis Piutang Murabahah',
            key: 'kc_sandi_jenis_piutang_murabahah',
            permissions: true,
          },
          component: () => import('./views/app/apps/referensi/gabungan/index.vue'),
        },
        {
          path: '/kc_sandi_jenis_rincian_modal',
          meta: {
            title: 'Kc Sandi Jenis Rincian Modal',
            key: 'kc_sandi_jenis_rincian_modal',
            permissions: true,
          },
          component: () => import('./views/app/apps/referensi/gabungan/index.vue'),
        },
        {
          path: '/kc_sandi_kategori_usaha',
          meta: {
            title: 'Kc Sandi Kategori Usaha',
            key: 'kc_sandi_kategori_usaha',
            permissions: true,
          },
          component: () => import('./views/app/apps/referensi/gabungan/index.vue'),
        },
        {
          path: '/kc_sandi_kota_kab',
          meta: {
            title: 'Kc Sandi Kota Kab',
            key: 'kc_sandi_kota_kab',
            permissions: true,
          },
          component: () => import('./views/app/apps/referensi/gabungan/index.vue'),
        },
        {
          path: '/kc_sandi_kualitas',
          meta: {
            title: 'Kc Sandi Kualitas',
            key: 'kc_sandi_kualitas',
            permissions: true,
          },
          component: () => import('./views/app/apps/referensi/gabungan/index.vue'),
        },
        {
          path: '/kc_sandi_kualitas_ayda',
          meta: {
            title: 'Kc Sandi Kualitas Ayda',
            key: 'kc_sandi_kualitas_ayda',
            permissions: true,
          },
          component: () => import('./views/app/apps/referensi/gabungan/index.vue'),
        },
        {
          path: '/kc_sandi_kualitas_pbl',
          meta: {
            title: 'Kc Sandi Kualitas Pbl',
            key: 'kc_sandi_kualitas_pbl',
            permissions: true,
          },
          component: () => import('./views/app/apps/referensi/gabungan/index.vue'),
        },
        {
          path: '/kc_sandi_metode_aidp',
          meta: {
            title: 'Kc Sandi Metode Aidp',
            key: 'kc_sandi_metode_aidp',
            permissions: true,
          },
          component: () => import('./views/app/apps/referensi/gabungan/index.vue'),
        },
        {
          path: '/kc_sandi_metode_bagi_hasil',
          meta: {
            title: 'Kc Sandi Metode Bagi Hasil',
            key: 'kc_sandi_metode_bagi_hasil',
            permissions: true,
          },
          component: () => import('./views/app/apps/referensi/gabungan/index.vue'),
        },
        {
          path: '/kc_sandi_metode_penyusutan_pembiayaan_sewa',
          meta: {
            title: 'Kc Sandi Metode Penyusutan Pembiayaan Sewa',
            key: 'kc_sandi_metode_penyusutan_pembiayaan_sewa',
            permissions: true,
          },
          component: () => import('./views/app/apps/referensi/gabungan/index.vue'),
        },
        {
          path: '/kc_sandi_periode_pembayaran_angsuran',
          meta: {
            title: 'Kc Sandi Periode Pembayaran Angsuran',
            key: 'kc_sandi_periode_pembayaran_angsuran',
            permissions: true,
          },
          component: () => import('./views/app/apps/referensi/gabungan/index.vue'),
        },
        {
          path: '/kc_sandi_periode_pembayaran_angsuran_bagi_hasil',
          meta: {
            title: 'Kc Sandi Periode Pembayaran Angsuran Bagi Hasil',
            key: 'kc_sandi_periode_pembayaran_angsuran_bagi_hasil',
            permissions: true,
          },
          component: () => import('./views/app/apps/referensi/gabungan/index.vue'),
        },
        {
          path: '/kc_sandi_periode_pembayaran_angsuran_pokok',
          meta: {
            title: 'Kc Sandi Periode Pembayaran Angsuran Pokok',
            key: 'kc_sandi_periode_pembayaran_angsuran_pokok',
            permissions: true,
          },
          component: () => import('./views/app/apps/referensi/gabungan/index.vue'),
        },
        {
          path: '/kc_sandi_periode_pembayaran_sewa',
          meta: {
            title: 'Kc Sandi Periode Pembayaran Sewa',
            key: 'kc_sandi_periode_pembayaran_sewa',
            permissions: true,
          },
          component: () => import('./views/app/apps/referensi/gabungan/index.vue'),
        },
        {
          path: '/kc_sandi_sektor_ekonomi',
          meta: {
            title: 'Kc Sandi Sektor Ekonomi',
            key: 'kc_sandi_sektor_ekonomi',
            permissions: true,
          },
          component: () => import('./views/app/apps/referensi/gabungan/index.vue'),
        },
        {
          path: '/kc_sandi_sektor_ekonomi_penerusan_dana_terikat',
          meta: {
            title: 'Kc Sandi Sektor Ekonomi Penerusan Dana Terikat',
            key: 'kc_sandi_sektor_ekonomi_penerusan_dana_terikat',
            permissions: true,
          },
          component: () => import('./views/app/apps/referensi/gabungan/index.vue'),
        },
        {
          path: '/kc_sandi_sifat_dana',
          meta: {
            title: 'Kc Sandi Sifat Dana',
            key: 'kc_sandi_sifat_dana',
            permissions: true,
          },
          component: () => import('./views/app/apps/referensi/gabungan/index.vue'),
        },
        {
          path: '/kc_sandi_sifat_investasi',
          meta: {
            title: 'Kc Sandi Sifat Investasi',
            key: 'kc_sandi_sifat_investasi',
            permissions: true,
          },
          component: () => import('./views/app/apps/referensi/gabungan/index.vue'),
        },
        {
          path: '/kc_sandi_sifat_pembiayaan',
          meta: {
            title: 'Kc Sandi Sifat Pembiayaan',
            key: 'kc_sandi_sifat_pembiayaan',
            permissions: true,
          },
          component: () => import('./views/app/apps/referensi/gabungan/index.vue'),
        },
        {
          path: '/kc_sandi_sifat_pembiayaan_ppap',
          meta: {
            title: 'Kc Sandi Sifat Pembiayaan Ppap',
            key: 'kc_sandi_sifat_pembiayaan_ppap',
            permissions: true,
          },
          component: () => import('./views/app/apps/referensi/gabungan/index.vue'),
        },
        {
          path: '/kc_sandi_sifat_piutang',
          meta: {
            title: 'Kc Sandi Sifat Piutang',
            key: 'kc_sandi_sifat_piutang',
            permissions: true,
          },
          component: () => import('./views/app/apps/referensi/gabungan/index.vue'),
        },
        {
          path: '/kc_sandi_status_aset_tetap_inventaris',
          meta: {
            title: 'Kc Sandi Status Aset Tetap Inventaris',
            key: 'kc_sandi_status_aset_tetap_inventaris',
            permissions: true,
          },
          component: () => import('./views/app/apps/referensi/gabungan/index.vue'),
        },
        {
          path: '/kc_sandi_status_bmpd',
          meta: {
            title: 'Kc Sandi Status Bmpd',
            key: 'kc_sandi_status_bmpd',
            permissions: true,
          },
          component: () => import('./views/app/apps/referensi/gabungan/index.vue'),
        },
        {
          path: '/kc_sandi_status_kepemilikan_kantor',
          meta: {
            title: 'Kc Sandi Status Kepemilikan Kantor',
            key: 'kc_sandi_status_kepemilikan_kantor',
            permissions: true,
          },
          component: () => import('./views/app/apps/referensi/gabungan/index.vue'),
        },
        {
          path: '/kc_sandi_status_pembiayaan',
          meta: {
            title: 'Kc Sandi Status Pembiayaan',
            key: 'kc_sandi_status_pembiayaan',
            permissions: true,
          },
          component: () => import('./views/app/apps/referensi/gabungan/index.vue'),
        },
        {
          path: '/kc_sandi_status_piutang',
          meta: {
            title: 'Kc Sandi Status Piutang',
            key: 'kc_sandi_status_piutang',
            permissions: true,
          },
          component: () => import('./views/app/apps/referensi/gabungan/index.vue'),
        },
        {
          path: '/kc_sandi_tujuan_aidp',
          meta: {
            title: 'Kc Sandi Tujuan Aidp',
            key: 'kc_sandi_tujuan_aidp',
            permissions: true,
          },
          component: () => import('./views/app/apps/referensi/gabungan/index.vue'),
        },
        {
          path: '/kc_sandi_valuta',
          meta: {
            title: 'Kc Sandi Valuta',
            key: 'kc_sandi_valuta',
            permissions: true,
          },
          component: () => import('./views/app/apps/referensi/gabungan/index.vue'),
        },
        {
          path: '/kc_sandi_wilayah_bi',
          meta: {
            title: 'Kc Sandi Wilayah Bi',
            key: 'kc_sandi_wilayah_bi',
            permissions: true,
          },
          component: () => import('./views/app/apps/referensi/gabungan/index.vue'),
        },
        {
          path: '/kc_sandi_wilayah_ojk',
          meta: {
            title: 'Kc Sandi Wilayah Ojk',
            key: 'kc_sandi_wilayah_ojk',
            permissions: true,
          },
          component: () => import('./views/app/apps/referensi/gabungan/index.vue'),
        },

        {
          path: '/lbbpr/compare',
          meta: {
            title: 'Compare data',
            permissions: true,
          },
          component: () => import('./views/app/apps/utility/lbbpr/compare.vue'),
        },
        {
          path: '/compare',
          meta: {
            title: 'Compare data',
            permissions: true,
          },
          component: () => import('./views/app/apps/utility/lbbprs/compare.vue'),
        },
        {
          path: '/importbprs',
          meta: {
            title: 'Import All Form',
            permissions: true,
          },
          component: () => import('./views/app/apps/utility/lbbprs/importbprs.vue'),
        },
        {
          path: '/lbbpr/exportzip',
          meta: {
            title: 'Export All Form',
            permissions: true,
          },
          component: () => import('./views/app/apps/utility/lbbpr/exportzip.vue'),
        },
        {
          path: '/lbbpr/initallform',
          meta: {
            title: 'Initial All Form',
            permissions: true,
          },
          component: () => import('./views/app/apps/lbbpr/initallform.vue'),
        },
        {
          path: '/lbbpr/periodechange',
          meta: {
            title: 'Change periode',
            permissions: true,
          },
          component: () => import('./views/app/apps/lbbpr/changeperiode/index.vue'),
        },
        {
          path: '/lbbprs/dashboard',
          meta: {
            title: 'Dashboard',
            // permissions: true,
          },
          component: () => import('./views/app/apps/lbbprs/dashboard.vue'),
        },
        {
          path: '/lbbprs/periodechange',
          meta: {
            title: 'Change periode',
            permissions: true,
          },
          component: () => import('./views/app/apps/lbbprs/changeperiode/index.vue'),
        },
        {
          path: '/lbbprs/initallform',
          meta: {
            title: 'Initial All Form',
            permissions: true,
          },
          component: () => import('./views/app/apps/lbbprs/initallform.vue'),
        },
        {
          path: '/lbbpr/periodechange',
          meta: {
            title: 'Periode Change',
            permissions: true,
          },
          component: () => import('./views/app/apps/lbbpr/settingperiode.vue'),
        },
        {
          path: '/slik/exportall',
          meta: {
            title: 'Export All Slik Form',
            permissions: true,
          },
          component: () => import('./views/app/apps/utility/slik/exportzip.vue'),
        },
        {
          path: '/exportzip',
          meta: {
            title: 'Export All Form',
            permissions: true,
          },
          component: () => import('./views/app/apps/utility/lbbprs/exportzip.vue'),
        },
        {
          path: '/lbbprs/sektorekonomi',
          meta: {
            title: 'Sektor Ekonomi',
            // permissions: true,
          },
          component: () => import('./views/app/apps/lbbprs/sektorekonomi.vue'),
        },
        {
          path: '/gb_form_01_1',
          name: 'form_01_1',
          meta: {
            title: 'Form GB 01.1',
            permissions: true,
          },
          component: () => import('./views/app/apps/lbbprs/grid/gb_form01point1.vue'),
        },
        {
          path: '/gb_form_01_2',
          name: 'form_01_2',
          meta: {
            title: 'Form GB 01.2',
            permissions: true,
          },
          component: () => import('./views/app/apps/lbbprs/grid/gb_form01point2.vue'),
        },
        {
          path: '/gb_form_01_3',
          name: 'form_01_3',
          meta: {
            title: 'Form GB 01.3',
            permissions: true,
          },
          component: () => import('./views/app/apps/lbbprs/grid/gb_form01point3.vue'),
        },
        {
          path: '/gb_form_01_4',
          name: 'form_01_4',
          meta: {
            title: 'Form GB 01.4',
            permissions: true,
          },
          component: () => import('./views/app/apps/lbbprs/grid/gb_form01point4.vue'),
        },
        {
          path: '/gb_form_01_5',
          name: 'form_01_5',
          meta: {
            title: 'Form GB 01.5',
            permissions: true,
          },
          component: () => import('./views/app/apps/lbbprs/grid/gb_form01point5.vue'),
        },
        {
          path: '/gb_form_01_6',
          name: 'form_01_6',
          meta: {
            title: 'Form GB 01.6',
            // permissions: true,
          },
          component: () => import('./views/app/apps/lbbprs/grid/gb_form01point6.vue'),
        },
        {
          path: '/gb_form_01_7',
          name: 'form_01_7',
          meta: {
            title: 'Form GB 01.7',
            // permissions: true,
          },
          component: () => import('./views/app/apps/lbbprs/grid/gb_form01point7.vue'),
        },
        {
          path: '/gb_form_01_8',
          name: 'form_01_8',
          meta: {
            title: 'Form GB 01.8',
            // permissions: true,
          },
          component: () => import('./views/app/apps/lbbprs/grid/gb_form01point8.vue'),
        },
        {
          path: '/gb_form_01_9',
          name: 'form_01_9',
          meta: {
            title: 'Form GB 01.9',
            // permissions: true,
          },
          component: () => import('./views/app/apps/lbbprs/grid/gb_form01point9.vue'),
        },
        {
          path: '/gb_form_01_10',
          name: 'form_01_10',
          meta: {
            title: 'Form GB 01.10',
            // permissions: true,
          },
          component: () => import('./views/app/apps/lbbprs/grid/gb_form01point10.vue'),
        },
        {
          path: '/gb_form_01_11',
          name: 'form_01_11',
          meta: {
            title: 'Form GB 01.11',
            // permissions: true,
          },
          component: () => import('./views/app/apps/lbbprs/grid/gb_form01point11.vue'),
        },
        {
          path: '/gb_form_01_12',
          name: 'form_01_12',
          meta: {
            title: 'Form GB 01.12',
            // permissions: true,
          },
          component: () => import('./views/app/apps/lbbprs/grid/gb_form01point12.vue'),
        },
        {
          path: '/gb_form_02',
          name: 'gb_form_02',
          meta: {
            title: 'Form GB 02',
            permissions: true,
          },
          component: () => import('./views/app/apps/lbbprs/grid/gb_form0200.vue'),
        },
        {
          path: '/gb_form_03',
          name: 'gb_form_03',
          meta: {
            title: 'Form GB 03',
            permissions: true,
          },
          component: () => import('./views/app/apps/lbbprs/grid/gb_form0300.vue'),
        },
        {
          path: '/gb_form_04',
          name: 'gb_form_04',
          meta: {
            title: 'Form GB 04',
            permissions: true,
          },
          component: () => import('./views/app/apps/lbbprs/grid/gb_form0400.vue'),
        },
        {
          path: '/gb_form_05',
          name: 'gb_form_05',
          meta: {
            title: 'Form GB 05',
            permissions: true,
          },
          component: () => import('./views/app/apps/lbbprs/grid/gb_form0500.vue'),
        },
        {
          path: '/gb_form_06',
          name: 'gb_form_06',
          meta: {
            title: 'Form GB 06',
            permissions: true,
          },
          component: () => import('./views/app/apps/lbbprs/grid/gb_form0600.vue'),
        },
        {
          path: '/gb_form_07',
          name: 'gb_form_07',
          meta: {
            title: 'Form GB 07',
            permissions: true,
          },
          component: () => import('./views/app/apps/lbbprs/grid/gb_form0700.vue'),
        },
        {
          path: '/gb_form_08',
          name: 'gb_form_08',
          meta: {
            title: 'Form GB 08',
            permissions: true,
          },
          component: () => import('./views/app/apps/lbbprs/grid/gb_form0800.vue'),
        },
        {
          path: '/gb_form_09',
          name: 'gb_form_09',
          meta: {
            title: 'Form GB 09',
            permissions: true,
          },
          component: () => import('./views/app/apps/lbbprs/grid/gb_form0900.vue'),
        },
        {
          path: '/gb_form_10',
          name: 'gb_form_10',
          meta: {
            title: 'Form GB 10',
            permissions: true,
          },
          component: () => import('./views/app/apps/lbbprs/grid/gb_form1000.vue'),
        },
        {
          path: '/gb_form_11',
          name: 'gb_form_11',
          meta: {
            title: 'Form GB 11',
            permissions: true,
          },
          component: () => import('./views/app/apps/lbbprs/grid/gb_form1100.vue'),
        },
        {
          path: '/form_01',
          name: 'form_01',
          meta: {
            title: 'Form KC 0100',
            permissions: true,
          },
          component: () => import('./views/app/apps/lbbprs/grid/kc_form0100.vue'),
        },
        {
          path: '/form_01_01',
          name: 'form_01.01',
          meta: {
            title: 'Form KC 0101',
            permissions: true,
          },
          component: () => import('./views/app/apps/lbbprs/grid/kc_form0101.vue'),
        },
        {
          path: '/form_01_02',
          name: 'form_01.02',
          meta: {
            title: 'Form KC 0102',
            permissions: true,
          },
          component: () => import('./views/app/apps/lbbprs/grid/kc_form0102.vue'),
        },
        {
          path: '/form_02',
          name: 'form_02',
          meta: {
            title: 'Form KC 0200',
            permissions: true,
          },
          component: () => import('./views/app/apps/lbbprs/grid/kc_form0200.vue'),
        },
        {
          path: '/form_03',
          name: 'form_03',
          meta: {
            title: 'Form KC 0300',
            permissions: true,
          },
          component: () => import('./views/app/apps/lbbprs/grid/kc_form0300.vue'),
        },
        {
          path: '/form_04',
          name: 'form_04',
          meta: {
            title: 'Form KC 0400',
            permissions: true,
          },
          component: () => import('./views/app/apps/lbbprs/grid/kc_form0400.vue'),
        },
        {
          path: '/form_05',
          name: 'form_05',
          meta: {
            title: 'Form KC 0500',
            permissions: true,
          },
          component: () => import('./views/app/apps/lbbprs/grid/kc_form0500.vue'),
        },
        {
          path: '/form_06',
          name: 'form_06',
          meta: {
            title: 'Form KC 0600',
            permissions: true,
          },
          component: () => import('./views/app/apps/lbbprs/grid/kc_form0600.vue'),
        },
        {
          path: '/form_07',
          name: 'form_07',
          meta: {
            title: 'Form KC 0700',
            permissions: true,
          },
          component: () => import('./views/app/apps/lbbprs/grid/kc_form0700.vue'),
        },
        {
          path: '/form_08',
          name: 'form_08',
          meta: {
            title: 'Form KC 0800',
            permissions: true,
          },
          component: () => import('./views/app/apps/lbbprs/grid/kc_form0800.vue'),
        },
        {
          path: '/form_09',
          name: 'form_09',
          meta: {
            title: 'Form KC 0900',
            permissions: true,
          },
          component: () => import('./views/app/apps/lbbprs/grid/kc_form0900.vue'),
        }, {
          path: '/form_10',
          name: 'form_10',
          meta: {
            title: 'Form KC 1000',
            permissions: true,
          },
          component: () => import('./views/app/apps/lbbprs/grid/kc_form1000.vue'),
        },
        {
          path: '/form_11',
          name: 'form_11',
          meta: {
            title: 'Form KC 1100',
            permissions: true,
          },
          component: () => import('./views/app/apps/lbbprs/grid/kc_form1100.vue'),
        },
        {
          path: '/form_12',
          name: 'form_12',
          meta: {
            title: 'Form KC 1200',
            permissions: true,
          },
          component: () => import('./views/app/apps/lbbprs/grid/kc_form1200.vue'),
        },
        {
          path: '/form_13',
          name: 'form_13',
          meta: {
            title: 'Form KC 1300',
            permissions: true,
          },
          component: () => import('./views/app/apps/lbbprs/grid/kc_form1300.vue'),
        },
        {
          path: '/form_14',
          name: 'form_14',
          meta: {
            title: 'Form KC 1400',
            permissions: true,
          },
          component: () => import('./views/app/apps/lbbprs/grid/kc_form1400.vue'),
        },
        {
          path: '/form_15',
          name: 'form_15',
          meta: {
            title: 'Form KC 1500',
            permissions: true,
          },
          component: () => import('./views/app/apps/lbbprs/grid/kc_form1500.vue'),
        },
        {
          path: '/form_16',
          name: 'form_16',
          meta: {
            title: 'Form KC 1600',
            permissions: true,
          },
          component: () => import('./views/app/apps/lbbprs/grid/kc_form1600.vue'),
        },
        {
          path: '/form_17',
          name: 'form_17',
          meta: {
            title: 'Form KC 1700',
            permissions: true,
          },
          component: () => import('./views/app/apps/lbbprs/grid/kc_form1700.vue'),
        },
        {
          path: '/form_18',
          name: 'form_18',
          meta: {
            title: 'Form KC 1800',
            permissions: true,
          },
          component: () => import('./views/app/apps/lbbprs/grid/kc_form1800.vue'),
        },
        {
          path: '/form_19',
          name: 'form_19',
          meta: {
            title: 'Form KC 1900',
            permissions: true,
          },
          component: () => import('./views/app/apps/lbbprs/grid/kc_form1900.vue'),
        },
        {
          path: '/form_20',
          name: 'form_20',
          meta: {
            title: 'Form KC 2000',
            permissions: true,
          },
          component: () => import('./views/app/apps/lbbprs/grid/kc_form2000.vue'),
        },
        {
          path: '/form_21',
          name: 'form_21',
          meta: {
            title: 'Form KC 2100',
            permissions: true,
          },
          component: () => import('./views/app/apps/lbbprs/grid/kc_form2100.vue'),
        },
        {
          path: '/form_22',
          name: 'form_22',
          meta: {
            title: 'Form KC 2200',
            permissions: true,
          },
          component: () => import('./views/app/apps/lbbprs/grid/kc_form2200.vue'),
        },
        {
          path: '/form_23',
          name: 'form_23',
          meta: {
            title: 'Form KC 2300',
            permissions: true,
          },
          component: () => import('./views/app/apps/lbbprs/grid/kc_form2300.vue'),
        },
        {
          path: '/form_24',
          name: 'form_24',
          meta: {
            title: 'Form KC 2400',
            permissions: true,
          },
          component: () => import('./views/app/apps/lbbprs/grid/kc_form2400.vue'),
        },
        {
          path: '/form_25',
          name: 'form_25',
          meta: {
            title: 'Form KC 2500',
            permissions: true,
          },
          component: () => import('./views/app/apps/lbbprs/grid/kc_form2500.vue'),
        },
        {
          path: '/form_26',
          name: 'form_26',
          meta: {
            title: 'Form KC 2600',
            permissions: true,
          },
          component: () => import('./views/app/apps/lbbprs/grid/kc_form2600.vue'),
        },
        {
          path: '/form_27',
          name: 'form_27',
          meta: {
            title: 'Form KC 2700',
            permissions: true,
          },
          component: () => import('./views/app/apps/lbbprs/grid/kc_form2700.vue'),
        },
        {
          path: '/form_28',
          name: 'form_28',
          meta: {
            title: 'Form KC 2800',
            permissions: true,
          },
          component: () => import('./views/app/apps/lbbprs/grid/kc_form2800.vue'),
        },
        {
          path: '/form_29',
          name: 'form_29',
          meta: {
            title: 'Form KC 2900',
            permissions: true,
          },
          component: () => import('./views/app/apps/lbbprs/grid/kc_form2900.vue'),
        },
        {
          path: '/form_30',
          name: 'form_30',
          meta: {
            title: 'Form KC 3000',
            permissions: true,
          },
          component: () => import('./views/app/apps/lbbprs/grid/kc_form3000.vue'),
        },
        {
          path: '/form_31',
          name: 'form_31',
          meta: {
            title: 'Form KC 3100',
            permissions: true,
          },
          component: () => import('./views/app/apps/lbbprs/grid/kc_form3100.vue'),
        },
        {
          path: '/form_32',
          name: 'form_32',
          meta: {
            title: 'Form KC 3200',
            permissions: true,
          },
          component: () => import('./views/app/apps/lbbprs/grid/kc_form3200.vue'),
        },
        {
          path: '/form_33',
          name: 'form_33',
          meta: {
            title: 'Form KC 3300',
            permissions: true,
          },
          component: () => import('./views/app/apps/lbbprs/grid/kc_form3300.vue'),
        },
        {
          path: '/form_34',
          name: 'form_34',
          meta: {
            title: 'Form KC 3400',
            permissions: true,
          },
          component: () => import('./views/app/apps/lbbprs/grid/kc_form3400.vue'),
        },
        {
          path: '/form_35',
          name: 'form_35',
          meta: {
            title: 'Form KC 3500',
            permissions: true,
          },
          component: () => import('./views/app/apps/lbbprs/grid/kc_form3500.vue'),
        },
        {
          path: '/form_36',
          name: 'form_36',
          meta: {
            title: 'Form KC 3600',
            permissions: true,
          },
          component: () => import('./views/app/apps/lbbprs/grid/kc_form3600.vue'),
        },
        {
          path: '/form_37',
          name: 'form_37',
          meta: {
            title: 'Form KC 3700',
            permissions: true,
          },
          component: () => import('./views/app/apps/lbbprs/grid/kc_form3700.vue'),
        },
        {
          path: '/form_38',
          name: 'form_38',
          meta: {
            title: 'Form KC 3800',
            permissions: true,
          },
          component: () => import('./views/app/apps/lbbprs/grid/kc_form3800.vue'),
        },
        {
          path: '/form_39',
          name: 'form_39',
          meta: {
            title: 'Form KC 3900',
            permissions: true,
          },
          component: () => import('./views/app/apps/lbbprs/grid/kc_form3900.vue'),
        },
        {
          path: '/form_40',
          name: 'form_40',
          meta: {
            title: 'Form KC 4000',
            permissions: true,
          },
          component: () => import('./views/app/apps/lbbprs/grid/kc_form4000.vue'),
        },
        {
          path: '/form_41',
          name: 'form_41',
          meta: {
            title: 'Form KC 4100',
            permissions: true,
          },
          component: () => import('./views/app/apps/lbbprs/grid/kc_form4100.vue'),
        },
        {
          path: '/form_42',
          name: 'form_42',
          meta: {
            title: 'Form KC 4200',
            permissions: true,
          },
          component: () => import('./views/app/apps/lbbprs/grid/kc_form4200.vue'),
        },

        // Start route for LBBPR
        {
          path: '/lbbpr/ckpn/netflow-rollrates',
          name: 'lbbpr-master-ckpn-netflow-rollrates',
          meta: {
            title: 'CKPN Net Flow / Roll Rates',
            // permissions: true,
          },
          component: () => import('./views/app/apps/lbbpr/ckpnnetflow.vue'),
        },
        {
          path: '/lbbpr/dashboard',
          name: 'lbpr-dashboard',
          meta: {
            title: 'Dashboard',
            // permissions: true,
          },
          component: () => import('./views/app/apps/lbbpr/dashboard.vue'),
        },
        {
          path: '/lbbpr/form00s',
          name: 'lbpr-form_00s',
          meta: {
            title: 'Form 00s',
            permissions: true,
          },
          component: () => import('./views/app/apps/lbbpr/form_0000.vue'),
        },
        {
          path: '/lbbpr/form001s',
          name: 'lbpr-form_001s',
          meta: {
            title: 'Form 001s',
            permissions: true,
          },
          component: () => import('./views/app/apps/lbbpr/form_0001.vue'),
        },
        {
          path: '/lbbpr/form002s',
          name: 'lbpr-form_002s',
          meta: {
            title: 'Form 002s',
            permissions: true,
          },
          component: () => import('./views/app/apps/lbbpr/form_0002.vue'),
        },
        {
          path: '/lbbpr/form003s',
          name: 'lbpr-form_003s',
          meta: {
            title: 'Form 003s',
            permissions: true,
          },
          component: () => import('./views/app/apps/lbbpr/form_0003.vue'),
        },
        {
          path: '/lbbpr/form004s',
          name: 'lbpr-form_004s',
          meta: {
            title: 'Form 004s',
            permissions: true,
          },
          component: () => import('./views/app/apps/lbbpr/form_0004.vue'),
        },
        {
          path: '/lbbpr/form005s',
          name: 'lbpr-form_005s',
          meta: {
            title: 'Form 005s',
            permissions: true,
          },
          component: () => import('./views/app/apps/lbbpr/form_0005.vue'),
        },
        {
          path: '/lbbpr/form006s',
          name: 'lbpr-form_006s',
          meta: {
            title: 'Form 006s',
            permissions: true,
          },
          component: () => import('./views/app/apps/lbbpr/form_0006.vue'),
        },
        {
          path: '/lbbpr/form007s',
          name: 'lbpr-form_007s',
          meta: {
            title: 'Form 007s',
            permissions: true,
          },
          component: () => import('./views/app/apps/lbbpr/form_0007.vue'),
        },
        {
          path: '/lbbpr/form008s',
          name: 'lbpr-form_008s',
          meta: {
            title: 'Form 008s',
            permissions: true,
          },
          component: () => import('./views/app/apps/lbbpr/form_0008.vue'),
        },
        {
          path: '/lbbpr/form009s',
          name: 'lbpr-form_009s',
          meta: {
            title: 'Form 009s',
            permissions: true,
          },
          component: () => import('./views/app/apps/lbbpr/form_0009.vue'),
        },
        {
          path: '/lbbpr/form0010s',
          name: 'lbpr-form_0010s',
          meta: {
            title: 'Form 0010s',
            permissions: true,
          },
          component: () => import('./views/app/apps/lbbpr/form_0010.vue'),
        },
        {
          path: '/lbbpr/form0011s',
          name: 'lbpr-form_0011s',
          meta: {
            title: 'Form 0011s',
            permissions: true,
          },
          component: () => import('./views/app/apps/lbbpr/form_0011.vue'),
        },
        {
          path: '/lbbpr/form0012s',
          name: 'lbpr-form_0012s',
          meta: {
            title: 'Form 0012s',
            permissions: true,
          },
          component: () => import('./views/app/apps/lbbpr/form_0012.vue'),
        },
        {
          path: '/lbbpr/form0013s',
          name: 'lbpr-form_0013s',
          meta: {
            title: 'Form 0013s',
            permissions: true,
          },
          component: () => import('./views/app/apps/lbbpr/form_0013.vue'),
        },
        {
          path: '/lbbpr/form0014s',
          name: 'lbpr-form_0014s',
          meta: {
            title: 'Form 0014s',
            // permissions: true,
          },
          component: () => import('./views/app/apps/lbbpr/form_0014.vue'),
        },
        {
          path: '/lbbpr/form0015s',
          name: 'lbpr-form_0015s',
          meta: {
            title: 'Form 0015s',
            // permissions: true,
          },
          component: () => import('./views/app/apps/lbbpr/form_0015.vue'),
        },
        {
          path: '/lbbpr/form0016s',
          name: 'lbpr-form_0016s',
          meta: {
            title: 'Form 0016s',
            // permissions: true,
          },
          component: () => import('./views/app/apps/lbbpr/form_0016.vue'),
        },
        {
          path: '/lbbpr/form0017s',
          name: 'lbpr-form_0017s',
          meta: {
            title: 'Form 0017s',
            // permissions: true,
          },
          component: () => import('./views/app/apps/lbbpr/form_0017.vue'),
        },
        {
          path: '/lbbpr/form0018s',
          name: 'lbpr-form_0018s',
          meta: {
            title: 'Form 0018s',
            // permissions: true,
          },
          component: () => import('./views/app/apps/lbbpr/form_0018.vue'),
        },
        {
          path: '/lbbpr/form01s',
          name: 'lbpr-form_01s',
          meta: {
            title: 'Form 01s',
            permissions: true,
          },
          component: () => import('./views/app/apps/lbbpr/form_0100.vue'),
        },
        {
          path: '/lbbpr/form011s',
          name: 'lbpr-form_011s',
          meta: {
            title: 'Form 011s',
            permissions: true,
          },
          component: () => import('./views/app/apps/lbbpr/form_0101.vue'),
        },
        {
          path: '/lbbpr/form02s',
          name: 'lbpr-form_02s',
          meta: {
            title: 'Form 02s',
            permissions: true,
          },
          component: () => import('./views/app/apps/lbbpr/form_0200.vue'),
        },
        {
          path: '/lbbpr/form03s',
          name: 'lbpr-form_03s',
          meta: {
            title: 'Form 03s',
            permissions: true,
          },
          component: () => import('./views/app/apps/lbbpr/form_0300.vue'),
        },
        {
          path: '/lbbpr/form04s',
          name: 'lbpr-form_04s',
          meta: {
            title: 'Form 04s',
            permissions: true,
          },
          component: () => import('./views/app/apps/lbbpr/form_0400.vue'),
        },
        {
          path: '/lbbpr/form05s',
          name: 'lbpr-form_05s',
          meta: {
            title: 'Form 05s',
            permissions: true,
          },
          component: () => import('./views/app/apps/lbbpr/form_0500.vue'),
        },
        {
          path: '/lbbpr/form06s',
          name: 'lbpr-form_06s',
          meta: {
            title: 'Form 06s',
            permissions: true,
          },
          component: () => import('./views/app/apps/lbbpr/form_0600.vue'),
        },
        {
          path: '/lbbpr/form061s',
          name: 'lbpr-form_061s',
          meta: {
            title: 'Form 061s',
            permissions: true,
          },
          component: () => import('./views/app/apps/lbbpr/form_0601.vue'),
        },
        {
          path: '/lbbpr/form062s',
          name: 'lbpr-form_062s',
          meta: {
            title: 'Form 062s',
            // permissions: true,
          },
          component: () => import('./views/app/apps/lbbpr/form_0602.vue'),
        },
        {
          path: '/lbbpr/form06C',
          name: 'lbpr-form_06C',
          meta: {
            title: 'Form 06c',
            // permissions: true,
          },
          component: () => import('./views/app/apps/lbbpr/form06c.vue'),
        },
        {
          path: '/lbbpr/form07s',
          name: 'lbpr-form_07s',
          meta: {
            title: 'Form 07s',
            permissions: true,
          },
          component: () => import('./views/app/apps/lbbpr/form_0700.vue'),
        },
        {
          path: '/lbbpr/form08s',
          name: 'lbpr-form_08s',
          meta: {
            title: 'Form 08s',
            permissions: true,
          },
          component: () => import('./views/app/apps/lbbpr/form_0800.vue'),
        },
        {
          path: '/lbbpr/form09s',
          name: 'lbpr-form_09s',
          meta: {
            title: 'Form 09s',
            permissions: true,
          },
          component: () => import('./views/app/apps/lbbpr/form_0900.vue'),
        },
        {
          path: '/lbbpr/form091s',
          name: 'lbpr-form_091s',
          meta: {
            title: 'Form 091s',
            permissions: true,
          },
          component: () => import('./views/app/apps/lbbpr/form_0901.vue'),
        },
        {
          path: '/lbbpr/form10s',
          name: 'lbpr-form_10s',
          meta: {
            title: 'Form 10s',
            permissions: true,
          },
          component: () => import('./views/app/apps/lbbpr/form_1000.vue'),
        },
        {
          path: '/lbbpr/form11s',
          name: 'lbpr-form_11s',
          meta: {
            title: 'Form 11s',
            permissions: true,
          },
          component: () => import('./views/app/apps/lbbpr/form_1100.vue'),
        },
        {
          path: '/lbbpr/form12s',
          name: 'lbpr-form_12s',
          meta: {
            title: 'Form 12s',
            permissions: true,
          },
          component: () => import('./views/app/apps/lbbpr/form_1200.vue'),
        },
        {
          path: '/lbbpr/form13s',
          name: 'lbpr-form_13s',
          meta: {
            title: 'Form 13s',
            permissions: true,
          },
          component: () => import('./views/app/apps/lbbpr/form_1300.vue'),
        },
        {
          path: '/lbbpr/form14s',
          name: 'lbpr-form_14s',
          meta: {
            title: 'Form 14s',
            permissions: true,
          },
          component: () => import('./views/app/apps/lbbpr/form_1400.vue'),
        },
        {
          path: '/lbbpr/form141s',
          name: 'lbpr-form_141s',
          meta: {
            title: 'Form 141s',
            permissions: true,
          },
          component: () => import('./views/app/apps/lbbpr/form_1401.vue'),
        },
        {
          path: '/lbbpr/form15s',
          name: 'lbpr-form_15s',
          meta: {
            title: 'Form 15s',
            permissions: true,
          },
          component: () => import('./views/app/apps/lbbpr/form_1500.vue'),
        },
        {
          path: '/lbbpr/form16s',
          name: 'lbpr-form_16s',
          meta: {
            title: 'Form 16s',
            // permissions: true,
          },
          component: () => import('./views/app/apps/lbbpr/form_1600.vue'),
        },
        {
          path: '/lbbpr/form17s',
          name: 'lbpr-form_17s',
          meta: {
            title: 'Form 17s',
            // permissions: true,
          },
          component: () => import('./views/app/apps/lbbpr/form_1700.vue'),
        },
        {
          path: '/lbbpr/form18s',
          name: 'lbpr-form_18s',
          meta: {
            title: 'Form 18s',
            // permissions: true,
          },
          component: () => import('./views/app/apps/lbbpr/form_1800.vue'),
        },
        {
          path: '/lbbpr/form19s',
          name: 'lbpr-form_19s',
          meta: {
            title: 'Form 19s',
            // permissions: true,
          },
          component: () => import('./views/app/apps/lbbpr/form_1900.vue'),
        },
        {
          path: '/lbbpr/alasan_diblokirs',
          meta: {
            key: 'alasan_diblokirs',
            permissions: true, // <== Permissions ref lbbpr
          },
          component: () => import('./views/app/apps/referensi/lbbpr/index.vue'),
        },
        {
          path: '/lbbpr/aset_tetap_inventaris_tdkberwujuds',
          meta: {
            key: 'aset_tetap_inventaris_tdkberwujuds',
            permissions: true, // <== Permissions ref lbbpr
          },
          component: () => import('./views/app/apps/referensi/lbbpr/index.vue'),
        },
        {
          path: '/lbbpr/banks',
          meta: {
            key: 'banks',
            permissions: true, // <== Permissions ref lbbpr
          },
          component: () => import('./views/app/apps/referensi/lbbpr/index.vue'),
        },
        {
          path: '/lbbpr/branches',
          meta: {
            key: 'branches',
            permissions: true, // <== Permissions ref lbbpr
          },
          component: () => import('./views/app/apps/referensi/lbbpr/index.vue'),
        },
        {
          path: '/lbbpr/cara_perhitungan_suku_bungas',
          meta: {
            key: 'cara_perhitungan_suku_bungas',
            permissions: true, // <== Permissions ref lbbpr
          },
          component: () => import('./views/app/apps/referensi/lbbpr/index.vue'),
        },
        {
          path: '/lbbpr/cb_form05s',
          meta: {
            key: 'cb_form05s',
            permissions: true, // <== Permissions ref lbbpr
          },
          component: () => import('./views/app/apps/referensi/lbbpr/index.vue'),
        },
        {
          path: '/lbbpr/cb_form13s',
          meta: {
            key: 'cb_form13s',
            permissions: true, // <== Permissions ref lbbpr
          },
          component: () => import('./views/app/apps/referensi/lbbpr/index.vue'),
        },
        {
          path: '/lbbpr/cb_form15s',
          meta: {
            key: 'cb_form15s',
            permissions: true, // <== Permissions ref lbbpr
          },
          component: () => import('./views/app/apps/referensi/lbbpr/index.vue'),
        },
        {
          path: '/lbbpr/check_valid',
          meta: {
            key: 'check_valid',
            permissions: true, // <== Permissions ref lbbpr
          },
          component: () => import('./views/app/apps/referensi/lbbpr/index.vue'),
        },
        {
          path: '/lbbpr/daftar_pemilihs',
          meta: {
            key: 'daftar_pemilihs',
            permissions: true, // <== Permissions ref lbbpr
          },
          component: () => import('./views/app/apps/referensi/lbbpr/index.vue'),
        },
        {
          path: '/lbbpr/headers',
          meta: {
            key: 'headers',
            permissions: true, // <== Permissions ref lbbpr
          },
          component: () => import('./views/app/apps/referensi/lbbpr/index.vue'),
        },
        {
          path: '/lbbpr/hubdgn_bank_kredits',
          meta: {
            key: 'hubdgn_bank_kredits',
            permissions: true, // <== Permissions ref lbbpr
          },
          component: () => import('./views/app/apps/referensi/lbbpr/index.vue'),
        },
        {
          path: '/lbbpr/hubungan_pihak_terkaits',
          meta: {
            key: 'hubungan_pihak_terkaits',
            permissions: true, // <== Permissions ref lbbpr
          },
          component: () => import('./views/app/apps/referensi/lbbpr/index.vue'),
        },
        {
          path: '/lbbpr/hubungandgn_banks',
          meta: {
            key: 'hubungandgn_banks',
            permissions: true, // <== Permissions ref lbbpr
          },
          component: () => import('./views/app/apps/referensi/lbbpr/index.vue'),
        },
        {
          path: '/lbbpr/jenis_agunan_diambil_alihs',
          meta: {
            key: 'jenis_agunan_diambil_alihs',
            permissions: true, // <== Permissions ref lbbpr
          },
          component: () => import('./views/app/apps/referensi/lbbpr/index.vue'),
        },
        {
          path: '/lbbpr/jenis_agunans',
          meta: {
            key: 'jenis_agunans',
            permissions: true, // <== Permissions ref lbbpr
          },
          component: () => import('./views/app/apps/referensi/lbbpr/index.vue'),
        },
        {
          path: '/lbbpr/jenis_asetyang_dihapusbukukans',
          meta: {
            key: 'jenis_asetyang_dihapusbukukans',
            permissions: true, // <== Permissions ref lbbpr
          },
          component: () => import('./views/app/apps/referensi/lbbpr/index.vue'),
        },
        {
          path: '/lbbpr/jenis_modals',
          meta: {
            key: 'jenis_modals',
            permissions: true, // <== Permissions ref lbbpr
          },
          component: () => import('./views/app/apps/referensi/lbbpr/index.vue'),
        },
        {
          path: '/lbbpr/jenis_pemiliks',
          meta: {
            key: 'jenis_pemiliks',
            permissions: true, // <== Permissions ref lbbpr
          },
          component: () => import('./views/app/apps/referensi/lbbpr/index.vue'),
        },
        {
          path: '/lbbpr/jenis_penempatanpada_bank_lains',
          meta: {
            key: 'jenis_penempatanpada_bank_lains',
            permissions: true, // <== Permissions ref lbbpr
          },
          component: () => import('./views/app/apps/referensi/lbbpr/index.vue'),
        },
        {
          path: '/lbbpr/jenis_penggunaans',
          meta: {
            key: 'jenis_penggunaans',
            permissions: true, // <== Permissions ref lbbpr
          },
          component: () => import('./views/app/apps/referensi/lbbpr/index.vue'),
        },
        {
          path: '/lbbpr/jenis_pihak_terkaits',
          meta: {
            key: 'jenis_pihak_terkaits',
            permissions: true, // <== Permissions ref lbbpr
          },
          component: () => import('./views/app/apps/referensi/lbbpr/index.vue'),
        },
        {
          path: '/lbbpr/jenis_pinjamen',
          meta: {
            key: 'jenis_pinjamen',
            permissions: true, // <== Permissions ref lbbpr
          },
          component: () => import('./views/app/apps/referensi/lbbpr/index.vue'),
        },
        {
          path: '/lbbpr/jenis_setoran_modals',
          meta: {
            key: 'jenis_setoran_modals',
            permissions: true, // <== Permissions ref lbbpr
          },
          component: () => import('./views/app/apps/referensi/lbbpr/index.vue'),
        },
        {
          path: '/lbbpr/jenis_simpanandri_bank_lains',
          meta: {
            key: 'jenis_simpanandri_bank_lains',
            permissions: true, // <== Permissions ref lbbpr
          },
          component: () => import('./views/app/apps/referensi/lbbpr/index.vue'),
        },
        {
          path: '/lbbpr/jenis_tabungans',
          meta: {
            key: 'jenis_tabungans',
            permissions: true, // <== Permissions ref lbbpr
          },
          component: () => import('./views/app/apps/referensi/lbbpr/index.vue'),
        },
        {
          path: '/lbbpr/jenis_usahas',
          meta: {
            key: 'jenis_usahas',
            permissions: true, // <== Permissions ref lbbpr
          },
          component: () => import('./views/app/apps/referensi/lbbpr/index.vue'),
        },
        {
          path: '/lbbpr/keanggotaan_komites',
          meta: {
            key: 'keanggotaan_komites',
            permissions: true, // <== Permissions ref lbbpr
          },
          component: () => import('./views/app/apps/referensi/lbbpr/index.vue'),
        },
        {
          path: '/lbbpr/klasifikasi_surat_berhargas',
          meta: {
            key: 'klasifikasi_surat_berhargas',
            permissions: true, // <== Permissions ref lbbpr
          },
          component: () => import('./views/app/apps/referensi/lbbpr/index.vue'),
        },
        {
          path: '/lbbpr/kualitas',
          meta: {
            key: 'kualitas',
            permissions: true, // <== Permissions ref lbbpr
          },
          component: () => import('./views/app/apps/referensi/lbbpr/index.vue'),
        },
        {
          path: '/lbbpr/kualitas_penempatanpd_bank_lains',
          meta: {
            key: 'kualitas_penempatanpd_bank_lains',
            permissions: true, // <== Permissions ref lbbpr
          },
          component: () => import('./views/app/apps/referensi/lbbpr/index.vue'),
        },
        {
          path: '/lbbpr/laporan_laba_rugis',
          meta: {
            key: 'laporan_laba_rugis',
            permissions: true, // <== Permissions ref lbbpr
          },
          component: () => import('./views/app/apps/referensi/lbbpr/index.vue'),
        },
        {
          path: '/lbbpr/laporan_posisi_keuangans',
          meta: {
            key: 'laporan_posisi_keuangans',
            permissions: true, // <== Permissions ref lbbpr
          },
          component: () => import('./views/app/apps/referensi/lbbpr/index.vue'),
        },
        {
          path: '/lbbpr/laporan_posisi_keuangans_old',
          meta: {
            key: 'laporan_posisi_keuangans_old',
            permissions: true, // <== Permissions ref lbbpr
          },
          component: () => import('./views/app/apps/referensi/lbbpr/index.vue'),
        },
        {
          path: '/lbbpr/neracas',
          meta: {
            key: 'neracas',
            permissions: true, // <== Permissions ref lbbpr
          },
          component: () => import('./views/app/apps/referensi/lbbpr/index.vue'),
        },
        {
          path: '/lbbpr/periode_pembayarans',
          meta: {
            key: 'periode_pembayarans',
            permissions: true, // <== Permissions ref lbbpr
          },
          component: () => import('./views/app/apps/referensi/lbbpr/index.vue'),
        },
        {
          path: '/lbbpr/provisifeb2021',
          meta: {
            key: 'provisifeb2021',
            permissions: true, // <== Permissions ref lbbpr
          },
          component: () => import('./views/app/apps/referensi/lbbpr/index.vue'),
        },
        {
          path: '/lbbpr/pyad_april',
          meta: {
            key: 'pyad_april',
            permissions: true, // <== Permissions ref lbbpr
          },
          component: () => import('./views/app/apps/referensi/lbbpr/index.vue'),
        },
        {
          path: '/lbbpr/pyad_feb',
          meta: {
            key: 'pyad_feb',
            permissions: true, // <== Permissions ref lbbpr
          },
          component: () => import('./views/app/apps/referensi/lbbpr/index.vue'),
        },
        {
          path: '/lbbpr/pyad_mar',
          meta: {
            key: 'pyad_mar',
            permissions: true, // <== Permissions ref lbbpr
          },
          component: () => import('./views/app/apps/referensi/lbbpr/index.vue'),
        },
        {
          path: '/lbbpr/pyad_mei',
          meta: {
            key: 'pyad_mei',
            permissions: true, // <== Permissions ref lbbpr
          },
          component: () => import('./views/app/apps/referensi/lbbpr/index.vue'),
        },
        {
          path: '/lbbpr/sandi_sektor_ekonomis',
          meta: {
            key: 'sandi_sektor_ekonomis',
            permissions: true, // <== Permissions ref lbbpr
          },
          component: () => import('./views/app/apps/referensi/lbbpr/index.vue'),
        },
        {
          path: '/lbbpr/rasio_keuangan_triwulans',
          meta: {
            key: 'rasio_keuangan_triwulans',
            permissions: true, // <== Permissions ref lbbpr
          },
          component: () => import('./views/app/apps/referensi/lbbpr/index.vue'),
        },
        {
          path: '/lbbpr/rekening_administratifs',
          meta: {
            key: 'rekening_administratifs',
            permissions: true, // <== Permissions ref lbbpr
          },
          component: () => import('./views/app/apps/referensi/lbbpr/index.vue'),
        },
        {
          path: '/lbbpr/rincian_aset_lainnyas',
          meta: {
            key: 'rincian_aset_lainnyas',
            permissions: true, // <== Permissions ref lbbpr
          },
          component: () => import('./views/app/apps/referensi/lbbpr/index.vue'),
        },
        {
          path: '/lbbpr/rincian_liabilitas_lainnyas',
          meta: {
            key: 'rincian_liabilitas_lainnyas',
            permissions: true, // <== Permissions ref lbbpr
          },
          component: () => import('./views/app/apps/referensi/lbbpr/index.vue'),
        },
        {
          path: '/lbbpr/rincian_liabilitas_segeras',
          meta: {
            key: 'rincian_liabilitas_segeras',
            permissions: true, // <== Permissions ref lbbpr
          },
          component: () => import('./views/app/apps/referensi/lbbpr/index.vue'),
        },
        {
          path: '/lbbpr/sandi_jabatan_organs',
          meta: {
            key: 'sandi_jabatan_organs',
            permissions: true, // <== Permissions ref lbbpr
          },
          component: () => import('./views/app/apps/referensi/lbbpr/index.vue'),
        },
        {
          path: '/lbbpr/sandi_jabatan_penguruses',
          meta: {
            key: 'sandi_jabatan_penguruses',
            permissions: true, // <== Permissions ref lbbpr
          },
          component: () => import('./views/app/apps/referensi/lbbpr/index.vue'),
        },
        {
          path: '/lbbpr/sandi_kabupaten_kotas',
          meta: {
            key: 'sandi_kabupaten_kotas',
            permissions: true, // <== Permissions ref lbbpr
          },
          component: () => import('./views/app/apps/referensi/lbbpr/index.vue'),
        },
        {
          path: '/lbbpr/sandi_mata_uangs',
          meta: {
            key: 'sandi_mata_uangs',
            permissions: true, // <== Permissions ref lbbpr
          },
          component: () => import('./views/app/apps/referensi/lbbpr/index.vue'),
        },
        {
          path: '/lbbpr/sandi_pendidikan_formals',
          meta: {
            key: 'sandi_pendidikan_formals',
            permissions: true, // <== Permissions ref lbbpr
          },
          component: () => import('./views/app/apps/referensi/lbbpr/index.vue'),
        },
        {
          path: '/lbbpr/sandi_pihak_lawans',
          meta: {
            key: 'sandi_pihak_lawans',
            permissions: true, // <== Permissions ref lbbpr
          },
          component: () => import('./views/app/apps/referensi/lbbpr/index.vue'),
        },
        {
          path: '/lbbpr/sandi_pihak_terkaits',
          meta: {
            key: 'sandi_pihak_terkaits',
            permissions: true, // <== Permissions ref lbbpr
          },
          component: () => import('./views/app/apps/referensi/lbbpr/index.vue'),
        },
        {
          path: '/lbbpr/sandi_sifat_kredits',
          meta: {
            key: 'sandi_sifat_kredits',
            permissions: true, // <== Permissions ref lbbpr
          },
          component: () => import('./views/app/apps/referensi/lbbpr/index.vue'),
        },
        {
          path: '/lbbpr/status_ati_tdkberwujuds',
          meta: {
            key: 'status_ati_tdkberwujuds',
            permissions: true, // <== Permissions ref lbbpr
          },
          component: () => import('./views/app/apps/referensi/lbbpr/index.vue'),
        },
        {
          path: '/lbbpr/status_b_m_p_ks',
          meta: {
            key: 'status_b_m_p_ks',
            permissions: true, // <== Permissions ref lbbpr
          },
          component: () => import('./views/app/apps/referensi/lbbpr/index.vue'),
        },
        {
          path: '/lbbpr/status_kepemilikan_gedungs',
          meta: {
            key: 'status_kepemilikan_gedungs',
            permissions: true, // <== Permissions ref lbbpr
          },
          component: () => import('./views/app/apps/referensi/lbbpr/index.vue'),
        },
        {
          path: '/lbbpr/status_pemegang_sahams',
          meta: {
            key: 'status_pemegang_sahams',
            permissions: true, // <== Permissions ref lbbpr
          },
          component: () => import('./views/app/apps/referensi/lbbpr/index.vue'),
        },
        {
          path: '/lbbpr/status_restrukturisasis',
          meta: {
            key: 'status_restrukturisasis',
            permissions: true, // <== Permissions ref lbbpr
          },
          component: () => import('./views/app/apps/referensi/lbbpr/index.vue'),
        },
        {
          path: '/lbbpr/sumber_dana_pelunasans',
          meta: {
            key: 'sumber_dana_pelunasans',
            permissions: true, // <== Permissions ref lbbpr
          },
          component: () => import('./views/app/apps/referensi/lbbpr/index.vue'),
        },
        {
          path: '/lbbpr/sumber_perolehan_at_tdkberwujuds',
          meta: {
            key: 'sumber_perolehan_at_tdkberwujuds',
            permissions: true, // <== Permissions ref lbbpr
          },
          component: () => import('./views/app/apps/referensi/lbbpr/index.vue'),
        },
        // End route for LBBPR

        // {
        //   path: '/dashboard/logtransaction',
        //   meta: {
        //     title: 'Dashboard Log Transaction',
        //     permissions: ['access-sewa_ban-log', 'access-test_ban-log'],
        //   },
        //   component: () => import('./views/app/dashboard/transaction'),
        // },
        // {
        //   path: 'apps/Chatting',
        //   name: 'chatting',
        //   meta: {
        //     title: 'App / Chatting',
        //   },
        //   component: () => import('./views/app/chatting/index.vue'),
        // },
        {
          path: '/master/permissions',
          name: 'permissions',
          meta: {
            title: 'Master / Permissions',
          },
          component: () => import('./views/app/master/permissions/index.vue'),
        },
        {
          path: '/master/roles',
          name: 'role',
          meta: {
            title: 'Master / Roles',
          },
          component: () => import('./views/app/master/role/index.vue'),
        },
        {
          path: '/master/users',
          name: 'users',
          meta: {
            title: 'Master / Users',
          },
          component: () => import('./views/app/master/users/index.vue'),
        },
        {
          path: '/master/branches',
          meta: {
            title: 'Master / Branches',
            permissions: true,
          },
          component: () => import('./views/app/master/branches/index.vue'),
        },
        {
          path: '/master/documentation',
          meta: {
            title: 'Documentation',
            permissions: true,
          },
          component: () => import('./views/app/apps/documentation/index.vue'),
        },
        // {
        //   path: '/master/roles',
        //   name: 'roles',
        //   meta: {
        //     title: 'Master / Roles',
        //     permissions: ['access-roles'],
        //   },
        //   component: () => import('./views/app/master/roles/index.vue'),
        // },
        // {
        //   path: '/setting/shift',
        //   name: 'Shift',
        //   meta: {
        //     title: 'Shift settings',
        //   },
        //   component: () => import('./views/app/master/shift/index.vue'),
        // },
        // {
        //   path: '/notification',
        //   name: 'notificationBroadcast',
        //   meta: {
        //     title: 'Pemberitahuan',
        //   },
        //   component: () => import('./views/app/apps/notification/index.vue'),
        // },

        /* Laporan */
        // {
        //   path: 'apps/laporan/odopermobil',
        //   name: 'odopermobil',
        //   meta: {
        //     title: 'Rekap / Odo per Mobil',
        //     permissions: ['access-sewa_ban-sp_rekap_odo', 'access-test_ban-sp_rekap_odo'],
        //   },
        //   component: () => import('./views/app/laporan/odopermobil/index.vue'),
        // },
        // {
        //   path: '/laporan/penjualan/detail',
        //   name: 'lappenjualandetail',
        //   meta: {
        //     title: 'Detail Penjualan',
        //     permissions: ['access-ukmsys-laptrxjual'],
        //   },
        //   component: () => import('./views/app/laporan/detail_penjualan/index.vue'),
        // },
        // {
        //   path: '/jurnal/laporan/bukubesar',
        //   name: 'bukubesar',
        //   meta: {
        //     title: 'Laporan / Buku Besar',
        //     permissions: ['access-ukmsys-bukubesar'],
        //   },
        //   component: () => import('./views/app/jurnal/laporan/bukubesar/index.vue'),
        // },
        // {
        //   path: '/jurnal/laporan/neraca',
        //   name: 'neraca',
        //   meta: {
        //     title: 'Laporan / Buku Besar',
        //     permissions: ['access-ukmsys-neraca'],
        //   },
        //   component: () => import('./views/app/jurnal/laporan/neraca/index.vue'),
        // },
      ],
    },

    // System Pages
    {
      path: '/auth',
      component: AuthLayout,
      redirect: 'auth/login',
      children: [
        {
          path: '/auth/404',
          meta: {
            title: 'Page Not Found',
          },
          component: () => import('./views/auth/404'),
        },
        {
          path: '/auth/blockeddomain',
          meta: {
            title: 'Domain Is Blocked',
          },
          component: () => import('./views/auth/blockeddomain'),
        },
        {
          path: '/auth/403',
          meta: {
            title: '(Forbiden Page)',
          },
          component: () => import('./views/auth/403'),
        },
        {
          path: '/auth/500',
          meta: {
            title: 'Error 500',
          },
          component: () => import('./views/auth/500'),
        },
        {
          path: '/auth/login',
          meta: {
            title: 'Sign In',
          },
          component: () => import('./views/auth/login'),
        },
        {
          path: '/auth/register',
          meta: {
            title: 'Sign Up',
          },
          component: () => import('./views/auth/register'),
        },
        {
          path: '/auth/forgot-password',
          meta: {
            title: 'Forgot Password',
          },
          component: () => import('./views/auth/forgot-password'),
        },
        {
          path: '/auth/lockscreen',
          meta: {
            title: 'Lockscreen',
          },
          component: () => import('./views/auth/lockscreen'),
        },
      ],
    },

    // Redirect to 404
    {
      path: '*', redirect: 'auth/403', hidden: true,
    },
  ],
})

router.beforeEach((to, from, next) => {
  // console.log('to', to)
  // console.log('from', from)
  // console.log('next', next)
  // console.log('store', store.state.settings.isLocked)
  // const hostname = location.host
  if (to.matched.some(record => record.meta.authRequired)) {
    if (!store.state.user.authorized) {
      //  || hostname.indexOf('Labul Icon.web.app') >= 0
      next({
        path: '/auth/login',
        query: { redirect: to.fullPath },
      })
    } else {
      // if (store.state.settings.isLocked) {
      //   next({
      //     path: '/auth/lockscreen',
      //     query: { redirect: to.fullPath },
      //   })
      // } else {

      try {
        to.matched.some(record => {
          // console.log('record.meta.permissions', record.meta.permissions)
          if (record.meta.roles !== undefined) {
            cekRole(record.meta.roles, next, to)
          } else if (record.meta.permissions !== undefined) {
            cekPermit(record.meta.permissions, next, to)
          } else {
            cekNext(next)
          }
        })
      } catch (e) {
        console.log('e', e)
      }
      // }
    }
  } else {
    cekNext(next)
  }
})

function cekNext(next) {
  if (!navigator.onLine) {
    notification.warning({
      message: 'Anda sedang offline. Pastikan jaringan internet Anda tersambung',
    })
    return false
  }
  next()
}

function cekPermit(permit, next, to) {
  var ret = false
  // console.log('store.state.user.permissions', store.state.user.permissions)
  // console.log('to.path', to.path)
  try {
    ret = checkChildren1(store.state.user.permissions, to)
  } catch (e) {
    console.log('e', e)
  }
  // console.log('ret', ret)
  if (ret) {
    cekNext(next)
  } else {
    next({
      path: '/page/403',
      query: { redirect: to.fullPath },
    })
  }
}

// function checkChildren(array, to) {
//   var ret = false
//   array.forEach(element => {
//     console.log(element.url === to.path)
//     if (element.url === to.path) {
//       ret = true
//       return ret
//     } else {
//       if (element.children !== undefined) {
//         console.log('has Child')
//         ret = checkChildren(element.children, to)
//         if (ret) {
//           return true
//         }
//       }
//     }
//   })
//   return ret
// }

function checkChildren1(array, to) {
  var ret = false
  for (let i = 0; i < array.length; i++) {
    const element = array[i]
    // console.log(element.url === to.path)
    if (element.url === to.path) {
      ret = true
      return ret
    } else {
      if (element.children !== undefined) {
        // console.log('has Child')
        ret = checkChildren1(element.children, to)
        if (ret) {
          return true
        }
      }
    }
  }
  return ret
}

function cekRole(permit, next, to) {
  var ret = false
  permit.forEach(item => {
    // console.log(item, store.state.user.permissions.includes(item))
    if (ret === false) {
      ret = store.state.user.role === item
    }
  })

  // console.log('ret', ret)
  if (ret) {
    cekNext(next)
  } else {
    next({
      path: '/page/403',
      query: { redirect: to.fullPath },
    })
  }
}

export default router
