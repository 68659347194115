import {
  notification,
  Modal,
} from 'ant-design-vue'
import apiClient from '@/services/axios'
import axiosstore from 'store'
import moment from 'moment'
import NProgress from 'nprogress'
import XLSX from 'xlsx'
import store from '@/store'
import VuePageTitle from 'vue-page-title'

// const hostname = location.host
var link1 = '/'
var link2 = '/'
const lbbprs = '/'
const attendance1 = '/'
const testattendance = '/'
const testattendance1 = '/'
var isTesting = false
// eva/
// geo/lbbprs/

export function setHostname() {
  // if (hostname.indexOf('domain.web.app') >= 0) {
  //   isTesting = false
  // } else if (hostname.indexOf('domain.firebaseapp.com') >= 0) {
  //   isTesting = true
  // } else if (hostname.indexOf('localhost') >= 0) {
  //   // console.log('store.state.settings.isTesting', store.state.settings.isTesting)
  //   isTesting = store.state.settings.isTesting
  // }
  // console.log('isTesting', store.state.settings.isTesting)
  if (isTesting) {
    link1 = lbbprs
    link2 = attendance1
    // store.state.settings.logo = 'TESTING'
    VuePageTitle.prefix = 'LB App'
  } else {
    link1 = testattendance
    link2 = testattendance1
    // store.state.settings.logo = 'LB-BPRS'
    VuePageTitle.prefix = 'LB App'
  }
}

setHostname()

export function baca(gate, operation = 'read', data = []) {
  var retval = {
    gate,
    operation: operation,
    data,
  }
  return retval
}

export async function ipReqTesting(getResponseEvenWhenFail = false) {
  // apiClient.defaults.baseURL = 'https://api.ipify.org?format=json'
  var head = { 'Access-Control-Allow-Origin': '*' }
  return apiClient
    .get('http://api.ipify.org?format=json', { headers: head })
    .then((response) => {
      NProgress.done()
      if (response) {
        // console.log('response', response)
        // const { accessToken } = response.accessToken
        // console.log('service-data-read', response.data)
        // this.$store.commit('SET_STATE', { setting: 'loading', value: false })
        return response.data
      }
      if (getResponseEvenWhenFail) {
        return response
      } else {
        return false
      }
    })
    .catch((err) => console.log('err service->data->select', err))
}

export async function readProfile(todefaultbaseurl = false) {
  NProgress.start()
  var data = {}
  // console.log('link1', link1)
  var dataapps = JSON.parse(localStorage.getItem('appList')) || store.state.user.appList
  // console.log('dataapps', dataapps)
  var base = { baseUrl: '' }
  if (store.state.settings.isTesting) {
    base = {
      baseUrl: 'http://localhost:5367',
    }
  } else {
    if (dataapps) {
      base = dataapps.find(x => x.id === store.state.settings.whatapp.id)
    } else {
      base = {
        baseUrl: 'https://apilbbprs.heasoft.com',
      }
    }
  }
  apiClient.defaults.baseURL = todefaultbaseurl ? 'https://apilbbprs.heasoft.com/api/' : base.baseUrl + '/api/'
  return apiClient
    .post(link1 + 'profil', data)
    .then((response) => {
      NProgress.done()
      if (response) {
        // const { accessToken } = response.accessToken
        // console.log('service-data-read', response.data)
        // this.$store.commit('SET_STATE', { setting: 'loading', value: false })
        return response.data
      }
      return false
    })
    .catch((err) => console.log('err service->data->select', err))
}

export async function createProfile(data, withNotif = true, todefaultbaseurl = false) {
  NProgress.start()
  var dataapps = JSON.parse(localStorage.getItem('appList')) || store.state.user.appList
  // console.log('dataapps', dataapps)
  var base = { baseUrl: '' }
  if (store.state.settings.isTesting) {
    base = {
      baseUrl: 'http://localhost:5367',
    }
  } else {
    if (dataapps) {
      base = dataapps.find(x => x.id === store.state.settings.whatapp.id)
    } else {
      base = {
        baseUrl: 'https://apilbbprs.heasoft.com',
      }
    }
  }
  apiClient.defaults.baseURL = todefaultbaseurl ? 'https://apilbbprs.heasoft.com/api/' : base.baseUrl + '/api/'
  return apiClient
    .post(link1 + 'auth', data)
    .then((response) => {
      NProgress.done()
      if (response) {
        // const { accessToken } = response.accessToken
        // console.log('service->data->create', response)
        if (withNotif) {
          notification.success({
            message: 'Successfully',
            description: response.message || 'Data has been successfully Created!',
          })
        }
        return response.data
      }
      return false
    })
    .catch((err) => console.log('err service->data->select', err))
}

export async function updateProfile(data, withNotif = true, todefaultbaseurl = false) {
  NProgress.start()
  var dataapps = JSON.parse(localStorage.getItem('appList')) || store.state.user.appList
  // console.log('dataapps', dataapps)
  var base = { baseUrl: '' }
  if (store.state.settings.isTesting) {
    base = {
      baseUrl: 'http://localhost:5367',
    }
  } else {
    if (dataapps) {
      base = dataapps.find(x => x.id === store.state.settings.whatapp.id)
    } else {
      base = {
        baseUrl: 'https://apilbbprs.heasoft.com',
      }
    }
  }
  apiClient.defaults.baseURL = todefaultbaseurl ? 'https://apilbbprs.heasoft.com/api/' : base.baseUrl + '/api/'
  return apiClient
    .put(link2 + 'setting/user/user/updatePassword', data)
    .then((response) => {
      NProgress.done()
      // console.log('response', response)
      if (response) {
        // const { accessToken } = response.accessToken
        // console.log('service->data->update', response)
        if (withNotif) {
          notification.success({
            message: 'Updated',
            description: 'Data has been successfully updated!',
          })
        }
        return response.data
      }
      return false
    })
    .catch((err) => console.log('err service->data->update', err))
}

export async function readDashboard(dashboard, data = {}, todefaultbaseurl = false) {
  NProgress.start()
  var dataapps = JSON.parse(localStorage.getItem('appList')) || store.state.user.appList
  // console.log('dataapps', dataapps)
  var base = { baseUrl: '' }
  if (store.state.settings.isTesting) {
    base = {
      baseUrl: 'http://localhost:5367',
    }
  } else {
    if (dataapps) {
      base = dataapps.find(x => x.id === store.state.settings.whatapp.id)
    } else {
      base = {
        baseUrl: 'https://apilbbprs.heasoft.com',
      }
    }
  }
  apiClient.defaults.baseURL = todefaultbaseurl ? 'https://apilbbprs.heasoft.com/api/' : base.baseUrl + '/api/'
  return apiClient
    .post(link2 + 'geo/absen/' + dashboard, data)
    .then((response) => {
      NProgress.done()
      if (response) {
        // const { accessToken } = response.accessToken
        // console.log('service-data-read', response.data)
        // this.$store.commit('SET_STATE', { setting: 'loading', value: false })
        return response.data
      }
      return false
    })
    .catch((err) => console.log('err service->data->select', err))
}

export async function readLaporan(laporan, data = {}, todefaultbaseurl = false) {
  NProgress.start()
  var dataapps = JSON.parse(localStorage.getItem('appList')) || store.state.user.appList
  // console.log('dataapps', dataapps)
  var base = { baseUrl: '' }
  if (store.state.settings.isTesting) {
    base = {
      baseUrl: 'http://localhost:5367',
    }
  } else {
    if (dataapps) {
      base = dataapps.find(x => x.id === store.state.settings.whatapp.id)
    } else {
      base = {
        baseUrl: 'https://apilbbprs.heasoft.com',
      }
    }
  }
  apiClient.defaults.baseURL = todefaultbaseurl ? 'https://apilbbprs.heasoft.com/api/' : base.baseUrl + '/api/'
  return apiClient
    .post(link2 + 'geo/absen/report/' + laporan, data)
    .then((response) => {
      NProgress.done()
      if (response) {
        // const { accessToken } = response.accessToken
        // console.log('service-data-read', response.data)
        // this.$store.commit('SET_STATE', { setting: 'loading', value: false })
        return response.data
      }
      return false
    })
    .catch((err) => console.log('err service->data->select', err))
}

export async function getAllMaster(storageKey = 'allMaster', withloading = true, todefaultbaseurl = false) {
  if (withloading) {
    NProgress.start()
  }
  var dataapps = JSON.parse(localStorage.getItem('appList')) || store.state.user.appList
  // console.log('dataapps', dataapps)
  var base = { baseUrl: '' }
  if (store.state.settings.isTesting) {
    base = {
      baseUrl: 'http://localhost:5367',
    }
  } else {
    if (dataapps) {
      base = dataapps.find(x => x.id === store.state.settings.whatapp.id)
    } else {
      base = {
        baseUrl: 'https://apilbbprs.heasoft.com',
      }
    }
  }
  apiClient.defaults.baseURL = todefaultbaseurl ? 'https://apilbbprs.heasoft.com/api/' : base.baseUrl + '/api/'
  return apiClient
    .get(link2 + 'master')
    .then((response) => {
      if (withloading) {
        NProgress.done()
      }
      if (response) {
        // const { accessToken } = response.accessToken
        // console.log('service-data-read', response.data)
        // localStorage.setItem(storageKey, JSON.stringify(response.data.data))
        // this.$store.commit('SET_STATE', { setting: 'loading', value: false })
        return response.data
      }
      return false
    })
    .catch((err) => console.log('err service->data->select', err))
}
export async function readMaster(master, withloading = false, todefaultbaseurl = false) {
  if (withloading) {
    NProgress.start()
  }
  var dataapps = JSON.parse(localStorage.getItem('appList')) || store.state.user.appList
  // console.log('dataapps', dataapps)
  var base = { baseUrl: '' }
  if (store.state.settings.isTesting) {
    base = {
      baseUrl: 'http://localhost:5367',
    }
  } else {
    if (dataapps) {
      base = dataapps.find(x => x.id === store.state.settings.whatapp.id)
    } else {
      base = {
        baseUrl: 'https://apilbbprs.heasoft.com',
      }
    }
  }
  apiClient.defaults.baseURL = todefaultbaseurl ? 'https://apilbbprs.heasoft.com/api/' : base.baseUrl + '/api/'
  return apiClient
    .get(link2 + master)
    .then((response) => {
      if (withloading) {
        NProgress.done()
      }
      if (response) {
        // const { accessToken } = response.accessToken
        // console.log('service-data-read', response.data)
        // this.$store.commit('SET_STATE', { setting: 'loading', value: false })
        return response.data
      }
      return false
    })
    .catch((err) => console.log('err service->data->select', err))
}

export async function customUrlGet(link, withNotif = false, todefaultbaseurl = false, getResponseEvenWhenFail = false, withloading = true) {
  if (withloading) {
    NProgress.start()
  }
  // console.log('store', store)
  var dataapps = JSON.parse(localStorage.getItem('appList')) || store.state.user.appList
  // console.log('dataapps', dataapps)
  var base = { baseUrl: '' }
  if (store.state.settings.isTesting) {
    base = {
      baseUrl: 'http://localhost:5367',
    }
  } else {
    if (dataapps) {
      base = dataapps.find(x => x.id === store.state.settings.whatapp.id)
    } else {
      base = {
        baseUrl: 'https://apilbbprs.heasoft.com',
      }
    }
  }
  var headers = {
    activeAppName: 'lbbprs',
  }
  apiClient.defaults.baseURL = todefaultbaseurl ? 'https://apilbbprs.heasoft.com/api/' : base.baseUrl + '/api/'
  return apiClient
    .get(link2 + link, {
      headers: headers,
    })
    .then((response) => {
      if (withloading) {
        NProgress.done()
      }
      if (response) {
        if (withNotif) {
          notification.success({
            message: 'Sucessful',
            description: response.data.message,
          })
        }
        // const { accessToken } = response.accessToken
        // console.log('service-data-read', response.data)
        // this.$store.commit('SET_STATE', { setting: 'loading', value: false })
        return response.data
      }
      if (getResponseEvenWhenFail) {
        return response
      } else {
        return false
      }
    })
    .catch((err) => console.log('err service->data->select', err))
}
export async function customUrlPut(link, data, withNotif = true, todefaultbaseurl = false) {
  NProgress.start()
  var dataapps = JSON.parse(localStorage.getItem('appList')) || store.state.user.appList
  // console.log('dataapps', dataapps)
  var base = { baseUrl: '' }
  if (store.state.settings.isTesting) {
    base = {
      baseUrl: 'http://localhost:5367',
    }
  } else {
    if (dataapps) {
      base = dataapps.find(x => x.id === store.state.settings.whatapp.id)
    } else {
      base = {
        baseUrl: 'https://apilbbprs.heasoft.com',
      }
    }
  }
  apiClient.defaults.baseURL = todefaultbaseurl ? 'https://apilbbprs.heasoft.com/api/' : base.baseUrl + '/api/'
  return apiClient
    .put(link2 + link, data)
    .then((response) => {
      NProgress.done()
      if (response) {
        if (withNotif) {
          notification.success({
            message: 'Sucessful',
            description: response.data.message,
          })
        }
        // const { accessToken } = response.accessToken
        // console.log('service-data-read', response.data)
        // this.$store.commit('SET_STATE', { setting: 'loading', value: false })
        return response.data
      }
      return false
    })
    .catch((err) => console.log('err service->data->select', err))
}

export async function customUrlPost(link, data, withNotif = true, todefaultbaseurl = false) {
  NProgress.start()
  var dataapps = JSON.parse(localStorage.getItem('appList')) || store.state.user.appList
  // console.log('dataapps', dataapps)
  var base = { baseUrl: '' }
  if (store.state.settings.isTesting) {
    base = {
      baseUrl: 'http://localhost:5367',
    }
  } else {
    if (dataapps) {
      base = dataapps.find(x => x.id === store.state.settings.whatapp.id)
    } else {
      base = {
        baseUrl: 'https://apilbbprs.heasoft.com',
      }
    }
  }
  apiClient.defaults.baseURL = todefaultbaseurl ? 'https://apilbbprs.heasoft.com/api/' : base.baseUrl + '/api/'
  return apiClient
    .post(link2 + link, data)
    .then((response) => {
      NProgress.done()
      if (response) {
        if (withNotif) {
          notification.success({
            message: 'Sucessful',
            description: response.data.message,
          })
        }
        // const { accessToken } = response.accessToken
        // console.log('service-data-read', response.data)
        // this.$store.commit('SET_STATE', { setting: 'loading', value: false })
        return response.data
      }
      return false
    })
    .catch((err) => console.log('err service->data->select', err))
}
export async function customUrlDelete(link, withNotif = true, todefaultbaseurl = false) {
  NProgress.start()
  var dataapps = JSON.parse(localStorage.getItem('appList')) || store.state.user.appList
  // console.log('dataapps', dataapps)
  var base = { baseUrl: '' }
  if (store.state.settings.isTesting) {
    base = {
      baseUrl: 'http://localhost:5367',
    }
  } else {
    if (dataapps) {
      base = dataapps.find(x => x.id === store.state.settings.whatapp.id)
    } else {
      base = {
        baseUrl: 'https://apilbbprs.heasoft.com',
      }
    }
  }
  apiClient.defaults.baseURL = todefaultbaseurl ? 'https://apilbbprs.heasoft.com/api/' : base.baseUrl + '/api/'
  return apiClient
    .delete(link2 + link)
    .then((response) => {
      NProgress.done()
      if (response) {
        if (withNotif) {
          notification.success({
            message: 'Sucessful',
            description: response.data.message,
          })
        }
        // const { accessToken } = response.accessToken
        // console.log('service-data-read', response.data)
        // this.$store.commit('SET_STATE', { setting: 'loading', value: false })
        return response.data
      }
      return false
    })
    .catch((err) => console.log('err service->data->select', err))
}

export async function customUrlGet2(link, withNotif = false, todefaultbaseurl = false, getResponseEvenWhenFail = false, withLoadin = false) {
  if (withLoadin) {
    NProgress.start()
  }
  // console.log('store', store)
  var dataapps = JSON.parse(localStorage.getItem('appList')) || store.state.user.appList
  // console.log('dataapps', dataapps)
  var base = { baseUrl: '' }
  if (store.state.settings.isTesting) {
    base = {
      baseUrl: 'http://localhost:5367',
    }
  } else {
    if (dataapps) {
      base = dataapps.find(x => x.id === store.state.settings.whatapp.id)
      // console.log('base', base)
    } else {
      base = {
        baseUrl: 'https://apilbbprs.heasoft.com',
      }
    }
  }
  var h1 = {
    activeAppName: 'lbbprs',
  }
  var devHeader = {}
  if (location.host.includes('localhost')) {
    devHeader = {
      'x-heasoft-dev': 'DFMCb49AWxLXun8zseQda3fq',
    }
    // console.log('devHeader', devHeader)
  }
  var headers = {
    h1,
    devHeader,
  }
  apiClient.defaults.baseURL = todefaultbaseurl ? 'https://apilbbprs.heasoft.com/api/' : base.baseUrl
  return apiClient
    .get(link2 + link, {
      headers: headers,
    })
    .then((response) => {
      NProgress.done()
      if (response) {
        if (withNotif) {
          notification.success({
            message: 'Sucessful',
            description: response.data.message,
          })
        }
        // const { accessToken } = response.accessToken
        // console.log('service-data-read', response.data)
        // this.$store.commit('SET_STATE', { setting: 'loading', value: false })
        return response.data
      }
      if (getResponseEvenWhenFail) {
        return response
      } else {
        return false
      }
    })
    .catch((err) => console.log('err service->data->select', err))
}
export async function customUrlPut2(link, data, withNotif = true, todefaultbaseurl = false, withLoadin = false) {
  if (withLoadin) {
    NProgress.start()
  }
  var dataapps = JSON.parse(localStorage.getItem('appList')) || store.state.user.appList
  // console.log('dataapps', dataapps)
  var base = { baseUrl: '' }
  if (store.state.settings.isTesting) {
    base = {
      baseUrl: 'http://localhost:5367',
    }
  } else {
    if (dataapps) {
      base = dataapps.find(x => x.id === store.state.settings.whatapp.id)
    } else {
      base = {
        baseUrl: 'https://apilbbprs.heasoft.com',
      }
    }
  }
  apiClient.defaults.baseURL = todefaultbaseurl ? 'https://apilbbprs.heasoft.com/api/' : base.baseUrl
  return apiClient
    .put(link2 + link, data)
    .then((response) => {
      NProgress.done()
      if (response) {
        if (withNotif) {
          notification.success({
            message: 'Sucessful',
            description: response.data.message,
          })
        }
        // const { accessToken } = response.accessToken
        // console.log('service-data-read', response.data)
        // this.$store.commit('SET_STATE', { setting: 'loading', value: false })
        return response.data
      }
      return false
    })
    .catch((err) => console.log('err service->data->select', err))
}

export async function customUrlPut3(link, data, withNotif = true, todefaultbaseurl = false, withLoadin = false, customheader = {}) {
  if (withLoadin) {
    NProgress.start()
  }
  var dataapps = JSON.parse(localStorage.getItem('appList')) || store.state.user.appList
  // console.log('dataapps', dataapps)
  var base = { baseUrl: '' }
  const accessToken = axiosstore.get('accessToken')
  var headers = {
    Authorization: accessToken,
  }
  var mergeheaders = {
    ...headers,
    ...customheader,
  }
  if (store.state.settings.isTesting) {
    base = {
      baseUrl: 'http://localhost:5367',
    }
  } else {
    if (dataapps) {
      base = dataapps.find(x => x.id === store.state.settings.whatapp.id)
    } else {
      base = {
        baseUrl: 'https://apilbbprs.heasoft.com',
      }
    }
  }
  apiClient.defaults.baseURL = todefaultbaseurl ? 'https://apilbbprs.heasoft.com/api/' : base.baseUrl
  return apiClient
    .put(link2 + link, data, {
      headers: mergeheaders,
    })
    .then((response) => {
      NProgress.done()
      if (response) {
        if (withNotif) {
          notification.success({
            message: 'Sucessful',
            description: response.data.message,
          })
        }
        // const { accessToken } = response.accessToken
        // console.log('service-data-read', response.data)
        // this.$store.commit('SET_STATE', { setting: 'loading', value: false })
        return response.data
      }
      return false
    })
    .catch((err) => console.log('err service->data->select', err))
}

export async function customUrlPatch(link, data, withNotif = true, todefaultbaseurl = false, withLoadin = false, customheader = {}) {
  if (withLoadin) {
    NProgress.start()
  }
  var dataapps = JSON.parse(localStorage.getItem('appList')) || store.state.user.appList
  // console.log('dataapps', dataapps)
  var base = { baseUrl: '' }
  const accessToken = axiosstore.get('accessToken')
  var headers = {
    Authorization: accessToken,
  }
  var mergeheaders = {
    ...headers,
    ...customheader,
  }
  if (store.state.settings.isTesting) {
    base = {
      baseUrl: 'http://localhost:5367',
    }
  } else {
    if (dataapps) {
      base = dataapps.find(x => x.id === store.state.settings.whatapp.id)
    } else {
      base = {
        baseUrl: 'https://apilbbprs.heasoft.com',
      }
    }
  }
  apiClient.defaults.baseURL = todefaultbaseurl ? 'https://apilbbprs.heasoft.com/api/' : base.baseUrl
  return apiClient
    .patch(link2 + link, data, {
      headers: mergeheaders,
    })
    .then((response) => {
      NProgress.done()
      if (response) {
        if (withNotif) {
          notification.success({
            message: 'Sucessful',
            description: response.data.message,
          })
        }
        // const { accessToken } = response.accessToken
        // console.log('service-data-read', response.data)
        // this.$store.commit('SET_STATE', { setting: 'loading', value: false })
        return response.data
      }
      return false
    })
    .catch((err) => console.log('err service->data->select', err))
}

export async function customUrlPost2(link, data, withNotif = true, todefaultbaseurl = false, withLoadin = false) {
  if (withLoadin) {
    NProgress.start()
  }
  var dataapps = JSON.parse(localStorage.getItem('appList')) || store.state.user.appList
  // console.log('dataapps', dataapps)
  var base = { baseUrl: '' }
  if (store.state.settings.isTesting) {
    base = {
      baseUrl: 'http://localhost:5367',
    }
  } else {
    if (dataapps) {
      base = dataapps.find(x => x.id === store.state.settings.whatapp.id)
    } else {
      base = {
        baseUrl: 'https://apilbbprs.heasoft.com',
      }
    }
  }
  apiClient.defaults.baseURL = todefaultbaseurl ? 'https://apilbbprs.heasoft.com/api/' : base.baseUrl
  return apiClient
    .post(link2 + link, data)
    .then((response) => {
      NProgress.done()
      if (response) {
        if (withNotif) {
          notification.success({
            message: 'Sucessful',
            description: response.data.message,
          })
        }
        // const { accessToken } = response.accessToken
        // console.log('service-data-read', response.data)
        // this.$store.commit('SET_STATE', { setting: 'loading', value: false })
        return response.data
      }
      return false
    })
    .catch((err) => console.log('err service->data->select', err))
}
export async function customUrlPost3(link, data, withNotif = true, todefaultbaseurl = false, withLoadin = false, customheader = {}) {
  if (withLoadin) {
    NProgress.start()
  }
  var dataapps = JSON.parse(localStorage.getItem('appList')) || store.state.user.appList
  // console.log('dataapps', dataapps)
  var base = { baseUrl: '' }
  const accessToken = axiosstore.get('accessToken')
  var headers = {
    Authorization: accessToken,
  }
  var mergeheaders = {
    ...headers,
    ...customheader,
  }
  if (store.state.settings.isTesting) {
    base = {
      baseUrl: 'http://localhost:5367',
    }
  } else {
    if (dataapps) {
      base = dataapps.find(x => x.id === store.state.settings.whatapp.id)
    } else {
      base = {
        baseUrl: 'https://apilbbprs.heasoft.com',
      }
    }
  }
  apiClient.defaults.baseURL = todefaultbaseurl ? 'https://apilbbprs.heasoft.com/api/' : base.baseUrl
  return apiClient
    .post(link2 + link, data, {
      headers: mergeheaders,
    })
    .then((response) => {
      NProgress.done()
      if (response) {
        if (withNotif) {
          notification.success({
            message: 'Sucessful',
            description: response.data.message,
          })
        }
        // const { accessToken } = response.accessToken
        // console.log('service-data-read', response.data)
        // this.$store.commit('SET_STATE', { setting: 'loading', value: false })
        return response.data
      }
      return false
    })
    .catch((err) => console.log('err service->data->select', err))
}
export async function customUrlDelete2(link, withNotif = true, todefaultbaseurl = false, withLoadin = false) {
  if (withLoadin) {
    NProgress.start()
  }
  var dataapps = JSON.parse(localStorage.getItem('appList')) || store.state.user.appList
  // console.log('dataapps', dataapps)
  var base = { baseUrl: '' }
  if (store.state.settings.isTesting) {
    base = {
      baseUrl: 'http://localhost:5367',
    }
  } else {
    if (dataapps) {
      base = dataapps.find(x => x.id === store.state.settings.whatapp.id)
    } else {
      base = {
        baseUrl: 'https://apilbbprs.heasoft.com',
      }
    }
  }
  apiClient.defaults.baseURL = todefaultbaseurl ? 'https://apilbbprs.heasoft.com/api/' : base.baseUrl
  return apiClient
    .delete(link2 + link)
    .then((response) => {
      NProgress.done()
      if (response) {
        if (withNotif) {
          notification.success({
            message: 'Sucessful',
            description: response.data.message,
          })
        }
        // const { accessToken } = response.accessToken
        // console.log('service-data-read', response.data)
        // this.$store.commit('SET_STATE', { setting: 'loading', value: false })
        return response.data
      }
      return false
    })
    .catch((err) => console.log('err service->data->select', err))
}

export async function customUrlDelete4(link, withNotif = true, todefaultbaseurl = false, withLoadin = false, customheader = {}) {
  if (withLoadin) {
    NProgress.start()
  }
  var dataapps = JSON.parse(localStorage.getItem('appList')) || store.state.user.appList
  // console.log('dataapps', dataapps)
  var base = { baseUrl: '' }
  const accessToken = axiosstore.get('accessToken')
  var headers = {
    Authorization: accessToken,
  }
  var mergeheaders = {
    ...headers,
    ...customheader,
  }
  if (store.state.settings.isTesting) {
    base = {
      baseUrl: 'http://localhost:5367',
    }
  } else {
    if (dataapps) {
      base = dataapps.find(x => x.id === store.state.settings.whatapp.id)
    } else {
      base = {
        baseUrl: 'https://apilbbprs.heasoft.com',
      }
    }
  }
  apiClient.defaults.baseURL = todefaultbaseurl ? 'https://apilbbprs.heasoft.com/api/' : base.baseUrl
  return apiClient
    .delete(link2 + link, {
      headers: mergeheaders,
    })
    .then((response) => {
      NProgress.done()
      if (response) {
        if (withNotif) {
          notification.success({
            message: 'Sucessful',
            description: response.data.message,
          })
        }
        // const { accessToken } = response.accessToken
        // console.log('service-data-read', response.data)
        // this.$store.commit('SET_STATE', { setting: 'loading', value: false })
        return response.data
      }
      return false
    })
    .catch((err) => console.log('err service->data->select', err))
}

export async function customUrlGet3(link, withNotif = false, todefaultbaseurl = false, baseurl = 'https://apilbbprs.heasoft.com/api/', getResponseEvenWhenFail = false, withLoadin = false) {
  if (withLoadin) {
    NProgress.start()
  }
  // console.log('store', store)
  var dataapps = JSON.parse(localStorage.getItem('appList')) || store.state.user.appList
  // console.log('dataapps', dataapps)
  var base = { baseUrl: '' }
  if (store.state.settings.isTesting) {
    base = {
      baseUrl: 'http://localhost:5367',
    }
  } else {
    if (dataapps) {
      base = dataapps.find(x => x.id === store.state.settings.whatapp.id)
      // console.log('base', base)
    } else {
      base = {
        baseUrl: 'https://apilbbprs.heasoft.com',
      }
    }
  }
  var headers = {
    activeAppName: 'lbbprs',
  }
  apiClient.defaults.baseURL = todefaultbaseurl ? baseurl : base.baseUrl
  return apiClient
    .get(link, {
      headers: headers,
    })
    .then((response) => {
      NProgress.done()
      if (response) {
        if (withNotif) {
          notification.success({
            message: 'Sucessful',
            description: response.data.message,
          })
        }
        // const { accessToken } = response.accessToken
        // console.log('service-data-read', response.data)
        // this.$store.commit('SET_STATE', { setting: 'loading', value: false })
        return response.data
      }
      if (getResponseEvenWhenFail) {
        return response
      } else {
        return false
      }
    })
    .catch((err) => console.log('err service->data->select', err))
}
export async function customUrlDelete3(link, data, withNotif = true, todefaultbaseurl = false, withLoadin = false) {
  if (withLoadin) {
    NProgress.start()
  }
  var dataapps = JSON.parse(localStorage.getItem('appList')) || store.state.user.appList
  // console.log('dataapps', dataapps)
  var base = { baseUrl: '' }
  if (store.state.settings.isTesting) {
    base = {
      baseUrl: 'http://localhost:5367',
    }
  } else {
    if (dataapps) {
      base = dataapps.find(x => x.id === store.state.settings.whatapp.id)
    } else {
      base = {
        baseUrl: 'https://apilbbprs.heasoft.com',
      }
    }
  }
  apiClient.defaults.baseURL = todefaultbaseurl ? 'https://apilbbprs.heasoft.com/api/' : base.baseUrl
  return apiClient
    .delete(link2 + link)
    .then((response) => {
      NProgress.done()
      if (response) {
        if (withNotif) {
          notification.success({
            message: 'Sucessful',
            description: response.data.message,
          })
        }
        // const { accessToken } = response.accessToken
        // console.log('service-data-read', response.data)
        // this.$store.commit('SET_STATE', { setting: 'loading', value: false })
        return response.data
      }
      return false
    })
    .catch((err) => console.log('err service->data->select', err))
}

export async function getBaseUrl(w) {
  var dataapps = JSON.parse(localStorage.getItem('appList')) || store.state.user.appList
  // console.log('dataapps', dataapps)
  var ret = ''
  if (dataapps) {
    ret = dataapps.find(x => x.appName === w)
  } else {
    ret = ''
  }
  // console.log('ret', ret)
  return ret.baseUrl
}

export async function readMultiMaster(data) {
  var arr = []
  for (var i in data) {
    // alert(i) // alerts key
    // alert(data[i]) // alerts key's value
    var response = await readMaster(data[i].name, {})
    arr.push(response.data)
  }
  return arr
  // NProgress.start()
  // return apiClient
  //   .post('/geo/lbbprs/multiple/penjualan', arr)
  //   .then((response) => {
  //     NProgress.done()
  //     if (response) {
  //       // const { accessToken } = response.accessToken
  //       // console.log('service-data-read', response.data)
  //       // this.$store.commit('SET_STATE', { setting: 'loading', value: false })
  //       return response.data
  //     }
  //     return false
  //   })
  //   .catch((err) => console.log('err service->data->select', err))
}

export async function customUrlPost2ButFile(link, data, withNotif = true, todefaultbaseurl = false, withLoadin = false) {
  if (withLoadin) {
    NProgress.start()
  }
  const accessToken = axiosstore.get('accessToken')
  var headers = {
    'Content-Type': 'multipart/form-data',
    Authorization: accessToken,
  }
  var dataapps = JSON.parse(localStorage.getItem('appList')) || store.state.user.appList
  // console.log('dataapps', dataapps)
  var base = { baseUrl: '' }
  if (store.state.settings.isTesting) {
    base = {
      baseUrl: 'http://localhost:5367',
    }
  } else {
    if (dataapps) {
      base = dataapps.find(x => x.id === store.state.settings.whatapp.id)
    } else {
      base = {
        baseUrl: 'https://apilbbprs.heasoft.com',
      }
    }
  }
  apiClient.defaults.baseURL = todefaultbaseurl ? 'https://apilbbprs.heasoft.com/api/' : base.baseUrl
  return apiClient
    .post(link2 + link, data, {
      headers: headers,
      // responseType: 'json',
    })
    .then((response) => {
      NProgress.done()
      if (response) {
        if (withNotif) {
          notification.success({
            message: 'Sucessful',
            description: response.data.message,
          })
        }
        // const { accessToken } = response.accessToken
        // console.log('service-data-read', response.data)
        // this.$store.commit('SET_STATE', { setting: 'loading', value: false })
        return response.data
      }
      return false
    })
    .catch((err) => console.log('err service->data->select', err))
}

export async function customUrlPut2ButFile(link, data, withNotif = true, todefaultbaseurl = false, withLoadin = false) {
  if (withLoadin) {
    NProgress.start()
  }
  const accessToken = axiosstore.get('accessToken')
  var headers = {
    'Content-Type': 'multipart/form-data',
    Authorization: accessToken,
  }
  var dataapps = JSON.parse(localStorage.getItem('appList')) || store.state.user.appList
  // console.log('dataapps', dataapps)
  var base = { baseUrl: '' }
  if (store.state.settings.isTesting) {
    base = {
      baseUrl: 'http://localhost:5367',
    }
  } else {
    if (dataapps) {
      base = dataapps.find(x => x.id === store.state.settings.whatapp.id)
    } else {
      base = {
        baseUrl: 'https://apilbbprs.heasoft.com',
      }
    }
  }
  apiClient.defaults.baseURL = todefaultbaseurl ? 'https://apilbbprs.heasoft.com/api/' : base.baseUrl
  return apiClient
    .put(link2 + link, data, {
      headers: headers,
      // responseType: 'json',
    })
    .then((response) => {
      NProgress.done()
      if (response) {
        if (withNotif) {
          notification.success({
            message: 'Sucessful',
            description: response.data.message,
          })
        }
        // const { accessToken } = response.accessToken
        // console.log('service-data-read', response.data)
        // this.$store.commit('SET_STATE', { setting: 'loading', value: false })
        return response.data
      }
      return false
    })
    .catch((err) => console.log('err service->data->select', err))
}

export async function ngetDocument(link, withNotif = true, todefaultbaseurl = false) {
  NProgress.start()
  const accessToken = axiosstore.get('accessToken')
  var headers = {
    Authorization: accessToken,
    'Content-Disposition': 'attachment; filename="output.docx"',
    'Content-Type': 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  }
  var dataapps = JSON.parse(localStorage.getItem('appList')) || store.state.user.appList
  // console.log('dataapps', dataapps)
  var base = { baseUrl: '' }
  if (store.state.settings.isTesting) {
    base = {
      baseUrl: 'http://localhost:5367',
    }
  } else {
    if (dataapps) {
      base = dataapps.find(x => x.id === store.state.settings.whatapp.id)
    } else {
      base = {
        baseUrl: 'https://apilbbprs.heasoft.com',
      }
    }
  }
  apiClient.defaults.baseURL = todefaultbaseurl ? 'https://apilbbprs.heasoft.com/api/' : base.baseUrl + '/api/'
  return apiClient
    .get(link, {
      headers: headers,
      responseType: 'arraybuffer',
    })
    .then((response) => {
      NProgress.done()
      if (response) {
        // console.log('response', response)
        // const { accessToken } = response.accessToken
        // console.log('service->data->create', response)
        if (withNotif) {
          notification.success({
            message: 'Sucessful',
            description: 'Document has successfully generated!',
          })
        }
        const headerLine = response.headers['content-disposition']
        const startFileNameIndex = headerLine.indexOf('"') + 1
        const endFileNameIndex = headerLine.lastIndexOf('"')
        const filename = headerLine.substring(startFileNameIndex, endFileNameIndex)
        const blob = new Blob([response.data], {
          type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        })
        const link = document.createElement('a')
        link.href = URL.createObjectURL(blob)
        link.download = filename
        link.click()
        // URL.revokeObjectURL(link.href)
        return response.data
      }
      return false
    })
    .catch((err) => console.log('err service->data->select', err))
}
export async function ngetDocumentPdf(link, withNotif = true, todefaultbaseurl = false, baseurl = 'https://apilbbprs.heasoft.com/api/', contentdisposition = 'attachment;', contenttype = 'application/pdf') {
  NProgress.start()
  const accessToken = axiosstore.get('accessToken')
  var headers = {
    Authorization: accessToken,
    'Content-Disposition': contentdisposition,
    'Content-Type': contenttype,
  }
  var dataapps = JSON.parse(localStorage.getItem('appList')) || store.state.user.appList
  // console.log('dataapps', dataapps)
  var base = { baseUrl: '' }
  if (store.state.settings.isTesting) {
    base = {
      baseUrl: 'http://localhost:5367',
    }
  } else {
    if (dataapps) {
      base = dataapps.find(x => x.id === store.state.settings.whatapp.id)
    } else {
      base = {
        baseUrl: 'https://apilbbprs.heasoft.com',
      }
    }
  }
  apiClient.defaults.baseURL = todefaultbaseurl ? baseurl : base.baseUrl
  return apiClient
    .get(link, {
      headers: headers,
      responseType: 'arraybuffer',
    })
    .then((response) => {
      NProgress.done()
      if (response) {
        // console.log('response', response)
        // const { accessToken } = response.accessToken
        // console.log('service->data->create', response)
        if (withNotif) {
          notification.success({
            message: 'Sucessful',
            description: 'Document has successfully generated!',
          })
        }
        // const headerLine = response.headers['content-disposition']
        // const startFileNameIndex = headerLine.indexOf('"') + 1
        // const endFileNameIndex = headerLine.lastIndexOf('"')
        // const filename = headerLine.substring(startFileNameIndex, endFileNameIndex)
        // const blob = new Blob([response.data], {
        //   type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        // })
        // const link = document.createElement('a')
        // link.href = URL.createObjectURL(blob)
        // link.download = filename
        // link.click()
        // URL.revokeObjectURL(link.href)
        return response.data
      }
      return false
    })
    .catch((err) => console.log('err service->data->select', err))
}

export async function npostDocumentPdf(link, data, withNotif = true, todefaultbaseurl = false, baseurl = 'https://apilbbprs.heasoft.com/api/', contentdisposition = 'attachment;', contenttype = 'application/pdf') {
  NProgress.start()
  const accessToken = axiosstore.get('accessToken')
  var headers = {
    Authorization: accessToken,
    // 'Content-Disposition': contentdisposition,
    // 'Content-Type': contenttype,
  }
  var dataapps = JSON.parse(localStorage.getItem('appList')) || store.state.user.appList
  // console.log('dataapps', dataapps)
  var base = { baseUrl: '' }
  if (store.state.settings.isTesting) {
    base = {
      baseUrl: 'http://localhost:5367',
    }
  } else {
    if (dataapps) {
      base = dataapps.find(x => x.id === store.state.settings.whatapp.id)
    } else {
      base = {
        baseUrl: 'https://apilbbprs.heasoft.com',
      }
    }
  }
  apiClient.defaults.baseURL = todefaultbaseurl ? baseurl : base.baseUrl
  return apiClient
    .post(link, data, {
      headers: headers,
      responseType: 'arraybuffer',
    })
    .then((response) => {
      NProgress.done()
      if (response) {
        // console.log('response', response)
        // const { accessToken } = response.accessToken
        // console.log('service->data->create', response)
        if (withNotif) {
          notification.success({
            message: 'Sucessful',
            description: 'Document has successfully generated!',
          })
        }
        // const headerLine = response.headers['content-disposition']
        // const startFileNameIndex = headerLine.indexOf('"') + 1
        // const endFileNameIndex = headerLine.lastIndexOf('"')
        // const filename = headerLine.substring(startFileNameIndex, endFileNameIndex)
        // const blob = new Blob([response.data], {
        //   type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        // })
        // const link = document.createElement('a')
        // link.href = URL.createObjectURL(blob)
        // link.download = filename
        // link.click()
        // URL.revokeObjectURL(link.href)
        return response.data
      }
      return false
    })
    .catch((err) => console.log('err service->data->select', err))
}

export async function uploadDocument(master, formData, withNotif = true, todefaultbaseurl = false) {
  NProgress.start()
  var headers = {
    'Content-Type': 'multipart/form-data',
  }
  var dataapps = JSON.parse(localStorage.getItem('appList')) || store.state.user.appList
  // console.log('dataapps', dataapps)
  var base = { baseUrl: '' }
  if (store.state.settings.isTesting) {
    base = {
      baseUrl: 'http://localhost:5367',
    }
  } else {
    if (dataapps) {
      base = dataapps.find(x => x.id === store.state.settings.whatapp.id)
    } else {
      base = {
        baseUrl: 'https://apilbbprs.heasoft.com',
      }
    }
  }
  apiClient.defaults.baseURL = todefaultbaseurl ? 'https://apilbbprs.heasoft.com/api/' : base.baseUrl + '/api/'
  return apiClient
    .post(link2 + 'master/' + master + '/docx', formData, {
      headers: headers,
      responseType: 'json',
    })
    .then((response) => {
      NProgress.done()
      if (response) {
        // console.log('response', response)
        // const { accessToken } = response.accessToken
        // console.log('service->data->create', response)
        if (withNotif) {
          notification.success({
            message: 'Sucessful',
            description: response.data.message,
          })
        }
        // URL.revokeObjectURL(link.href)
        return response.data
      }
      return false
    })
    .catch((err) => console.log('err service->data->select', err))
}

export async function getDocument(master, id = '', withNotif = true, todefaultbaseurl = false) {
  NProgress.start()
  var headers = {
    'Content-Disposition': 'attachment; filename="output.docx"',
    'Content-Type': 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  }
  var separator = id === '' ? '' : '/'
  var dataapps = JSON.parse(localStorage.getItem('appList')) || store.state.user.appList
  // console.log('dataapps', dataapps)
  var base = { baseUrl: '' }
  if (store.state.settings.isTesting) {
    base = {
      baseUrl: 'http://localhost:5367',
    }
  } else {
    if (dataapps) {
      base = dataapps.find(x => x.id === store.state.settings.whatapp.id)
    } else {
      base = {
        baseUrl: 'https://apilbbprs.heasoft.com',
      }
    }
  }
  apiClient.defaults.baseURL = todefaultbaseurl ? 'https://apilbbprs.heasoft.com/api/' : base.baseUrl + '/api/'
  return apiClient
    .get(link2 + 'master/' + master + '/template/docx' + separator + id, {
      headers: headers,
      responseType: 'arraybuffer',
    })
    .then((response) => {
      NProgress.done()
      if (response) {
        // console.log('response', response)
        // const { accessToken } = response.accessToken
        // console.log('service->data->create', response)
        if (withNotif) {
          notification.success({
            message: 'Sucessful',
            description: 'Document has successfully generated!',
          })
        }
        const headerLine = response.headers['content-disposition']
        const startFileNameIndex = headerLine.indexOf('"') + 1
        const endFileNameIndex = headerLine.lastIndexOf('"')
        const filename = headerLine.substring(startFileNameIndex, endFileNameIndex)
        const blob = new Blob([response.data], {
          type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        })
        const link = document.createElement('a')
        link.href = URL.createObjectURL(blob)
        link.download = filename
        link.click()
        // URL.revokeObjectURL(link.href)
        return response.data
      }
      return false
    })
    .catch((err) => console.log('err service->data->select', err))
}

export async function getZip(link, withNotif = true, withloading = true, todefaultbaseurl = false) {
  if (withloading) NProgress.start()
  var headers = {
    'Content-Disposition': 'attachment; filename="output.zip"',
    'Content-Type': 'application/zip',
  }
  var dataapps = JSON.parse(localStorage.getItem('appList')) || store.state.user.appList
  // console.log('dataapps', dataapps)
  var base = { baseUrl: '' }
  if (store.state.settings.isTesting) {
    base = {
      baseUrl: 'http://localhost:5367',
    }
  } else {
    if (dataapps) {
      base = dataapps.find(x => x.id === store.state.settings.whatapp.id)
    } else {
      base = {
        baseUrl: 'https://apilbbprs.heasoft.com',
      }
    }
  }
  apiClient.defaults.baseURL = todefaultbaseurl ? 'https://apilbbprs.heasoft.com/api/' : base.baseUrl + '/api/'
  return apiClient
    .get(link2 + link, {
      headers: headers,
      responseType: 'arraybuffer',
    })
    .then((response) => {
      NProgress.done()
      if (response) {
        // console.log('response', response)
        // const { accessToken } = response.accessToken
        // console.log('service->data->create', response)
        if (withNotif) {
          notification.success({
            message: 'Sucessful',
            description: 'Document has successfully generated!',
          })
        }
        // console.log('response.headers', response.headers)
        const headerLine = response.headers['content-disposition']
        // console.log('headerLine', headerLine)
        var filename = 'output.zip'
        if (headerLine !== undefined) {
          const startFileNameIndex = headerLine.indexOf('=') + 1
          // console.log('startFileNameIndex', startFileNameIndex)
          const endFileNameIndex = headerLine.lastIndexOf('.zip;')
          // console.log('endFileNameIndex', endFileNameIndex)
          filename = headerLine.substring(startFileNameIndex, endFileNameIndex)
        }
        // console.log('filename', filename)
        const blob = new Blob([response.data], {
          type: 'application/zip',
        })
        const link = document.createElement('a')
        link.href = URL.createObjectURL(blob)
        link.download = filename
        link.click()
        // URL.revokeObjectURL(link.href)
        return response.data
      }
      return false
    })
    .catch((err) => console.log('err service->data->select', err))
}

export async function customGetZip(baseURL, link, withNotif = true, withloading = true) {
  if (withloading) NProgress.start()
  var headers = {
    'Content-Disposition': 'attachment; filename="output.zip"',
    'Content-Type': 'application/zip',
  }
  // console.log('dataapps', dataapps)
  apiClient.defaults.baseURL = baseURL + '/api/'
  return apiClient
    .get(link2 + link, {
      headers: headers,
      responseType: 'arraybuffer',
    })
    .then((response) => {
      NProgress.done()
      if (response) {
        // console.log('response', response)
        // const { accessToken } = response.accessToken
        // console.log('service->data->create', response)
        if (withNotif) {
          notification.success({
            message: 'Sucessful',
            description: 'Document has successfully generated!',
          })
        }
        // console.log('response.headers', response.headers)
        const headerLine = response.headers['content-disposition']
        // console.log('headerLine', headerLine)
        var filename = 'output.zip'
        if (headerLine !== undefined) {
          const startFileNameIndex = headerLine.indexOf('=') + 1
          // console.log('startFileNameIndex', startFileNameIndex)
          const endFileNameIndex = headerLine.lastIndexOf('.zip;')
          // console.log('endFileNameIndex', endFileNameIndex)
          filename = headerLine.substring(startFileNameIndex, endFileNameIndex)
        }
        // console.log('filename', filename)
        const blob = new Blob([response.data], {
          type: 'application/zip',
        })
        const link = document.createElement('a')
        link.href = URL.createObjectURL(blob)
        link.download = filename
        link.click()
        // URL.revokeObjectURL(link.href)
        return response.data
      }
      return false
    })
    .catch((err) => console.log('err service->data->select', err))
}

export async function getDocumentExcel(master, withNotif = true, todefaultbaseurl = false) {
  NProgress.start()
  var headers = {
    'Content-Disposition': 'attachment; filename="output.xlsx"',
    'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  }
  var dataapps = JSON.parse(localStorage.getItem('appList')) || store.state.user.appList
  // console.log('dataapps', dataapps)
  var base = { baseUrl: '' }
  if (store.state.settings.isTesting) {
    base = {
      baseUrl: 'http://localhost:5367',
    }
  } else {
    if (dataapps) {
      base = dataapps.find(x => x.id === store.state.settings.whatapp.id)
    } else {
      base = {
        baseUrl: 'https://apilbbprs.heasoft.com',
      }
    }
  }
  apiClient.defaults.baseURL = todefaultbaseurl ? 'https://apilbbprs.heasoft.com/api/' : base.baseUrl + '/api/'
  return apiClient
    .get(link2 + master, {
      headers: headers,
      responseType: 'arraybuffer',
    })
    .then((response) => {
      NProgress.done()
      if (response) {
        // console.log('response', response)
        // const { accessToken } = response.accessToken
        // console.log('service->data->create', response)
        if (withNotif) {
          notification.success({
            message: 'Sucessful',
            description: 'Document has successfully generated!',
          })
        }
        const headerLine = response.headers['content-disposition']
        const startFileNameIndex = headerLine.indexOf('"') + 1
        const endFileNameIndex = headerLine.lastIndexOf('"')
        const filename = headerLine.substring(startFileNameIndex, endFileNameIndex)
        const blob = new Blob([response.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        })
        const link = document.createElement('a')
        link.href = URL.createObjectURL(blob)
        link.download = filename
        link.click()
        // URL.revokeObjectURL(link.href)
        return response.data
      }
      return false
    })
    .catch((err) => console.log('err service->data->select', err))
}

export async function createMaster(master, data, withNotif = true, todefaultbaseurl = false) {
  NProgress.start()
  var dataapps = JSON.parse(localStorage.getItem('appList')) || store.state.user.appList
  // console.log('dataapps', dataapps)
  var base = { baseUrl: '' }
  if (store.state.settings.isTesting) {
    base = {
      baseUrl: 'http://localhost:5367',
    }
  } else {
    if (dataapps) {
      base = dataapps.find(x => x.id === store.state.settings.whatapp.id)
    } else {
      base = {
        baseUrl: 'https://apilbbprs.heasoft.com',
      }
    }
  }
  apiClient.defaults.baseURL = todefaultbaseurl ? 'https://apilbbprs.heasoft.com/api/' : base.baseUrl + '/api/'
  return apiClient
    .post(link2 + master, data)
    .then((response) => {
      NProgress.done()
      if (response) {
        // const { accessToken } = response.accessToken
        // console.log('service->data->create', response)
        if (withNotif) {
          notification.success({
            message: 'Successfully',
            description: response.data.message || 'Data has been successfully Created!',
          })
        }
        return response.data
      }
      return false
    })
    .catch((err) => console.log('err service->data->select', err))
}
export async function createMasterMany(master, data, withNotif = true, todefaultbaseurl = false) {
  NProgress.start()
  var dataapps = JSON.parse(localStorage.getItem('appList')) || store.state.user.appList
  // console.log('dataapps', dataapps)
  // console.log('store.state.settings.isTesting', store.state.settings.isTesting)
  var base = { baseUrl: '' }
  if (store.state.settings.isTesting) {
    base = {
      baseUrl: 'http://localhost:5367',
    }
  } else {
    if (dataapps) {
      base = dataapps.find(x => x.id === store.state.settings.whatapp.id)
    } else {
      base = {
        baseUrl: 'https://apilbbprs.heasoft.com',
      }
    }
  }
  apiClient.defaults.baseURL = todefaultbaseurl ? 'https://apilbbprs.heasoft.com/api/' : base.baseUrl + '/api/'
  return apiClient
    .post(link2 + master + '/many', data)
    .then((response) => {
      NProgress.done()
      if (response) {
        // const { accessToken } = response.accessToken
        // console.log('service->data->create', response)
        if (withNotif) {
          notification.success({
            message: 'Successfully',
            description: response.message || 'Data has been successfully Created!',
          })
        }
        return response.data
      }
      return false
    })
    .catch((err) => console.log('err service->data->select', err))
}

export async function updateMaster(master, data, withNotif = true, todefaultbaseurl = false) {
  NProgress.start()
  var dataapps = JSON.parse(localStorage.getItem('appList')) || store.state.user.appList
  // console.log('dataapps', dataapps)
  var base = { baseUrl: '' }
  if (store.state.settings.isTesting) {
    base = {
      baseUrl: 'http://localhost:5367',
    }
  } else {
    if (dataapps) {
      base = dataapps.find(x => x.id === store.state.settings.whatapp.id)
    } else {
      base = {
        baseUrl: 'https://apilbbprs.heasoft.com',
      }
    }
  }
  apiClient.defaults.baseURL = todefaultbaseurl ? 'https://apilbbprs.heasoft.com/api/' : base.baseUrl + '/api/'
  return apiClient
    .put(link2 + master, data)
    .then((response) => {
      NProgress.done()
      if (response) {
        // const { accessToken } = response.accessToken
        // console.log('service->data->update', response)
        if (withNotif) {
          notification.success({
            message: 'Updated',
            description: 'Data has been successfully updated!',
          })
        }
        return response.data
      }
      return false
    })
    .catch((err) => console.log('err service->data->select', err))
}
export async function updateMasterMany(master, data, withNotif = true, todefaultbaseurl = false) {
  NProgress.start()
  var dataapps = JSON.parse(localStorage.getItem('appList')) || store.state.user.appList
  // console.log('dataapps', dataapps)
  var base = { baseUrl: '' }
  if (store.state.settings.isTesting) {
    base = {
      baseUrl: 'http://localhost:5367',
    }
  } else {
    if (dataapps) {
      base = dataapps.find(x => x.id === store.state.settings.whatapp.id)
    } else {
      base = {
        baseUrl: 'https://apilbbprs.heasoft.com',
      }
    }
  }
  apiClient.defaults.baseURL = todefaultbaseurl ? 'https://apilbbprs.heasoft.com/api/' : base.baseUrl + '/api/'
  return apiClient
    .put(link2 + master + '/many', data)
    .then((response) => {
      NProgress.done()
      if (response) {
        // const { accessToken } = response.accessToken
        // console.log('service->data->update', response)
        if (withNotif) {
          notification.success({
            message: 'Updated',
            description: 'Data has been successfully updated!',
          })
        }
        return response.data
      }
      return false
    })
    .catch((err) => console.log('err service->data->select', err))
}

export async function deleteMaster(master, id, withNotif = true, todefaultbaseurl = false) {
  NProgress.start()
  var dataapps = JSON.parse(localStorage.getItem('appList')) || store.state.user.appList
  // console.log('dataapps', dataapps)
  var base = { baseUrl: '' }
  if (store.state.settings.isTesting) {
    base = {
      baseUrl: 'http://localhost:5367',
    }
  } else {
    if (dataapps) {
      base = dataapps.find(x => x.id === store.state.settings.whatapp.id)
    } else {
      base = {
        baseUrl: 'https://apilbbprs.heasoft.com',
      }
    }
  }
  apiClient.defaults.baseURL = todefaultbaseurl ? 'https://apilbbprs.heasoft.com/api/' : base.baseUrl + '/api/'
  return apiClient
    .delete(link2 + master + '/' + id)
    .then((response) => {
      NProgress.done()
      if (response) {
        // const { accessToken } = response.accessToken
        // console.log('service->data->delete', response.data)
        if (withNotif) {
          notification.success({
            message: 'Deleted',
            description: 'Data has been successfully deleted from database!',
          })
        }
        return response.data
      }
      return false
    })
    .catch((err) => console.log('err service->data->select', err))
}

export async function urlTransaksi(link, data, withNotif = true, todefaultbaseurl = false) {
  NProgress.start()
  var dataapps = JSON.parse(localStorage.getItem('appList')) || store.state.user.appList
  // console.log('dataapps', dataapps)
  var base = { baseUrl: '' }
  if (store.state.settings.isTesting) {
    base = {
      baseUrl: 'http://localhost:5367',
    }
  } else {
    if (dataapps) {
      base = dataapps.find(x => x.id === store.state.settings.whatapp.id)
    } else {
      base = {
        baseUrl: 'https://apilbbprs.heasoft.com',
      }
    }
  }
  apiClient.defaults.baseURL = todefaultbaseurl ? 'https://apilbbprs.heasoft.com/api/' : base.baseUrl + '/api/'
  return apiClient
    .post(link, data)
    .then((response) => {
      NProgress.done()
      if (response) {
        // const { accessToken } = response.accessToken
        // console.log('service->data->create', response)
        if (withNotif) {
          notification.success({
            message: 'Success',
            description: 'Successfully execute!',
          })
        }
        return response.data
      }
      return false
    })
    .catch((err) => console.log('err service->data->select', err))
}

export async function getDataattendance() {
  var lbbprs = store.state.user.lbbprs
  return lbbprs
}

// function formatingData(data) {
//   if (data !== []) {
//     for (var [key, value] of Object.entries(data)) {
//       if (value.toString().includes('GMT')) {
//         data[key] = moment(value).format('YYYY-MM-DD')
//         // console.log('value', data[key])
//       }
//     }
//   }
// }

export async function create1(data, withNotif = true, todefaultbaseurl = false) {
  var arr = []
  for (var i in data) {
    // alert(i) // alerts key
    // alert(data[i]) // alerts key's value
    arr.push(baca(data[i].gate, 'create', data[i].data))
  }
  NProgress.start()
  var dataapps = JSON.parse(localStorage.getItem('appList')) || store.state.user.appList
  // console.log('dataapps', dataapps)
  var base = { baseUrl: '' }
  if (store.state.settings.isTesting) {
    base = {
      baseUrl: 'http://localhost:5367',
    }
  } else {
    if (dataapps) {
      base = dataapps.find(x => x.id === store.state.settings.whatapp.id)
    } else {
      base = {
        baseUrl: 'https://apilbbprs.heasoft.com',
      }
    }
  }
  apiClient.defaults.baseURL = todefaultbaseurl ? 'https://apilbbprs.heasoft.com/api/' : base.baseUrl + '/api/'
  return apiClient
    .post(link1, arr)
    .then((response) => {
      NProgress.done()
      if (response) {
        // const { accessToken } = response.accessToken
        // console.log('service->data->create', response)
        if (withNotif) {
          notification.success({
            message: 'Successfully',
            description: response.message || 'Data has been successfully Created!',
          })
        }
        return response.data
      }
      return false
    })
    .catch((err) => console.log('err service->data->select', err))
}

export async function read4(data, todefaultbaseurl = false) {
  var arr = []
  for (var i in data) {
    // alert(i) // alerts key
    // alert(data[i]) // alerts key's value
    arr.push(baca(data[i].gate, data[i].operation, data[i].data))
  }
  NProgress.start()
  var dataapps = JSON.parse(localStorage.getItem('appList')) || store.state.user.appList
  // console.log('dataapps', dataapps)
  var base = { baseUrl: '' }
  if (store.state.settings.isTesting) {
    base = {
      baseUrl: 'http://localhost:5367',
    }
  } else {
    if (dataapps) {
      base = dataapps.find(x => x.id === store.state.settings.whatapp.id)
    } else {
      base = {
        baseUrl: 'https://apilbbprs.heasoft.com',
      }
    }
  }
  apiClient.defaults.baseURL = todefaultbaseurl ? 'https://apilbbprs.heasoft.com/api/' : base.baseUrl + '/api/'
  return apiClient
    .post(link1, arr)
    .then((response) => {
      NProgress.done()
      if (response) {
        // const { accessToken } = response.accessToken
        // console.log('service-data-read', response.data)
        // this.$store.commit('SET_STATE', { setting: 'loading', value: false })
        return response.data
      }
      return false
    })
    .catch((err) => console.log('err service->data->select', err))
}

export async function read3(data, todefaultbaseurl = false) {
  var arr = []
  for (var i in data) {
    // alert(i) // alerts key
    // alert(data[i]) // alerts key's value
    arr.push(baca(i, 'read', data[i]))
  }
  NProgress.start()
  var dataapps = JSON.parse(localStorage.getItem('appList')) || store.state.user.appList
  // console.log('dataapps', dataapps)
  var base = { baseUrl: '' }
  if (store.state.settings.isTesting) {
    base = {
      baseUrl: 'http://localhost:5367',
    }
  } else {
    if (dataapps) {
      base = dataapps.find(x => x.id === store.state.settings.whatapp.id)
    } else {
      base = {
        baseUrl: 'https://apilbbprs.heasoft.com',
      }
    }
  }
  apiClient.defaults.baseURL = todefaultbaseurl ? 'https://apilbbprs.heasoft.com/api/' : base.baseUrl + '/api/'
  return apiClient
    .post(link1, arr)
    .then((response) => {
      NProgress.done()
      if (response) {
        // const { accessToken } = response.accessToken
        // console.log('service-data-read', response.data)
        // this.$store.commit('SET_STATE', { setting: 'loading', value: false })
        return response.data
      }
      return false
    })
    .catch((err) => console.log('err service->data->select', err))
}

export async function messageSend(pesan, todefaultbaseurl = false) {
  NProgress.start()
  var dataapps = JSON.parse(localStorage.getItem('appList')) || store.state.user.appList
  // console.log('dataapps', dataapps)
  var base = { baseUrl: '' }
  if (store.state.settings.isTesting) {
    base = {
      baseUrl: 'http://localhost:5367',
    }
  } else {
    if (dataapps) {
      base = dataapps.find(x => x.id === store.state.settings.whatapp.id)
    } else {
      base = {
        baseUrl: 'https://apilbbprs.heasoft.com',
      }
    }
  }
  apiClient.defaults.baseURL = todefaultbaseurl ? 'https://apilbbprs.heasoft.com/api/' : base.baseUrl + '/api/'
  return apiClient
    .post(link2, {
      gate: 'Fapi',
      operation: 'test',
      data: {
        channel_id: 1,
        message: pesan,
        message_id: '',
      },
    })
    .then((response) => {
      NProgress.done()
      if (response) {
        // const { accessToken } = response.accessToken
        // console.log('service-data-read', response.data)
        // this.$store.commit('SET_STATE', { setting: 'loading', value: false })
        return response.data
      }
      return false
    })
  // .catch((err) => console.log('err service->data->select', err))
}

export async function readId(gate, id, todefaultbaseurl = false) {
  NProgress.start()
  var dataapps = JSON.parse(localStorage.getItem('appList')) || store.state.user.appList
  // console.log('dataapps', dataapps)
  var base = { baseUrl: '' }
  if (store.state.settings.isTesting) {
    base = {
      baseUrl: 'http://localhost:5367',
    }
  } else {
    if (dataapps) {
      base = dataapps.find(x => x.id === store.state.settings.whatapp.id)
    } else {
      base = {
        baseUrl: 'https://apilbbprs.heasoft.com',
      }
    }
  }
  apiClient.defaults.baseURL = todefaultbaseurl ? 'https://apilbbprs.heasoft.com/api/' : base.baseUrl + '/api/'
  return apiClient
    .post(link1, {
      gate,
      operation: 'read',
      id,
      data: [],
    })
    .then((response) => {
      NProgress.done()
      if (response) {
        // const { accessToken } = response.accessToken
        // console.log('service->data', response)
        return response.data
      }
      return false
    })
    .catch((err) => console.log('err service->data->select', err))
}

export async function update(gate, id, data, withNotif = true, todefaultbaseurl = false) {
  NProgress.start()
  var dataapps = JSON.parse(localStorage.getItem('appList')) || store.state.user.appList
  // console.log('dataapps', dataapps)
  var base = { baseUrl: '' }
  if (store.state.settings.isTesting) {
    base = {
      baseUrl: 'http://localhost:5367',
    }
  } else {
    if (dataapps) {
      base = dataapps.find(x => x.id === store.state.settings.whatapp.id)
    } else {
      base = {
        baseUrl: 'https://apilbbprs.heasoft.com',
      }
    }
  }
  apiClient.defaults.baseURL = todefaultbaseurl ? 'https://apilbbprs.heasoft.com/api/' : base.baseUrl + '/api/'
  return apiClient
    .post(link1, {
      gate,
      operation: 'update',
      id,
      data,
    })
    .then((response) => {
      NProgress.done()
      if (response) {
        // const { accessToken } = response.accessToken
        // console.log('service->data->update', response)
        if (withNotif) {
          notification.success({
            message: 'Updated',
            description: 'Data has been successfully updated!',
          })
        }
        return response.data
      }
      return false
    })
    .catch((err) => console.log('err service->data->select', err))
}

export async function del(gate, id, withNotif = true, todefaultbaseurl = false) {
  NProgress.start()
  var dataapps = JSON.parse(localStorage.getItem('appList')) || store.state.user.appList
  // console.log('dataapps', dataapps)
  var base = { baseUrl: '' }
  if (store.state.settings.isTesting) {
    base = {
      baseUrl: 'http://localhost:5367',
    }
  } else {
    if (dataapps) {
      base = dataapps.find(x => x.id === store.state.settings.whatapp.id)
    } else {
      base = {
        baseUrl: 'https://apilbbprs.heasoft.com',
      }
    }
  }
  apiClient.defaults.baseURL = todefaultbaseurl ? 'https://apilbbprs.heasoft.com/api/' : base.baseUrl + '/api/'
  return apiClient
    .post(link1, {
      gate,
      operation: 'delete',
      data: {
        where: {
          id: id,
        },
      },
    })
    .then((response) => {
      NProgress.done()
      if (response) {
        // const { accessToken } = response.accessToken
        // console.log('service->data->delete', response.data)
        if (withNotif) {
          notification.success({
            message: 'Deleted',
            description: 'Data has been successfully deleted from database!',
          })
        }
        return response.data
      }
      return false
    })
    .catch((err) => console.log('err service->data->select', err))
}

export async function del1(gate, data, withNotif = true, todefaultbaseurl = false) {
  NProgress.start()
  var dataapps = JSON.parse(localStorage.getItem('appList')) || store.state.user.appList
  // console.log('dataapps', dataapps)
  var base = { baseUrl: '' }
  if (store.state.settings.isTesting) {
    base = {
      baseUrl: 'http://localhost:5367',
    }
  } else {
    if (dataapps) {
      base = dataapps.find(x => x.id === store.state.settings.whatapp.id)
    } else {
      base = {
        baseUrl: 'https://apilbbprs.heasoft.com',
      }
    }
  }
  apiClient.defaults.baseURL = todefaultbaseurl ? 'https://apilbbprs.heasoft.com/api/' : base.baseUrl + '/api/'
  return apiClient
    .post(link1, {
      gate,
      operation: 'delete',
      data: data,
    })
    .then((response) => {
      NProgress.done()
      if (response) {
        // const { accessToken } = response.accessToken
        // console.log('service->data->delete', response.data)
        if (withNotif) {
          notification.success({
            message: 'Deleted',
            description: 'Data has been successfully deleted from database!',
          })
        }
        return response.data
      }
      return false
    })
    .catch((err) => console.log('err service->data->select', err))
}

export async function confirmDelete(gate, deldata, afterDeleteFunc = '') {
  Modal.confirm({
    title: 'Are you sure delete this data?',
    content: "You can't prevent this!!! ",
    okText: 'Yes',
    okType: 'danger',
    cancelText: 'No',
    onOk: async () => {
      var response = await del(gate, deldata.id)
      if (response) {
        if (typeof afterDeleteFunc === 'function') {
          afterDeleteFunc()
        }
      }
      return response
      // this.getAllData()
    },
    onCancel: () => {
      console.log('Cancel')
      return false
    },
  })
}

// export async function confirmDelete2(gate, deldata, afterDeleteFunc = '') {
//   Popconfirm.confirm({
//     title: 'Are you sure delete this data?',
//     content: "You can't prevent this!!! ",
//     okText: 'Yes',
//     cancelText: 'No',
//     onConfirm: async () => {
//       var response = await del(gate, deldata.id)
//       if (response) {
//         if (typeof (afterDeleteFunc) === 'function') {
//           afterDeleteFunc()
//         }
//       }
//       return response
//       // this.getAllData()
//     },
//     onCancel: () => {
//       console.log('Cancel')
//       return false
//     },
//   })
// }

// Taksasi Jaminan Helpers
export async function readMasterPemohon(master = 'pengajuankredit', limit, kode, mode = 'get') {
  NProgress.start()
  return apiClient
    .get(link2 + 'master/' + master + '?limit=' + limit + '&kode=' + kode)
    .then((response) => {
      NProgress.done()
      if (response) {
        // const { accessToken } = response.accessToken
        // console.log('service-data-read', response.data)
        // this.$store.commit('SET_STATE', { setting: 'loading', value: false })
        return response.data
      }
      return false
    })
    .catch((err) => console.log('err service->data->select', err))
}
// End of Taksasi Jaminan Helpers

/* Date Helpers */
export function addMonth(date) {
  var ndate = moment(date)
  var fm = moment(ndate).add(1, 'M')
  var fmEnd = moment(fm).endOf('month')
  return ndate.date() !== fm.date() && fm.isSame(fmEnd.format('YYYY-MM-DD'))
    ? fm.add(1, 'd')
    : fm
}

export function convRelative(date) {
  return moment(date).startOf('hour').fromNow() // Tanggal hari ini format 2020-12-31
}

export function datenow() {
  return moment().format('YYYY-MM-DD') // Tanggal hari ini format 2020-12-31
}

export function formatDate(value) {
  return moment(value).format('DD-MM-YYYY')
}

export function ExcelDateToJSDate(serial) {
  var utcdays = Math.floor(serial - 25569)
  var utcvalue = utcdays * 86400
  var dateinfo = new Date(utcvalue * 1000)

  var fractionalday = serial - Math.floor(serial) + 0.0000001

  var totalseconds = Math.floor(86400 * fractionalday)

  var seconds = totalseconds % 60

  totalseconds -= seconds

  var hours = Math.floor(totalseconds / (60 * 60))
  var minutes = Math.floor(totalseconds / 60) % 60
  var ndate = new Date(dateinfo.getFullYear(), dateinfo.getMonth(), dateinfo.getDate(), hours, minutes, seconds)
  return ndate.getFullYear().toString() + '-' + ('0' + (ndate.getMonth() + 1)).slice(-2) + '-' + ('0' + ndate.getDate()).slice(-2)
}

export function curency(val) {
  var formatter = new Intl.NumberFormat('id-ID', {
    // style: 'currency',
    minimumIntegerDigits: 1,
    maximumFractionDigits: 0,
  })
  return formatter.format(val)
}

export function capitalizeTheFirstLetterOfEachWord(words) {
  var nwords = words.replaceAll('_', ' ')
  var separateWord = nwords.toLowerCase().split(' ')
  for (var i = 0; i < separateWord.length; i++) {
    separateWord[i] = separateWord[i].charAt(0).toUpperCase() +
      separateWord[i].substring(1)
  }
  return separateWord.join(' ')
}

export function capitalize(word) {
  return word[0].toUpperCase() + word.slice(1).toLowerCase()
}

export function requestOtorisasi(data) {
  // console.log(data)
  var ret = []
  this.socket.emit('otorisasi')
  this.socket.on('otorisasi', (data) => {
    ret = data
  })
  return ret
}

export function printrekap(columns, datatable, title = 'Rekap') {
  var col = []
  var row = {}
  for (let u = 0; u < datatable.length; u++) {
    const item = datatable[u]

    for (let i = 0; i < columns.length; i++) {
      const field = columns[i]
      // console.log(field.label);
      row[field.title] = item[field.dataIndex]
      // console.log(row)
    }
    col.push(row)
    row = {}
  }
  // console.log(col);
  var rows = XLSX.utils.json_to_sheet(col)
  var wb = XLSX.utils.book_new() // make Workbook of Excel

  // add Worksheet to Workbook
  // Workbook contains one or more worksheets
  XLSX.utils.book_append_sheet(wb, rows, title) // sheetAName is name of Worksheet
  // XLSX.utils.book_append_sheet(wb, pokemonWS, 'pokemons')

  // export Excel file
  XLSX.writeFile(wb, title + ' ' + datenow() + '.xlsx') // name of the file is 'book.xlsx'
}

export function printtable(columns, datatable, title = 'Data Table') {
  var col = []
  var row = {}
  for (let u = 0; u < datatable.length; u++) {
    const item = datatable[u]

    for (let i = 0; i < columns.length; i++) {
      const field = columns[i]
      // console.log(field.label);
      row[field.dataIndex] = item[field.dataIndex]
      // console.log(row)
    }
    col.push(row)
    row = {}
  }
  // console.log(col);
  var rows = XLSX.utils.json_to_sheet(col)
  var wb = XLSX.utils.book_new() // make Workbook of Excel

  // add Worksheet to Workbook
  // Workbook contains one or more worksheets
  XLSX.utils.book_append_sheet(wb, rows, 'Total KM') // sheetAName is name of Worksheet
  // XLSX.utils.book_append_sheet(wb, pokemonWS, 'pokemons')

  // export Excel file
  XLSX.writeFile(wb, title + '.xlsx') // name of the file is 'book.xlsx'
}

export function templateCol(keys, withOp = true) {
  var ncolumns = []
  for (let i = 0; i < keys.length; i++) {
    const element = keys[i]
    if (i === 0) {
      ncolumns.push({
        title: element,
        dataIndex: element,
        key: element,
        width: '7%',
      })
    } else {
      if (
        element !== 'roles' &&
        element !== 'created_at' &&
        element !== 'updated_at'
      ) {
        ncolumns.push({
          title: element,
          dataIndex: element,
          scopedSlots: {
            customRender: element,
          },
          width: '7%',
        })
      }
    }
    if (withOp === true) {
      if (i === keys.length - 1) {
        ncolumns.push({
          title: 'operation',
          dataIndex: 'operation',
          scopedSlots: {
            customRender: 'operation',
          },
          width: '8%',
        })
      }
    }
  }
  return ncolumns
}

export function arraySum(arr, key) {
  var sum = 0
  for (var i in arr) {
    // eslint-disable-next-line no-prototype-builtins
    if (arr[i].hasOwnProperty(key)) {
      sum += parseFloat(arr[i][key])
    }
  }
  return isNaN(sum) ? 0 : sum
}

// Grouping array / Array grouping
export function groupByKey(array, key) {
  return array
    .reduce((hash, obj) => {
      if (obj[key] === undefined) return hash
      return Object.assign(hash, { [obj[key]]: (hash[obj[key]] || []).concat(obj) })
    }, {})
}

export function shownotif(title, msg, type = 'info', duration = 2) {
  switch (type) {
    case 'success':
      notification.success({
        message: title,
        description: msg,
        duration: duration,
      })
      break
    case 'error':
      notification.error({
        message: title,
        description: msg,
        duration: duration,
      })
      break
    case 'warning':
      notification.warning({
        message: title,
        description: msg,
        duration: duration,
      })
      break
    case 'info':
      notification.info({
        message: title,
        description: msg,
        duration: duration,
      })
      break
    default:
      break
  }
}

export function showconfirm(okFunc, cancelFunc = () => { console.log('Canceled') }, { title = 'Are you sure?', content = 'You can\'t prevent this!?', okType = 'primary', okText = 'Yes', noText = 'No', noType = 'danger' }) {
  Modal.confirm({
    title: title,
    content: content,
    okText: okText,
    okType: okType,
    cancelText: noText,
    cancelType: noType,
    onOk: okFunc,
    onCancel: cancelFunc,
  })
}

export function terbilang(a) {
  var bilangan = [
    '',
    'Satu',
    'Dua',
    'Tiga',
    'Empat',
    'Lima',
    'Enam',
    'Tujuh',
    'Delapan',
    'Sembilan',
    'Sepuluh',
    'Sebelas',
  ]
  var kalimat, utama, depan, belakang
  // 1 - 11
  if (a < 12) {
    kalimat = bilangan[a]
  } else if (a < 20) {
    kalimat = bilangan[a - 10] + ' Belas'
  } else if (a < 100) {
    utama = a / 10
    depan = parseInt(String(utama).substr(0, 1))
    belakang = a % 10
    kalimat = bilangan[depan] + ' Puluh ' + bilangan[belakang]
  } else if (a < 200) {
    kalimat = 'Seratus ' + terbilang(a - 100)
  } else if (a < 1000) {
    utama = a / 100
    depan = parseInt(String(utama).substr(0, 1))
    belakang = a % 100
    kalimat = bilangan[depan] + ' Ratus ' + terbilang(belakang)
  } else if (a < 2000) {
    kalimat = 'Seribu ' + terbilang(a - 1000)
  } else if (a < 10000) {
    utama = a / 1000
    depan = parseInt(String(utama).substr(0, 1))
    belakang = a % 1000
    kalimat = bilangan[depan] + ' Ribu ' + terbilang(belakang)
  } else if (a < 100000) {
    utama = a / 100
    depan = parseInt(String(utama).substr(0, 2))
    belakang = a % 1000
    kalimat = terbilang(depan) + ' Ribu ' + terbilang(belakang)
  } else if (a < 1000000) {
    utama = a / 1000
    depan = parseInt(String(utama).substr(0, 3))
    belakang = a % 1000
    kalimat = terbilang(depan) + ' Ribu ' + terbilang(belakang)
  } else if (a < 100000000) {
    utama = a / 1000000
    depan = parseInt(String(utama).substr(0, 4))
    belakang = a % 1000000
    kalimat = terbilang(depan) + ' Juta ' + terbilang(belakang)
  } else if (a < 1000000000) {
    utama = a / 1000000
    depan = parseInt(String(utama).substr(0, 4))
    belakang = a % 1000000
    kalimat = terbilang(depan) + ' Juta ' + terbilang(belakang)
  } else if (a < 10000000000) {
    utama = a / 1000000000
    depan = parseInt(String(utama).substr(0, 1))
    belakang = a % 1000000000
    kalimat = terbilang(depan) + ' Milyar ' + terbilang(belakang)
  } else if (a < 100000000000) {
    utama = a / 1000000000
    depan = parseInt(String(utama).substr(0, 2))
    belakang = a % 1000000000
    kalimat = terbilang(depan) + ' Milyar ' + terbilang(belakang)
  } else if (a < 1000000000000) {
    utama = a / 1000000000
    depan = parseInt(String(utama).substr(0, 3))
    belakang = a % 1000000000
    kalimat = terbilang(depan) + ' Milyar ' + terbilang(belakang)
  } else if (a < 10000000000000) {
    utama = a / 10000000000
    depan = parseInt(String(utama).substr(0, 1))
    belakang = a % 10000000000
    kalimat = terbilang(depan) + ' Triliun ' + terbilang(belakang)
  } else if (a < 100000000000000) {
    utama = a / 1000000000000
    depan = parseInt(String(utama).substr(0, 2))
    belakang = a % 1000000000000
    kalimat = terbilang(depan) + ' Triliun ' + terbilang(belakang)
  } else if (a < 1000000000000000) {
    utama = a / 1000000000000
    depan = parseInt(String(utama).substr(0, 3))
    belakang = a % 1000000000000
    kalimat = terbilang(depan) + ' Triliun ' + terbilang(belakang)
  } else if (a < 10000000000000000) {
    utama = a / 1000000000000000
    depan = parseInt(String(utama).substr(0, 1))
    belakang = a % 1000000000000000
    kalimat = terbilang(depan) + ' Kuadriliun ' + terbilang(belakang)
  }

  var pisah = kalimat.split(' ')
  var full = []
  for (var i = 0; i < pisah.length; i++) {
    if (pisah[i] !== '') {
      full.push(pisah[i])
    }
  }
  return full.join(' ')
}

export function randomColors() {
  var letters = '0123456789ABCDEF'
  var color = '#'
  for (var i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)]
  }
  return color
}

export function dynamicColors(datake = 5, transparation = 1) {
  // console.log('datake', datake)
  var r, g, b
  switch (datake) {
    case 0:
      r = 5
      g = 69
      b = 195
      break
    case 1:
      r = 0
      g = 163
      b = 235
      break
    case 2:
      r = 0
      g = 134
      b = 2
      break
    case 3:
      r = 250
      g = 199
      b = 10
      break
    case 4:
      r = 249
      g = 100
      b = 0
      break
    case 5:
      r = 41
      g = 43
      b = 44
      break
    default:
      r = Math.floor(Math.random() * 255)
      g = Math.floor(Math.random() * 255)
      b = Math.floor(Math.random() * 255)
      break
  }
  return 'rgb(' + r + ',' + g + ',' + b + ', ' + transparation + ')'
}

export function isMobile() {
  if (
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent,
    )
  ) {
    return true
  } else {
    return false
  }
}

// IndexedDB helpers
export async function getDb(DB_NAME = 'tempdatabase', DB_VERSION = 1) {
  return new Promise((resolve, reject) => {
    const request = window.indexedDB.open(DB_NAME, DB_VERSION)

    request.onerror = e => {
      // console.log('Error opening db', e)
      shownotif('error', 'Error opening db: ' + e, 'error')
      // eslint-disable-next-line prefer-promise-reject-errors
      reject('Error')
    }

    request.onsuccess = e => {
      resolve(e.target.result)
    }

    request.onupgradeneeded = e => {
      // console.log('onupgradeneeded')
      const db = e.target.result
      var store = db.createObjectStore('forallmaster', {
        autoIncrement: true,
        keyPath: 'id',
      })
      store.createIndex('mykey', 'mykey', {
        unique: true,
      })
    }
  })
}
export async function dropDb(DB_NAME = 'tempdatabase') {
  // window.indexedDB.close()
  return new Promise((resolve, reject) => {
    const request = window.indexedDB.deleteDatabase(DB_NAME)

    request.onerror = () => {
      console.log("Couldn't delete database")
    }

    request.onsuccess = () => {
      console.log('Deleted database successfully')
    }
    request.onblocked = () => {
      console.log("Couldn't delete database due to the operation being blocked")
    }
  })
}

export async function getDataFromDb(table = ['forallmaster']) {
  var db = await getDb()
  return new Promise((resolve, reject) => {
    const trans = db.transaction(table, 'readonly')
    trans.oncomplete = e => {
      resolve(data)
    }

    const store = trans.objectStore(table)
    const data = []

    store.openCursor().onsuccess = e => {
      const cursor = e.target.result
      if (cursor) {
        data.push(cursor.value)
        cursor.continue()
      }
    }
  })
}

export async function addDataToDb(data, table = ['forallmaster']) {
  var db = await getDb()
  // console.log('db', db)
  return new Promise((resolve, reject) => {
    // console.log('table', table)
    const trans = db.transaction(table, 'readwrite')
    trans.oncomplete = e => {
      resolve()
    }

    const store = trans.objectStore(table)
    store.add(data)
  })
}

export async function deleteDataFromDb(id, table = ['forallmaster']) {
  var db = await getDb()
  return new Promise((resolve, reject) => {
    const trans = db.transaction(table, 'readwrite')
    trans.oncomplete = e => {
      resolve()
    }

    const store = trans.objectStore(table)
    store.delete(id)
  })
}
